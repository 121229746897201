import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Container, Grid, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Logo from '../../assets/images/logo.png';
import Recrumentimage from '../../assets/images/Onboarding.svg';
import Start from '../../assets/images/Startups (1).svg';
import Manufacturing from '../../assets/images/Manufacturing.svg';
import Software from '../../assets/images/IT & Software.svg';
import Retail from '../../assets/images/Retail.svg';
import Agriculture from '../../assets/images/Agriculture.svg';
import Construction from '../../assets/images/Construction.svg';
import Shipping from '../../assets/images/Shipping & Marine.svg';
import School from '../../assets/images/School.svg';
import Salon from '../../assets/images/Salon & Spa.svg';
import Logistics from '../../assets/images/Logistics.svg';
import Beverage from '../../assets/images/Food & beverage.svg';
import commerce from '../../assets/images/E-Commerce.svg';

// import Plug from '../../assets/images/Pluge.svg';

const hoverStyles = {
    textAlign: 'center',
    paddingBottom: '40px',
    '&:hover': {
        marginTop: '-20px',
    },
};

const iconStyles = {
    width: '100px',
    height: '100px',
    margin: 'auto',
    textAlign: 'center',
    '@media (max-width:600px)': {
        width: '60px',
        height: '50px',
    },
    '@media (min-width:600px)': {
        width: '130px',
        height: '120px',
    },
    '@media (min-width:600px) and (max-width:1024px)': {
        width: '100px',
        height: '95px',
    },
};

const textStyles = {
    color: '#707070',
    fontSize: '20px',
    fontWeight: 'bold',
    fontFamily: 'Lato, Bold',
    '@media (max-width:600px)': {
        fontSize: '10px',
    },
    '@media (min-width:600px)': {
        fontSize: '35px',
    },
    '@media (min-width:600px) and (max-width:1024px)': {
        fontSize: '25px',
    },
};

const HomeKeyFeature = () => {
    return (
        <Container maxWidth="lg">
            <Box sx={{ textAlign: 'center', mt: 4, mb: 4, ml: 5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                    <img
                        src={Logo}
                        alt="Logo"
                        style={{
                            width: '15%',
                            height: '15%',
                            objectFit: 'contain',
                            marginBottom: "8px"
                        }}
                    />
                    <Typography variant="h1" sx={{ ml: 2, fontSize: '2.5rem', fontWeight: 'bold' }}>
                        Key Features
                    </Typography>
                </Box>
            </Box>

            <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                    <img
                        src={Recrumentimage}
                        alt="Recruitment"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box>
                        <Typography variant="body2" >
                            <Box className="Copy__title" sx={{ mb: 2 }}>
                                <Typography variant="h2" sx={{ fontWeight: '700', mb: 2, fontSize: "20px", color: "#007bff" }}>
                                    Save Time & Eliminate Errors
                                </Typography>
                                <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 3, fontSize: "40px" }}>
                                    Create perfect shipping docs,
                                    <Typography component="span" sx={{ fontWeight: 'bold', background: 'linear-gradient(to right, #ff7e5f, #feb47b)', WebkitBackgroundClip: 'text', color: '#007bff' }}>
                                        up to 80% faster.
                                    </Typography>
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2, mb: 0, fontSize: "20px" }}>
                                    Eliminate re-entry and create full & error-free sets of documents in minutes
                                    <strong> or have documents generated automatically from your ERP system.</strong>
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <ImageIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary="Autofill data from your ERP, TMS and other systems"
                                            primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <WorkIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                        </ListItemAvatar>
                                        <ListItemText primary="Over 30+ UNLK-aligned templates for global trade"
                                            primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <BeachAccessIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                        </ListItemAvatar>
                                        <ListItemText primary="Eliminate manual re-entry with Master File"
                                            primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <WorkIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                        </ListItemAvatar>
                                        <ListItemText primary="Eliminate manual re-entry with Master File"
                                            primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="h2" sx={{ fontWeight: '700', mb: 2, fontSize: "20px", color: "#007bff" }}>
                                Upgrade From Your Paper-Based Processes
                            </Typography>
                            <Typography variant="h1" sx={{ fontWeight: 'bold', mb: 3, fontSize: "40px" }}>
                                Upgrade to paperless,
                                <br />
                                <Typography component="span" sx={{ fontWeight: 'bold', background: 'linear-gradient(to right, #ff7e5f, #feb47b)', WebkitBackgroundClip: 'text', color: '#007bff' }}>
                                    work from anywhere.
                                </Typography>
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 2, mb: 0, fontSize: "20px" }}>
                                Say goodbye to tedious paper-based processes and enable your teams to work digitally, and from a shared workspace - whether they're in the office, or on the go.
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        <ImageIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Autofill data from your ERP, TMS and other systems"
                                        primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <WorkIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                    </ListItemAvatar>
                                    <ListItemText primary="Over 30+ UNLK-aligned templates for global trade"
                                        primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <BeachAccessIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                    </ListItemAvatar>
                                    <ListItemText primary="Eliminate manual re-entry with Master File"
                                        primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemAvatar>
                                        <WorkIcon sx={{ color: "#007bff", fontSize: "25px" }} />
                                    </ListItemAvatar>
                                    <ListItemText primary="Eliminate manual re-entry with Master File"
                                        primaryTypographyProps={{ style: { fontSize: '15px', fontWeight: "700" } }}
                                    />
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <img
                        src={Recrumentimage}
                        alt="Recruitment"
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    />
                </Grid>
            </Grid>

            <Box sx={{ flexGrow: 1, mt: 4, mb: 4 }}>
                <Container sx={{ textAlign: 'center', ml: 5 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <Typography variant="h1" sx={{ ml: 2, fontSize: '2.5rem', fontWeight: 'bold' }}>
                            BEST FIT For Your Industry
                        </Typography>
                    </Box>
                </Container>

                <Grid container spacing={4} sx={{ padding: '20px' }}>
                    {[
                        // { src: Plug, label: 'Plug' },
                        { src: Start, label: 'Start Ups dsadsfd' },
                        { src: Manufacturing, label: 'Manufacturing' },
                        { src: Software, label: 'IT & Software' },
                        { src: Retail, label: 'Retail' },
                        { src: Agriculture, label: 'Agriculture' },
                        { src: Construction, label: 'Construction' },
                        { src: Logistics, label: 'Logistics' },
                        { src: Beverage, label: 'Food & Beverage' },
                        { src: commerce, label: 'E-commerce' },
                        { src: Shipping, label: 'Shipping & Marine' },
                        { src: School, label: 'School' },
                        { src: Salon, label: 'Salon & Spa' },
                    ].map((item, index) => (
                        <Grid item xs={4} sm={4} lg={4} xl={4} md={4} key={index} sx={{ textAlign: 'center' }}>
                            <div style={hoverStyles}>
                                <img src={item.src} alt={item.label} style={iconStyles} />
                                <br />
                                <span style={textStyles}>{item.label}</span>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};


export default HomeKeyFeature;
