import React, { useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
const title = "Batch No. Wise Import Shipment Report";

const Function = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [leadModal, setLeadModal] = useState(false);
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);

    const columns = useMemo(
        () => [
            { accessorKey: 'sn', header: 'SN', size: 50 },
            { accessorKey: 'date', header: 'Date', size: 100 },
            { accessorKey: 'shipmentId', header: 'Shipment I’d', size: 100 },
            { accessorKey: 'piNo', header: 'PI No.', size: 100 },
            { accessorKey: 'batchNo', header: 'Batch No.', size: 100 },
            { accessorKey: 'supplierName', header: 'Supplier Name', size: 150 },
            { accessorKey: 'country', header: 'Country', size: 100 },
            { accessorKey: 'shippingMark', header: 'Shipping Mark', size: 150 },
            { accessorKey: 'blNo', header: 'BL No.', size: 100 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton onClick={() => handleViewClick(row.original.sn)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );

    const data = [
        {
            sn: "1",
            date: "23/03/2024",
            company: "KG 2",
            mobile: "37263274",
            leadSource: "New",
            leadOwner: "new",
            status: "In progress",
            priority: "low",
            lossReason: "low quality",
            createdBy: "KG",
        },
        {
            sn: "2",
            date: "23/03/2024",
            company: "AG",
            mobile: "37263274",
            leadSource: "AG pvt",
            leadOwner: "AG Pvt",
            status: "In progress",
            priority: "low",
            lossReason: "Transportation delayed",
            createdBy: "AG",
        },
    ];
    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }

    const handleViewClick = (id) => {
        navigate(`/app/abc/${id}`);
    };
    return (
        <>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default Function;
