import React, { useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import {
    ClearRounded,
    SaveRounded,
    Replay10Rounded,
} from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import PagesOutlinedIcon from '@mui/icons-material/PagesOutlined';
import { useTranslation } from "react-i18next";
import DataTable from "../../components/common/DataTable";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomTextarea from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";

const title = "Expense Ledger Group";

const Function = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [leadModal, setLeadModal] = useState(false);
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 5);


    const [formData, setFormData] = useState({
        leadName: "",
        leadOwner: null,
        leadSource: null,
        contactName: null,
        clientCompany: "",
        leadStatus: "",
        stage: "",
        priority: "",
        lossReason: "",
        productType: "",
        expectedTimeline: "",
        productRadio: "0",
        leadDescription: "",
    });

    const columns = useMemo(
        () => [
            { accessorKey: 'sn', header: 'SN', size: 50 },
            { accessorKey: 'shortName', header: 'Short Name', size: 150 },
            { accessorKey: 'groupName', header: 'Group Name', size: 200 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton onClick={() => handleViewClick(row.original.sn)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );

    const data = [
        {
            sn: "1",
            date: "23/03/2024",
            company: "KG 2",
            mobile: "37263274",
            leadSource: "New",
            leadOwner: "new",
            status: "In progress",
            priority: "low",
            lossReason: "low quality",
            createdBy: "KG",
        },
        {
            sn: "2",
            date: "23/03/2024",
            company: "AG",
            mobile: "37263274",
            leadSource: "AG pvt",
            leadOwner: "AG Pvt",
            status: "In progress",
            priority: "low",
            lossReason: "Transportation delayed",
            createdBy: "AG",
        },
    ];
    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }

    const handleViewClick = (id) => {
        navigate(`/app/abc/${id}`);
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleOwnerChange = (event, value) => {
        setFormData({
            ...formData,
            leadOwner: value,
        });
    };

    const handleSourceChange = (event, value) => {
        setFormData({
            ...formData,
            leadSource: value,
        });
    };

    const handleContactChange = (event, value) => {
        setFormData({
            ...formData,
            contactName: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
        // You can handle form submission logic here
    };

    return (
        <>
            <Box sx={{ paddingLeft: "10px" }} >
                <form >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <CustomTextField
                                type="text"
                                name="lastName"
                                fieldLabel="Expense Ledger Group"
                                value={formData.lastName}
                                required={true}
                                onChange={handleChange}
                                placeholder="Enter Expense "
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <CustomTextField
                                type="text"
                                name="lastName"
                                fieldLabel="Short Name"
                                value={formData.lastName}
                                required={true}
                                onChange={handleChange}
                                placeholder="Enter Short Name "
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <br />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                            />
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={data}
                />

            </Box>
        </>
    );
};
export default Function;



const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
];
