import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Pagination, colors } from '@mui/material';
import { List, ListItem, ListItemText } from '@mui/material';
import { Container, Grid, } from '@mui/material';
import { Link } from 'react-router-dom';
import BannerSearch from './BannerSearch';


const Blog = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 5; // Number of posts per page

    // Calculate the index of the last post on the current page
    const indexOfLastPost = currentPage * postsPerPage;
    // Calculate the index of the first post on the current page
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    // Slice the posts array to get the current page posts
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
    // Calculate the total number of pages
    const totalPages = Math.ceil(posts.length / postsPerPage);

    // Handler for page change
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <BannerSearch />

            <Container maxWidth="lg" sx={{ paddingY: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <Box id="brxe-yaloog" sx={styles.sidebar}>
                            <List component="ul" sx={styles.list}>
                                <ListItem sx={styles.listItem}>
                                    <Typography variant="h2" component="h2" sx={styles.heading}>
                                        Categories
                                    </Typography>
                                </ListItem>
                                <ListItem sx={styles.listItem}>
                                    <List component="ul" sx={styles.list}>
                                        {categories.map((category, index) => (
                                            <ListItem key={index} sx={styles.listItem}>
                                                <Link to={category.href} style={{ fontWeight: "bold", fontSize: "14px" }}>
                                                    {category.label}
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={9}>

                        <Box sx={{ padding: 2 }}>
                            <Typography variant="h2" gutterBottom sx={{ color: "#007BFF" }}>
                                Latest Posts
                            </Typography>
                            {currentPosts.map((post, index) => (
                                <Card key={index}
                                    sx={{
                                        marginBottom: 2,
                                        border: '1px solid #007BFF',
                                        // background: 'linear-gradient(to right, #f0f8ff, #e6f7ff)',
                                        boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.2)'
                                    }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>

                                        <CardContent>
                                            <Typography variant="h3" component="div">
                                                <Link
                                                    to={post.url}
                                                    aria-label={`Read more about ${post.title}`}
                                                    color="inherit"
                                                    style={{ textDecoration: 'none', fontWeight: "bold", color: "#007BFF" }}
                                                >
                                                    {post.title}
                                                </Link>
                                            </Typography>
                                            <Box sx={{ display: "flex", alignItems: "top", gap: 2 }}>
                                                <Typography variant="body1" sx={{ flex: 1, pt: 2, fontSize: "14px" }}>
                                                    {post.excerpt}
                                                    <Typography variant="body2" color="text.secondary" sx={{ pt: 2 }}>
                                                        Last updated on {post.date} by <Link color="inherit" style={{ textDecoration: 'none', color: "#007BFF" }}>{post.author}</Link>
                                                    </Typography>
                                                </Typography>
                                            </Box>
                                        </CardContent>
                                        <CardMedia
                                            component="img"
                                            image={post.image}
                                            alt={`Image for ${post.title}`}
                                            sx={{ width: 200, height: 200, borderRadius: 1, objectFit: 'cover', m: 2 }} // Custom style for image
                                        />
                                    </Box>
                                </Card>

                            ))}
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, }}>
                                <Pagination
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handlePageChange}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Blog;


const styles = {
    sidebar: {
        // Add custom styles for the sidebar
    },
    list: {
        padding: 0,
        listStyle: 'none',
    },
    listItem: {
        // Add custom styles for ListItems if needed
    },
    heading: {
        color: "#007BFF"
    },

};

const posts = [
    {
        "title": "Incoterms® 2020 Explained – The Complete Guide",
        "author": "Ben Thompson",
        "date": "May 28, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2020/01/IncoTerms-2020-Explained-Import-Export-Small-150x150.png",
        "excerpt": "The International Chamber of Commerce have published new Incoterms® 2020 that have come into effect from the 1st of January 2020 . A Bill of Exchange is a document that is used in global trade as a written order binding one party to pay a fixed sum of money global trade as a written order binding one party to pay a fixed sum of money ",
        "url": "/blog/details"
    },
    {
        "title": "Create and Download a Bill of Exchange Document Template for Export",
        "author": "Ben Thompson",
        "date": "August 6, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Bill-of-Exchange-Format-Template-Export-Import-Shipping-150x150.png",
        "excerpt": "A Bill of Exchange is a document that is used in global trade as a written order binding one party to pay a fixed sum of money.A Bill of Exchange is a document that is used in global trade as a written order binding one party to pay a fixed sum of money",
        "url": "/blog/details"
    },
    {
        "title": "Understanding Letter of Credit in International Trade",
        "author": "Emma Smith",
        "date": "June 15, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Letter-of-Credit-International-Trade-150x150.png",
        "excerpt": "A Letter of Credit (LC) is a payment mechanism used in international trade to ensure that payment will be made...",
        "url": "/blog/details"
    },
    {
        "title": "How to Prepare a Proforma Invoice for Export",
        "author": "John Doe",
        "date": "July 10, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Proforma-Invoice-Export-150x150.png",
        "excerpt": "A Proforma Invoice is a preliminary invoice sent to buyers before the sale is completed...",
        "url": "/blog/details"
    },
    {
        "title": "Import Duty and Taxes Explained",
        "author": "Alice Johnson",
        "date": "August 1, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Import-Duty-Taxes-Explained-150x150.png",
        "excerpt": "Understanding import duties and taxes is crucial for businesses involved in international trade...",
        "url": "/blog/details"
    },
    {
        "title": "Guide to Export Documentation and Procedures",
        "author": "Michael Lee",
        "date": "June 25, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Export-Documentation-Procedures-150x150.png",
        "excerpt": "This guide provides a comprehensive overview of export documentation and procedures...",
        "url": "/blog/details"
    },
    {
        "title": "How to Handle Customs Clearance for Exports",
        "author": "Sophia Brown",
        "date": "July 18, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Customs-Clearance-Exports-150x150.png",
        "excerpt": "Customs clearance is a critical step in the export process. Learn how to handle it efficiently...",
        "url": "/blog/details"
    },
    {
        "title": "The Role of Incoterms® in Global Trade",
        "author": "David Wilson",
        "date": "August 8, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Role-of-Incoterms-Global-Trade-150x150.png",
        "excerpt": "Incoterms® are essential for international trade, defining the responsibilities of buyers and sellers...",
        "url": "#"
    },
    {
        "title": "Essential Documents for Importing Goods",
        "author": "Emily Davis",
        "date": "June 5, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Essential-Documents-Importing-Goods-150x150.png",
        "excerpt": "Learn about the essential documents required for importing goods successfully...",
        "url": "#"
    },
    {
        "title": "Export Packing List: What You Need to Know",
        "author": "Daniel Martinez",
        "date": "July 12, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Export-Packing-List-150x150.png",
        "excerpt": "An export packing list is a key document that provides details about the shipment...",
        "url": "#"
    },
    {
        "title": "Navigating International Trade Agreements",
        "author": "Laura Garcia",
        "date": "August 3, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/International-Trade-Agreements-150x150.png",
        "excerpt": "Understand the various international trade agreements and how they affect global trade...",
        "url": "#"
    },
    {
        "title": "Tips for Successful Import Management",
        "author": "Matthew Anderson",
        "date": "June 22, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Import-Management-Tips-150x150.png",
        "excerpt": "Effective import management is crucial for businesses to thrive in international markets...",
        "url": "#"
    },
    {
        "title": "The Impact of Tariffs on Trade",
        "author": "Olivia Thomas",
        "date": "July 25, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Impact-of-Tariffs-on-Trade-150x150.png",
        "excerpt": "Explore how tariffs affect international trade and the strategies to mitigate their impact...",
        "url": "#"
    },
    {
        "title": "Understanding the Export Sales Contract",
        "author": "James Wilson",
        "date": "August 15, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Export-Sales-Contract-150x150.png",
        "excerpt": "Learn about the key components and importance of an export sales contract...",
        "url": "#"
    },
    {
        "title": "How to Manage International Shipping",
        "author": "Isabella Clark",
        "date": "June 30, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/International-Shipping-Management-150x150.png",
        "excerpt": "Managing international shipping involves several critical factors. Discover how to do it effectively...",
        "url": "#"
    },
    {
        "title": "Best Practices for Export Compliance",
        "author": "Liam Lewis",
        "date": "July 28, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Export-Compliance-Best-Practices-150x150.png",
        "excerpt": "Ensure your business meets all export compliance requirements with these best practices...",
        "url": "#"
    },
    {
        "title": "The Role of Freight Forwarders in Exporting",
        "author": "Ava Walker",
        "date": "August 10, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Freight-Forwarders-Exporting-150x150.png",
        "excerpt": "Freight forwarders play a crucial role in the exporting process. Learn how they can help your business...",
        "url": "#"
    },
    {
        "title": "How to Handle Export Documentation Efficiently",
        "author": "Ethan Hall",
        "date": "June 18, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Export-Documentation-Efficiently-150x150.png",
        "excerpt": "Efficient handling of export documentation is key to smooth international trade operations...",
        "url": "#"
    },
    {
        "title": "Export Credit Insurance: What You Need to Know",
        "author": "Mia Young",
        "date": "July 5, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Export-Credit-Insurance-150x150.png",
        "excerpt": "Export credit insurance protects exporters from the risk of non-payment. Find out how it works...",
        "url": "#"
    },
    {
        "title": "Managing International Supply Chains",
        "author": "Noah King",
        "date": "August 12, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/International-Supply-Chains-150x150.png",
        "excerpt": "Managing international supply chains effectively is crucial for global trade success...",
        "url": "#"
    },
    {
        "title": "Understanding Export Financing Options",
        "author": "Sophia Wright",
        "date": "June 29, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Export-Financing-Options-150x150.png",
        "excerpt": "Explore various export financing options available to businesses involved in international trade...",
        "url": "#"
    },
    {
        "title": "The Benefits of Using Trade Credit Insurance",
        "author": "Jackson Scott",
        "date": "July 20, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/11/Trade-Credit-Insurance-Benefits-150x150.png",
        "excerpt": "Trade credit insurance provides protection against non-payment risks. Discover its benefits...",
        "url": "#"
    },
    {
        "title": "Tips for Effective Export Marketing",
        "author": "Emily Brown",
        "date": "August 18, 2024",
        "image": "https://incodocs.com/blog/wp-content/uploads/2021/10/Effective-Export-Marketing-150x150.png",
        "excerpt": "Effective export marketing strategies can help you reach international customers more successfully...",
        "url": "#"
    }
];

const categories = [
    { href: '/blog/details', label: 'Incoterms®' },
    { href: '/blog/details', label: 'Sourcing & Negotiation' },
    { href: '/blog/details', label: 'Landed Cost Calculations' },
    { href: '#', label: 'Contracts, Orders & International Payments' },
    { href: '#', label: 'Manufacturing & Export' },
    { href: '#', label: 'Shipping & Logistics' },
    { href: '#', label: 'Shipping Documentation' },
    { href: '#', label: 'Sales & Marketing' },
    { href: '#', label: 'Warehousing & Inventory' },
    { href: '#', label: 'Software, Tools & Resources' },
    { href: '#', label: 'Global Trade News' },
];
