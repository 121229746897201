import React, { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import CustomTextField from '../common/CustomTextField';
import CustomButton from '../common/CustomButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { SaveRounded } from '@mui/icons-material';
import { REGISTER_PATH } from '../../services/apiRoutes'
import { genericPost } from '../../services/authServices';
import MessageBox from '../common/CustomMessageBox'

// Validation Schema
const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile: Yup.string().required('Mobile Number is required'),
  password: Yup.string().required('Password is required'),
});




const Register = ({ setTabValue }) => {
  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  })

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    password: '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await genericPost(REGISTER_PATH, values);
      if (response.status === 200) {
        setMessage({
          open: true,
          text: 'User register successfully',
          type: 'success'
        })
        setTabValue('login')
      } else {
        setMessage({
          open: true,
          text: 'Something went wrong',
          type: 'error'
        })
      }
    } catch (error) {
      setMessage({
        open: true,
        text: 'Something went wrong',
        type: 'error'
      })
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}

      >
        {({ errors, touched }) => (
          <Form>
            <Box sx={{ mt: 2, color: "#404040" }}>
              <Typography
                variant="h6"
                sx={{ fontSize: "35px", fontWeight: "600", lineHeight: 1 }}
              >
                Welcome!
              </Typography>
              <Typography variant="h6" sx={{ fontSize: "25px", fontWeight: "500" }}>
                Create your account
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Field
                    name="firstName"
                    as={CustomTextField}
                    required
                    placeholder="Enter First Name"
                    fullWidth
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}

                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="lastName"
                    as={CustomTextField}
                    required
                    placeholder="Enter Last Name"
                    fullWidth
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="email"
                    type="email"
                    as={CustomTextField}
                    required
                    placeholder="Enter Email"
                    fullWidth
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="mobile"
                    as={CustomTextField}
                    required
                    placeholder="Enter Mobile Number"
                    fullWidth
                    error={touched.mobile && !!errors.mobile}
                    helperText={touched.mobile && errors.mobile}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="password"
                    type="password"
                    as={CustomTextField}
                    required
                    placeholder="Enter Password"
                    fullWidth
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    sx={{ mb: 2 }}
                  />
                </Grid>
              </Grid>

              <CustomButton
                type="submit"
                startIcon={<SaveRounded />}
                title="Register"
                size="small"
                variant="contained"
              />
            </Box>
          </Form>
        )}
      </Formik>
      <MessageBox
        open={message.open}
        onClose={() => setMessage({ ...message, open: false })}
        message={message.text}
        type={message.type}
      />
    </>
  );
};

export default Register;
