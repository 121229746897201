import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import PagesOutlinedIcon from '@mui/icons-material/PagesOutlined';
import SystemCompany from './SystemCompany';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container spacing={1} sx={{ pb: 1, pl: 2 }}>
                <Grid item xs={4} md={6}>
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <Typography sx={{ color: "#212529", fontWeight: 500, fontSize: 20 }}>
                                System Setting
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Divider />
            <Box sx={{ flexGrow: 1, display: 'flex', height: 224 }}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider', width: 200 }}
                >
                    <Tab
                        sx={{
                            minHeight: 50,
                            height: 50,
                            textTransform: "capitalize",
                            color: "rgba(0, 0, 0, 0.87)",
                            fontWeight: "bold"

                        }}
                        label="Company Setting"
                        {...a11yProps(0)}
                    />
                    <Tab
                        sx={{
                            minHeight: 50,
                            height: 50,
                            textTransform: "capitalize",
                            color: "rgba(0, 0, 0, 0.87)",
                            fontWeight: "bold"

                        }}
                        label="Personal Setting "
                        {...a11yProps(1)}
                    />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <SystemCompany />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SystemCompany />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    dgfdg
                </TabPanel>

            </Box>
        </>
    );
}
