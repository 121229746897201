import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import GetStartedForFre from "../../components/Home/GetStartedForFree";
import Containercarousel from "../../components/Home/Containercarousel";
import HomepagePlatform from "../../components/Home/HomepagePlatform";
import FAQ from "../../components/Home/FAQ";
import { Box, Container, Toolbar } from '@mui/material';
import HomepageBenefits from '../../components/Home/HomepageBenefits';
import HomeKeyFeature from '../../components/Home/HomeKeyFeature';


const Homepage = (props) => {


    return (
        <>
            <Box component="main" sx={{ p: 0 }}>
                {/* <Toolbar /> */}
                <br />
                <br />
                <Container maxWidth="lg">
                    <GetStartedForFre />
                </Container>
                <br />
                <Container >
                    <Containercarousel />
                </Container>
                <br />
                <br />
                <HomeKeyFeature />
                <br />
                <HomepageBenefits />
                <br />
                <HomepagePlatform />
                <br />
                <FAQ />
                <br />
                {/* <HomeFooter /> */}
            </Box>
        </>
    );
}


export default Homepage;
