// CompanyCard.js
import React, { useEffect, useState, useContext} from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  CardMedia,
  Grid,
  Box,
  CircularProgress
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CustomModal from "../components/common/CustomModal";
import AddCompany from "../components/MyCompany/AddCompany";
import { getCountries, getStates } from "../services/CommonServices";
import { genericGet, genericPut } from "../services/apiServices";
import { GET_COMPANY, SET_DEFAULT_COMPANY } from "../services/apiRoutes";
import { AuthContext } from '../context/AuthContext';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: "20px",
  borderRadius: "15px",
  boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
  transition: "0.3s",
  "&:hover": {
    boxShadow: `0 8px 16px rgba(0, 0, 0, 0.3)`,
    transform: "scale(1.02)",
  },
}));

const MediaSection = styled(CardMedia)(({ theme }) => ({
  height: 140,
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  objectFit: "contain",
}));

const CompanyCard = () => {
  const navigate = useNavigate();
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [getAllCompany, setGetAllCompany] = useState([]);
  const [loading, setLoading] = useState(false)
  const { login } = useContext(AuthContext)

  const handleClose = () => {
    setAddCompanyModal(false);
  };

  const handleSetDefault = async (companyId) => {
    console.log(`Set default for company ID: ${companyId}`);
    setLoading(true)
    try {
      const response = await genericPut(`${SET_DEFAULT_COMPANY}/${companyId}`, {});
      if (response.data.accessToken) {
        sessionStorage.setItem('accessToken', response.data.accessToken)
        login(response.data.accessToken)
        getCompanyList();
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false)

    }
  };

  const getCompanyList = async () => {
    try {
      const response = await genericGet({ url: GET_COMPANY });
      let CompanyData = response.data.companies;
      if (CompanyData) {
        setGetAllCompany(CompanyData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCommonData = async () => {
    const getCountriesData = await getCountries();
    if(getCountriesData){
    setCountries(getCountriesData);
    }
  };

  useEffect(() => {
    getCommonData();
    getCompanyList();
  }, []);

  const handleClick = async (companyId) => {
    // Custom logic here (e.g., tracking, analytics, etc.)
    console.log(`Company card clicked: ${companyId}`);

    // Navigate to the route
    navigate(`/app/companysetting/${companyId}`);
  };

  return (
    <>

      <Grid container spacing={3}>
        {getAllCompany.map((company) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={company.companyId}>
            <StyledCard onClick={() => handleClick(company.companyId)}>
              <MediaSection
                component="img"
                src="https://via.placeholder.com/345x140?text=Company+Logo"
                alt="Company Logo"
              />
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  {company.companyName}
                </Typography>
                <Button
                  variant="contained"
                  color={company.isDefault ? 'success' : 'primary'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetDefault(company.companyId);
                  }}
                >
                  {company.isDefault ? "Default" : "Set Default"}
                </Button>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledCard sx={{ display: "flex", justifyContent: "center" }}>
            <CardActions sx={{ height: "300px" }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setAddCompanyModal(true)}
              >
                Add Company
              </Button>
            </CardActions>
          </StyledCard>
        </Grid>
      </Grid>
      <CustomModal open={addCompanyModal} handleClose={handleClose}>
        <AddCompany
          handleClose={handleClose}
          countries={countries}
          getStates={getStates}
        />
      </CustomModal>
    </>
  );
};

export default CompanyCard;