import React from "react";
import {
  Divider,
  Button,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  Card,
  Box,
  TableHead,
  Grid,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { PhoneEnabled, West } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";

const DetailsPageCard = ({ columns, data, title, buttonTitle, handleOpen }) => {
  return (
    <>
      <Box id="tasks">
        <Card variant="outlined">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span style={styles.cardtittile}> {title}</span>
            <span style={{ marginRight: "12px" }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                endIcon={<ArrowDropDownRoundedIcon />}
                // onClick={handleMenuClick}
                style={styles.tablebutton}
                onClick={handleOpen}

              >
                {buttonTitle}
              </Button>
            </span>
          </div>
          <Divider />
          <div style={{ padding: "1rem" }}>
            <TableContainer component={Paper}>
              <Table
                style={{
                  width: "100%",
                  border: "1px solid #dee2e6",
                  borderCollapse: "collapse",
                }}
              >
                <TableHead>
                  <TableRow style={{ border: "1px solid #dee2e6" }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        style={{
                          border: "1px solid #dee2e6",
                          color: "#212529",
                          fontWeight: "600",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "clip",
                          padding: "5px",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.length > 0 ? (
                    data.map((row, rowIndex) => (
                      <TableRow key={rowIndex}
                        style={{
                          border: "1px solid #dee2e6",
                          color: "#212529",
                          fontWeight: "600",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "clip",
                        }}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{
                              border: "1px solid #dee2e6", padding: "5px",
                            }}
                          >
                            {column.Cell ? (
                              // Render custom cell component if defined
                              <column.Cell row={row} />
                            ) : (
                              // Default cell rendering
                              row[column.accessor] // Make sure to use the correct accessor
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow style={{ border: "1px solid #dee2e6" }}>
                      <TableCell
                        colSpan={columns.length}
                        style={{
                          textAlign: "center",
                          border: "1px solid #dee2e6",
                        }}
                      >
                        No Record Found!
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Card>
      </Box>
    </>
  );
};

export default DetailsPageCard;

const styles = {
  table: {
    minWidth: 650,
  },
  textMuted: {
    color: "#6c757d",
  },
  tableCell: {
    paddingBottom: "8px",
    paddingTop: "8px",
    borderBottom: "0px",
  },
  labelCell: {
    width: "180px",
  },
  tableContainer: {
    marginBottom: "16px",
  },
  header: {
    marginBottom: "16px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  customTableRow: {
    height: "20px",
  },
  editButton: {
    textAlign: "right",
  },
  showDetailsSpan: {
    padding: "8px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#212529",
    cursor: "pointer",
  },
  expandIcon: {
    color: "#212529",
  },
  boxheading: {
    width: 70, // Width of the box
    height: 70, // Height of the box, making it square
    // background: 'radial-gradient(circle, #0086c9 0, rgba(0, 134, 201, .5) 0, #0086c9 100%)', // Radial gradient background
    background:
      "radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)", // Radial gradient background
    color: "white", // Text color
    display: "flex", // Center content horizontally
    alignItems: "center", // Center content vertically
    justifyContent: "center", // Center content horizontally
    borderRadius: 14, // Rounded corners
    boxShadow: 3, // Box shadow for depth
  },
  headingname: {
    paddingTop: "10px",
    fontWeight: "600",
    fontSize: "20px",
    color: "#212529",
  },
  avatarcircle: {
    backgroundColor: "#007BFF",
    width: "30px",
    height: "30px",
    fontSize: "15px",
  },
  cardtittile: {
    padding: "8px",
    paddingLeft: "35px",
    fontWeight: 600,
    fontSize: "16px",
    color: "#212529",
  },
  tablebutton: {
    backgroundColor: "#007bff",
    color: "#ffff",
    textTransform: "capitalize",
    borderRadius: 4,
    border: "2px solid #007bff",
    height: 30,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tablecell: {
    border: "1px solid #dee2e6",
    color: "#212529",
    fontWeight: 600,
    fontSize: "14px",
    textAlign: "left",
  },
};
