// src/hooks/useShipmentDetails.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { READ_IMPORT_SHIPMENT } from '../../../services/apiRoutes';

export const useShipmentDetails = (importShipmentId) => {
    const [shipmentDetails, setShipmentDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(READ_IMPORT_SHIPMENT(importShipmentId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setShipmentDetails(response.data.shipment);
            })
            .catch(error => {
                console.error('Error fetching shipment details:', error);
            });
    }, [importShipmentId]);

    return { shipmentDetails, };
};
