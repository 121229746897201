import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/logo.png";
import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



// Define a default theme or remove theme-dependent code
const theme = {
    breakpoints: {
        down: (key) => `@media (max-width:${key})`,
        up: (key) => `@media (min-width:${key})`,
        between: (key1, key2) => `@media (min-width:${key1}) and (max-width:${key2})`,
    },
};

const drawerWidth = 240;
const navItems = [
    { name: 'Login', link: '/login' },
    // { name: 'Get Started For Free', link: '' },
];


const Homepage = (props) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <Link to="/">
                    <img src={Logo} alt="Logo" style={{ width: "103px", height: "22px" }} />
                </Link>
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItemText primary={item.name} />
                            </Link>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    const headermenu = [
        { name: 'Home', link: '/' },
        { name: 'Features', link: '/features' },
        { name: 'Pricing', link: '/pricing' },
    ];

    const options = [
        { name: 'Blog', link: '/blog' },
        { name: 'Help Center', link: '/helpcenter' },
        { name: 'FAQ', link: '/faqpage' }
    ];

    return (
        <>

            <CssBaseline />
            <AppBar component="nav" sx={{ backgroundColor: 'rgb(255, 255, 255)', height: "65px" }}>
                <Box sx={{
                    fontSize: "14px",
                    height: "35px",
                    width: "100%",
                    backgroundColor: "rgb(209, 240, 255)",
                    color: "#707070",
                    textAlign: "right",
                    paddingRight: "30px"


                }}>
                    <Typography>
                        +1234567890 sales@exim.com
                    </Typography>
                </Box>
                <Toolbar style={{ marginTop: "-10px", }} >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon style={{ color: "red" }} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ mr: 8 }}

                    >
                        <Link to="/">
                            <img src={Logo} alt="Logo" style={{ width: "103px", height: "22px" }} />
                        </Link>

                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {headermenu.map((menu) => (
                            <Button
                                key={menu.name}
                                component={Link}
                                to={menu.link}
                                sx={{
                                    my: 2,
                                    color: '#707070',
                                    display: 'block',
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                    mr: 2
                                }}
                            >
                                {menu.name}
                            </Button>
                        ))}
                        <Button
                            sx={{
                                my: 2,
                                color: '#707070',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: "500",
                                textTransform: "capitalize",
                                mr: 2,
                                fontSize: "16px",
                            }}
                            endIcon={<KeyboardArrowDownIcon />}
                            onClick={handleMenuOpen}
                        >
                            Resource
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {options.map((option) => (
                                <MenuItem
                                    key={option.name}
                                    component={Link}
                                    to={option.link}
                                    onClick={handleMenuClose}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Button
                            component={Link}
                            to="/login"
                            variant="outlined"
                            sx={{
                                color: "#707070",
                                textTransform: "capitalize",
                                borderRadius: 1,
                                border: "1px solid #707070",
                                height: 30,
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "5px 5px",
                            }}
                        >
                            Login
                        </Button>
                    </Box>
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            backgroundColor: "#007bff",
                            color: "#ffffff",
                            textTransform: "capitalize",
                            borderRadius: 1,
                            border: "2px solid #007bff",
                            height: 30,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "5px 5px",
                        }}
                    >
                        Get Start For Free
                    </Button>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </>
    );
}


export default Homepage;


