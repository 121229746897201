import React, { useState, useEffect } from 'react';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CreateTasks from '../CreateOverView/CreateTasks';
import CustomModal from '../../common/CustomModal'; // Assuming you have a CustomModal component
import { Delete_task, GET_task } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';
import UpdateTasks from '../UpdateOverView/UpdateTasks';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CustomDelete from '../../../components/common/CustomDeleteAlert'
import axios from 'axios';
import MessageBox from '../../common/CustomMessageBox';
import { getCompanies, getLeadSource, getModules, getPriorities, getPrioritiesLossReasons, getTaskStatuses } from '../../../services/CommonServices';


export function Tasks() {
    const [taskData, setTaskData] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const [leadSource, setLeadSource] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [modules, setModules] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [priorities, setPriorities] = useState([]);

    const getCommonData = async () => {
        const leadSourceData = await getLeadSource();
        setLeadSource(leadSourceData);
        const companiesData = await getCompanies();
        setCompanies(companiesData);
        const modulesData = await getModules();
        setModules(modulesData);
        const taskStatusesData = await getTaskStatuses();
        setTaskStatuses(taskStatusesData);
        const prioritiesData = await getPriorities();
        setPriorities(prioritiesData);

    };

    const handleOpenCreate = () => {
        setCreateModalOpen(true);
    };

    const handleCloseCreate = () => {
        setCreateModalOpen(false);
    };

    const handleOpenUpdate = (taskId) => {
        setSelectedTask(taskId);
        setUpdateModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
        setSelectedTask(null);
    };



    const fetchTaskData = async () => {
        try {
            const response = await genericGet({ url: GET_task });

            if (response.data.success && response.data.tasks) {
                setTaskData(response.data.tasks);
            } else {
                setError('No tasks found');
            }
        } catch (error) {
            setError('Error fetching task data');
            console.error('Error fetching task data', error);
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (taskId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(Delete_task(taskId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchTaskData()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting lead", error);
        }
    };



    useEffect(() => {
        fetchTaskData();
        getCommonData();
    }, []);

    const handleOpenDeleteAlert = (taskId) => {
        setDeleteAlert(true)
        setRowId(taskId)
    }

    const handleViewClick = (taskId) => {
        // Handle view click logic here
        console.log(`View details for callId: ${taskId}`);
    };

    const handleUpdateClick = (taskId) => {
        // Handle update click logic here
        handleOpenUpdate(taskId);
    };



    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    const TasksColumn = [
        { id: 'taskId', label: 'SN', accessor: 'taskId' },
        { id: 'taskOwner', label: 'Task Owner', accessor: '' },
        { id: 'assignTo', label: 'Assign To', accessor: '' },
        { id: 'contactPerson', label: 'Contact Person', accessor: '' },
        { id: 'taskName', label: 'Task Name', accessor: '' },
        { id: 'subject', label: 'Subject', accessor: 'subject' },
        { id: 'dueDate', label: 'Due Date', accessor: '' },
        { id: 'status', label: 'Status', accessor: '' },
        { id: 'priority', label: 'Priority', accessor: '' },
        { id: 'description', label: 'Description', accessor: '' },
        { id: 'createdAt', label: 'Created At', accessor: 'createdAt' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => handleViewClick(row.taskId)} style={{ marginRight: '1px' }}>
                            <VisibilityIcon style={{ color: "#059212" }} />
                        </IconButton>
                        <IconButton onClick={() => handleUpdateClick(row.taskId)} style={{ marginRight: '1px' }}>
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.taskId)}>
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </div>

                </>
            ),
        },
    ];

    return (
        <>

            <DetailsPageCard
                data={taskData}
                columns={TasksColumn}
                title={'Tasks'}
                buttonTitle={'Create Tasks'}
                handleOpen={handleOpenCreate}
                handleOpenUpdate={handleOpenUpdate} // Pass handler for update modal if needed
            />
            <CustomModal open={createModalOpen} handleClose={handleCloseCreate}>
                <CreateTasks
                    fetchTaskData={fetchTaskData}
                    handleClose={handleCloseCreate}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    companies={companies}
                    modules={modules}
                    taskStatuses={taskStatuses}
                    priorities={priorities}
                />
            </CustomModal>
            <CustomModal open={updateModalOpen} handleClose={handleCloseUpdate}>
                <UpdateTasks
                    fetchTaskData={fetchTaskData}
                    handleClose={handleCloseUpdate}
                    taskId={selectedTask}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    companies={companies}
                    modules={modules}
                    taskStatuses={taskStatuses}
                    priorities={priorities}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}

            />
        </>
    );
}


