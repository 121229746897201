import React, { useState } from 'react';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CreateNotes from '../CreateOverView/CreateNotes';
import CustomModal from '../../common/CustomModal'; // Assuming you have a CustomModal component

export function Notes() {
    const [leadModal, setLeadModal] = useState(false);

    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false);
    };

    const NotesColumn = [
        { id: 'sn', label: 'SN', accessor: 'sn' },
        { id: 'notes', label: 'Notes', accessor: 'notes' },

    ];

    return (
        <>
            <DetailsPageCard
                columns={NotesColumn}
                title={'Notes'}
                buttonTitle={'Create Notes'}
                handleOpen={handleOpen}
            />
            <CustomModal open={leadModal} handleClose={handleClose}>
                <CreateNotes />
            </CustomModal>
        </>
    );
}
