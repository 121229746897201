import React, { useState, useEffect, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CreateCall from '../CreateOverView/CreateCall';
import UpdateCall from '../UpdateOverView/UpdateCall';
import CustomModal from '../../common/CustomModal'; // Assuming you have a CustomModal component
import { genericGet } from '../../../services/apiServices';
import { Delete_call_id, GET_call } from '../../../services/apiRoutes';
import { getIndustryType, getRating, getLeadSource, getLeadStatus, getCountries, getBusinessGroup, getCompanyTypes, getCallPurposeType, getCompanyOwnershipType, getCallOutcomeType, getPrioritiesLossReasons, getCompanyModules, getContactDepartments, getContactGroups, getContactTypes, getModules, getTaskStatuses, getLeadStages, getPriorities, getCompanies } from '../../../services/CommonServices'

import CustomDelete from '../../../components/common/CustomDeleteAlert'

import axios from 'axios';
import MessageBox from '../../common/CustomMessageBox';

export function Call() {
    const [callData, setCallData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedCall, setSelectedCall] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false)
    const [rowId, setRowId] = useState('')
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    })

    const [leadSource, setLeadSource] = useState([]);
    const [leadStatus, setLeadStatus] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [callPurposeTypes, setCallPurposeTypes] = useState([]);
    const [callOutcomeTypes, setCallOutcomeTypes] = useState([]);
    const [prioritiesLossReasons, setPrioritiesLossReasons] = useState([]);
    const [taskStatuses, setTaskStatuses] = useState([]);
    const [leadStages, setLeadStages] = useState([]);
    const [priorities, setPriorities] = useState([]);

    const getCommonData = async () => {
        const leadSourceData = await getLeadSource();
        setLeadSource(leadSourceData);
        const leadStatusData = await getLeadStatus();
        setLeadStatus(leadStatusData);
        const companiesData = await getCompanies();
        setCompanies(companiesData);
        const companyTypesData = await getCompanyTypes();
        setCompanyTypes(companyTypesData);
        const callPurposeData = await getCallPurposeType();
        setCallPurposeTypes(callPurposeData);
        const callOutcomeData = await getCallOutcomeType();
        setCallOutcomeTypes(callOutcomeData);
        const prioritiesLossReasonsData = await getPrioritiesLossReasons();
        setPrioritiesLossReasons(prioritiesLossReasonsData);
        const taskStatusesData = await getTaskStatuses();
        setTaskStatuses(taskStatusesData);
        const leadStagesData = await getLeadStages();
        setLeadStages(leadStagesData);
        const prioritiesData = await getPriorities();
        setPriorities(prioritiesData);
    };

    const handleOpenCreate = () => {
        setCreateModalOpen(true);
    };

    const handleCloseCreate = () => {
        setCreateModalOpen(false);
    };

    const handleOpenUpdate = (callId) => {
        setSelectedCall(callId);
        setUpdateModalOpen(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModalOpen(false);
        setSelectedCall(null);
    };

    const handleViewClick = (callId) => {
        // Handle view click logic here
        console.log(`View details for callId: ${callId}`);
    };

    const handleUpdateClick = (call) => {
        // Handle update click logic here
        handleOpenUpdate(call);
    };



    const handleDelete = async (callId) => {
        setLoading(true)
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(Delete_call_id(callId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchData()
                setDeleteAlert(false)
            } else {
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.error("Error deleting lead", error);
        }
    };


    const fetchData = async () => {
        try {
            const response = await genericGet({ url: GET_call });
            if (response.data.success && response.data.calls) {
                setCallData(response.data.calls);
            } else {
                setError('No calls found');
            }
        } catch (error) {
            setError('Error fetching call data');
            console.error('Error fetching call data', error);
        } finally {
            setLoading(false);
        }
    };


    const handleOpenDeleteAlert = (callId) => {
        setDeleteAlert(true)
        setRowId(callId)
    }

    useEffect(() => {
        fetchData();
        getCommonData()
    }, []);


    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    const CallColumn = [
        { id: 'sn', label: 'SN', accessor: 'callId' },
        { id: 'callTo', label: 'Call To', accessor: 'callTo' },
        { id: 'callType', label: 'Call Type', accessor: 'callType' },
        { id: 'subject', label: 'Subject', accessor: 'subject' },
        { id: 'details', label: 'Details', accessor: 'details' },
        { id: 'startTime', label: 'Start Time', accessor: 'startTime' },
        { id: 'duration', label: 'Duration', accessor: 'callDuration' },
        { id: 'purpose', label: 'Purpose', accessor: 'callPurposeId' },
        { id: 'outcome', label: 'Outcome', accessor: 'callOutcomeId' },
        { id: '', label: 'Description', accessor: '' },
        { id: 'calledAt', label: 'Called at', accessor: 'calledAt' },

        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton onClick={() => handleViewClick(row.callId)} style={{ marginRight: '1px' }}>
                            <VisibilityIcon style={{ color: "#059212" }} />
                        </IconButton>
                        <IconButton onClick={() => handleUpdateClick(row.callId)} style={{ marginRight: '1px' }}>
                            <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteAlert(row.callId)}>
                            <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                        </IconButton>
                    </div>

                </>
            ),
        },
    ];

    return (
        <>

            <DetailsPageCard
                columns={CallColumn}
                data={callData}
                title={'Call'}
                buttonTitle={'Create Call'}
                handleOpen={handleOpenCreate}
            />
            <CustomModal open={createModalOpen} handleClose={handleCloseCreate}>
                <CreateCall
                    fetchData={fetchData}
                    handleClose={handleCloseCreate}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    leadStatus={leadStatus}
                    companies={companies}
                    companyTypes={companyTypes}
                    callPurposeTypes={callPurposeTypes}
                    callOutcomeTypes={callOutcomeTypes}
                    prioritiesLossReasons={prioritiesLossReasons}
                    taskStatuses={taskStatuses}
                    leadStages={leadStages}
                    priorities={priorities}
                />
            </CustomModal>

            <CustomModal open={updateModalOpen} handleClose={handleCloseUpdate}>
                <UpdateCall
                    fetchData={fetchData}
                    handleClose={handleCloseUpdate}
                    callId={selectedCall}
                    setMessage={setMessage}
                    leadSource={leadSource}
                    leadStatus={leadStatus}
                    companies={companies}
                    companyTypes={companyTypes}
                    callPurposeTypes={callPurposeTypes}
                    callOutcomeTypes={callOutcomeTypes}
                    prioritiesLossReasons={prioritiesLossReasons}
                    taskStatuses={taskStatuses}
                    leadStages={leadStages}
                    priorities={priorities}
                />
            </CustomModal>
            <MessageBox
                open={message.open}
                onClose={() => setMessage({ ...message, open: false })}
                message={message.text}
                handleClose={() => setMessage({ ...message, open: false })}
                type={message.type}
            />
            <CustomDelete
                handleDelete={handleDelete}
                open={deleteAlert}
                rowId={rowId}
                handleClose={() => setDeleteAlert(false)}

            />
        </>
    );
}
