// src/components/ContactTypes.js

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton } from "@mui/material";
import { SaveRounded } from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DataTable from "../../components/common/DataTable";
import CustomTextField from "../common/CustomTextField";
import CustomButton from "../common/CustomButton";
import { getContactTypes } from "../../services/CommonServices";

const title = "Contact Types";

const ContactTypes = () => {
    const navigate = useNavigate();
    const [contactTypes, setContactTypes] = useState([]);

    const [formData, setFormData] = useState({
        shortName: "",
        title: "",
    });

    const columns = useMemo(
        () => [
            { accessorKey: 'id', header: 'SN', size: 50 },
            { accessorKey: 'shortName', header: 'Short Name', size: 150 },
            { accessorKey: 'title', header: 'Contact Type', size: 200 },
            {
                accessorKey: "action",
                header: "Action",
                size: 50,
                Cell: ({ row }) => (
                    <IconButton onClick={() => handleViewClick(row.original.id)}>
                        <VisibilityIcon />
                    </IconButton>
                ),
            },
        ],
        []
    );

    const handleViewClick = (id) => {
        navigate(`/app/contact-type/${id}`);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const fetchContactTypes = async () => {
        const contactTypesData = await getContactTypes();
        setContactTypes(contactTypesData);
    };

    useEffect(() => {
        fetchContactTypes();
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Implement your save logic here
        console.log("Form submitted:", formData);
    };

    return (
        <>
            <Box sx={{ paddingLeft: "10px", paddingTop: "10px" }} >
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CustomTextField
                                type="text"
                                name="shortName"
                                fieldLabel="Short Name:"
                                value={formData.shortName}
                                required
                                onChange={handleChange}
                                placeholder="Enter Short Name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <CustomTextField
                                type="text"
                                name="title"
                                fieldLabel="Contact Type:"
                                value={formData.title}
                                required
                                onChange={handleChange}
                                placeholder="Enter Contact Type"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <CustomButton
                                type="submit"
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="medium"
                                variant="contained"
                            />
                        </Grid>
                    </Grid>
                </form>
            </Box>
            <Box sx={{ padding: "10px" }}>
                <DataTable
                    title={title}
                    columns={columns}
                    data={contactTypes}
                />
            </Box>
        </>
    );
};

export default ContactTypes;
