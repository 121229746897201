import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateCOA from '../CreateForm/CreateCOA';
import UpdateCOA from '../UpdateForm/UpdateCOA';
import MessageBox from '../../common/CustomMessageBox';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { LIST_COAS_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';

const COA = () => {
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('COA');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [selectedCOA, setSelectedCOA] = useState(null);

    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails } = useExportShipmentDetails(exportShipmentId);

    // Fetch COA data
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: LIST_COAS_EXPORT });
            if (response.data.success && response.data.data) {
                setData(response.data.data);
            } else {
                setError('No COA data found');
            }
        } catch (error) {
            setError('Error fetching COA data');
            console.error('Error fetching COA data', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Table columns
    const columns = [
        { id: 'coaId', label: 'SN', accessor: 'coaId' },
        { id: 'shippingId', label: 'Shipping Id', accessor: 'shippingId' },
        { id: 'date', label: 'Date', accessor: 'date' },
        { id: 'productName', label: 'Product Name', accessor: 'productName' },
        { id: 'batchNo', label: 'Batch No.', accessor: 'batchNo' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.coaId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.coaId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.coaId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    // Event handlers
    const handleViewClick = (coa) => {
        setSelectedCOA(coa);
        setModal(true);
    };

    const handleUpdateClick = (coa) => {
        setSelectedCOA(coa);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (coa) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setSelectedCOA(null);
    };

    const handleBackClick = () => {
        navigate('/app/export/shipment');
    };

    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }

    const companyName = shipmentDetails.company.companyName;
    const phoneNumber = shipmentDetails.shipmentId;
    const initial = companyName ? companyName.charAt(0).toUpperCase() : '';

    // JSX Structure
    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}>
                <DetailsPageCard
                    columns={columns}
                    title="COA"
                    buttonTitle="Create COA"
                    handleOpen={handleOpen}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={modal} handleClose={handleClose}>
                    <CreateCOA fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleClose} />
                </CustomModal>

                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateCOA
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                        coaId={selectedCOA}
                    />
                </CustomModal>

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </Main>
        </div>
    );
};

export default COA;
