import React, { useState } from 'react';

import { ClearRounded, SaveRounded } from "@mui/icons-material";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import DetailsPageCompany from '../../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../../common/DetailsPage/DetailPageClient';
import { TaskCardColumns, AppBarMenu, NotesCardColumns } from '../../../constants/DetailsPage'
import { Main } from '../../../constants/DetailsMain';
import { useNavigate, useParams } from 'react-router-dom';

import CustomModal from "../../../components/common/CustomModal";
import Createimportshipement from "../../../components/importshipement/createimportshipement";
import { Button, Grid } from '@mui/material';
// import CustomTextField from "../../common/CustomTextField";
import CustomTextField from "../../common/CustomTextField";
import CustomButton from "../../common/CustomButton";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";

import Typography from '@mui/material/Typography';
import { Card, Divider, } from '@mui/material';
import { useShipmentDetails } from './useShipmentDetails';


export default function PersistentDrawerLeft() {
    const { importShipmentId } = useParams();

    const [open, setOpen] = React.useState(true);
    const [leadModal, setLeadModal] = useState(false);

    const [activeTab, setActiveTab] = React.useState('Tracker');
    const navigate = useNavigate();

    const { shipmentDetails } = useShipmentDetails(importShipmentId);
    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };


    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }
    const companyName = shipmentDetails.company.companyName;
    const phoneNumber = shipmentDetails.shipmentId;
    const initial = shipmentDetails.company.companyName ? shipmentDetails.company.companyName.charAt(0).toUpperCase() : '';

    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    return (
        <>
            <div>
                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarMenu(importShipmentId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}

                />

                <Main open={open}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Card variant="outlined" sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                <Grid container spacing={1} sx={{ paddingBottom: "5px", paddingTop: "10px" }}>
                                    <Grid item xs={12} md={12}>
                                        <Typography sx={{ color: "#212529", fontWeight: 500, fontSize: "20px" }}>
                                            Create Shipping Tracker
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />

                                <form >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <CustomTextField
                                                type='date'
                                                name="fobValue"
                                                fieldLabel="Date"
                                                required={true}
                                                // value={formData.fobValue}
                                                // onChange={handleChange}
                                                placeholder="Enter"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <CustomAutocomplete
                                                options={shippingOptions}
                                                fieldLabel="Status"
                                                required={true}
                                                getOptionLabel={(option) => option.title}
                                                // value={formData.shippingOption}
                                                // onChange={handleAutocompleteChange}
                                                placeholder="Select Status"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <CustomTextarea
                                                name="country"
                                                type="text"
                                                fieldLabel="Note "
                                                placeholder="Write a Note.."
                                                sx={{ height: "150px !important" }}
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} container justifyContent="center">
                                            <CustomButton
                                                startIcon={<SaveRounded />}
                                                title="Save"
                                                size="small"
                                                variant="contained"
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </Card>

                        </Grid>
                        <Grid item xs={6}>
                            <Card variant="outlined" sx={{ paddingLeft: "10px", paddingRight: "10px" }}>
                                <Grid container spacing={1} sx={{ paddingBottom: "5px", paddingTop: "10px" }}>
                                    <Grid item xs={12} md={12}>
                                        <Typography sx={{ color: "#212529", fontWeight: 500, fontSize: "20px" }}>
                                            Shipping Timeline
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />

                                <div style={styles.timeline}>
                                    {events.map((event, index) => (
                                        <div style={styles.timelineItem} key={index}>
                                            <div style={styles.timelineTime}>{event.time}</div>
                                            <div style={styles.timelineIcon}>{event.icon}</div>
                                            <Card style={styles.timelineContent} variant='outlined'>
                                                <h6 style={styles.timelineContentTitle}>{event.title}</h6>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Grid>
                    </Grid>

                </Main>

            </div >
        </>
    );
}

const shippingOptions = [
    { title: "Container Stuffing" },
    { title: "Transit" },
    { title: "Vessel Dispatch" },
    { title: "Receiving Port" },
    { title: "CFS" },
    { title: "Custom Clearing" },
    { title: "Dispatch to Warehouse" },
    { title: "Empty container" },
];


// Define the events with titles and icons
const events = [
    {
        time: '9:30 am',
        icon: '📦', // Use a relevant icon for "Container Stuffing"
        title: 'Container Stuffing',
        description: 'Preparing containers for shipment'
    },
    {
        time: '10:00 am',
        icon: '🚢', // Use a relevant icon for "Vessel Dispatch"
        title: 'Vessel Dispatch',
        description: 'Dispatching vessel for transportation'
    },
    {
        time: '11:00 am',
        icon: '🚚', // Use a relevant icon for "Transit"
        title: 'Transit',
        description: 'Goods in transit to destination'
    },
    {
        time: '12:00 pm',
        icon: '🏢', // Use a relevant icon for "Receiving Port"
        title: 'Receiving Port',
        description: 'Goods received at the port'
    },
    {
        time: '1:00 pm',
        icon: '📦', // Use a relevant icon for "CFS"
        title: 'CFS',
        description: 'Container Freight Station activities'
    },
    {
        time: '2:00 pm',
        icon: '📝', // Use a relevant icon for "Custom Clearing"
        title: 'Custom Clearing',
        description: 'Clearing goods through customs'
    },
    {
        time: '3:00 pm',
        icon: '🏠', // Use a relevant icon for "Dispatch to Warehouse"
        title: 'Dispatch to Warehouse',
        description: 'Dispatching goods to the warehouse'
    },
    {
        time: '4:00 pm',
        icon: '📦', // Use a relevant icon for "Empty Container"
        title: 'Empty Container',
        description: 'Returning empty containers'
    }
];


const styles = {
    timeline: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        margin: '0 auto',
        padding: '20px 0',
        maxWidth: '600px'
    },
    timelineItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px'
    },
    timelineTime: {
        flex: '0 0 80px',
        textAlign: 'right',
        paddingRight: '20px',
        color: '#757575',
        fontSize: '10px'
    },
    timelineIcon: {
        flex: '0 0 40px',
        textAlign: 'center',
        fontSize: '24px'
    },
    timelineContent: {
        flex: 1,
        paddingLeft: '20px',
        // backgroundColor: '#f5f5f5',
        borderRadius: '5px',
        padding: '5px'
    },
    timelineContentTitle: {
        margin: 0,
        fontSize: '14px',
        color: '#212529'
    },
    timelineContentDescription: {
        margin: 0,
        color: '#757575'
    }
};
