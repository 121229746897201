import React from 'react';
import { Grid, Card, CardContent, Typography, Container, Box, CardHeader, Avatar, Divider } from '@mui/material';
import { Padding } from '@mui/icons-material';
import WorkIcon from '@mui/icons-material/Work';


const HomepageBenefits = () => {
    return (
        <>
            <Box sx={{ pt: 4, pb: 4, backgroundColor: "rgba(51, 112, 235, .04)" }}>
                <Container>
                    <Typography component="h1" sx={{ fontWeight: "bold", mb: 4, fontSize: "2.5rem" }}>
                        Drive efficiency. Reduce overheads.
                        <br />
                        Maximize trade profitability.
                    </Typography>
                </Container>
                <Box sx={{ ml: 10, mr: 10, backgroundColor: "#007BFF", p: 4 }}>
                    <Container >
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={8} md={4} lg={4} xl={4}>
                                <Card variant='outlined' sx={{ height: "615px", backgroundColor: "#007BFF", color: "#FFFF"  ,borderColor:"#ffff" }}>
                                    <CardContent sx={{ p: 3 }}>
                                        <WorkIcon sx={{ color: "#FFFF", fontSize: "75px", }} />
                                        <Typography sx={{ color: "#FFFF", fontSize: "20px", fontWeight: "700", }} variant="h5">
                                            Streamline documentation
                                        </Typography>
                                        <Typography sx={{ color: "#EEF1FF", fontSize: "20px", fontWeight: "700", }} variant="h5">
                                            and eliminate errors
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Typography variant="h1" component="p" sx={{ fontSize: 50, fontWeight: 'medium' }}>
                                                            80%*
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body1" component="p" sx={{ fontSize: 16, fontWeight: 'medium', textAlign: 'right' }}>
                                                            Less time spent on documentation
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{ backgroundColor: "#FFFF", height: 2, mt: 2, mb: 2 }} />
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Typography variant="h3" component="p" sx={{ fontSize: 30, fontWeight: 'semi-bold' }}>
                                                            Near-Zero
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body1" component="p" sx={{ fontSize: 16, fontWeight: 'medium', textAlign: 'right' }}>
                                                            Errors, and minimal shipment & delays
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{ backgroundColor: "#FFFF", height: 2, mt: 2, mb: 2 }} />
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <Typography variant="h3" component="p" sx={{ fontSize: 32, fontWeight: 'semi-bold' }}>
                                                            &lt; 5 mins
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography variant="body1" component="p" sx={{ fontSize: 16, fontWeight: 'medium', textAlign: 'right' }}>
                                                            To signup and get started
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Divider sx={{ backgroundColor: "#FFFF", height: 2, mt: 2, mb: 2 }} />
                                            </Box>
                                            <Typography variant="body2" component="p" sx={{ fontSize: 14, fontWeight: 'medium', textAlign: 'right', marginTop: 1.5 }}>
                                                * Up to 95% when integrated with ERP system
                                            </Typography>
                                        </Box>

                                    </CardContent>
                                </Card>
                            </Grid>


                            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Card variant='outlined' sx={{ height: "300px", }}>
                                            <CardContent sx={{ p: 3 }}>
                                                <Box sx={{ textAlign: "center" }}>
                                                    <Typography sx={{ color: "#007BFF", fontSize: "20px", fontWeight: "700", }} variant="h5">Everything in one place,</Typography>
                                                    <Typography sx={{ fontSize: "20px", fontWeight: "700", }} variant="h5" >accessible from anywhere   </Typography>
                                                </Box>
                                                <Box>
                                                    <WorkIcon sx={{ color: "#007bff", fontSize: "75px", }} />
                                                </Box>
                                                <Typography variant="body2"
                                                    sx={{ fontSize: "14px", fontWeight: "100", pt: 2, color: "rgba(3, 27, 78, .55)", textAlign: "center" }}
                                                >
                                                    Say goodbye to switching between different tools all day,
                                                    your IncoDocs workspace lets you do your to-do from one place, in the office or remotely.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>



                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Card variant='outlined' sx={{ height: "300px", }}>
                                            <CardContent sx={{ p: 3, textAlign: "center" }}>
                                                <WorkIcon sx={{ color: "#007bff", fontSize: "65px", }} />
                                                <Typography sx={{ color: "#007BFF", fontSize: "15px", fontWeight: "700", }} variant="h5">Everything in one place,</Typography>
                                                <Typography sx={{ fontSize: "15px", fontWeight: "700", }} variant="h5" >accessible from anywhere   </Typography>
                                                <Typography variant="body2"
                                                    sx={{ fontSize: "14px", fontWeight: "100", pt: 3, color: "rgba(3, 27, 78, .55)" }}
                                                >
                                                    Say goodbye to switching between different tools all day,
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Card variant='outlined' sx={{ height: "300px", }}>
                                            <CardContent sx={{ p: 3, textAlign: "center" }}>
                                                <WorkIcon sx={{ color: "#007bff", fontSize: "65px", }} />
                                                <Typography sx={{ color: "#007BFF", fontSize: "15px", fontWeight: "700", }} variant="h5">Everything in one place,</Typography>
                                                <Typography sx={{ fontSize: "15px", fontWeight: "700", }} variant="h5" >accessible from anywhere   </Typography>
                                                <Typography variant="body2"
                                                    sx={{ fontSize: "14px", fontWeight: "100", pt: 3, color: "rgba(3, 27, 78, .55)" }}
                                                >
                                                    Say goodbye to switching between different tools all day,
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Card variant='outlined' sx={{ height: "300px", }}>
                                            <CardContent sx={{ p: 3, textAlign: "center" }}>
                                                <WorkIcon sx={{ color: "#007bff", fontSize: "65px", }} />
                                                <Typography sx={{ color: "#007BFF", fontSize: "15px", fontWeight: "700", }} variant="h5">Everything in one place,</Typography>
                                                <Typography sx={{ fontSize: "15px", fontWeight: "700", }} variant="h5" >accessible from anywhere   </Typography>
                                                <Typography variant="body2"
                                                    sx={{ fontSize: "14px", fontWeight: "100", pt: 3, color: "rgba(3, 27, 78, .55)" }}
                                                >
                                                    Say goodbye to switching between different tools all day,
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

export default HomepageBenefits;
