// LeadDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailPageCustum from '../../common/DetailsPage/DetailPageCustum';
import { GET_Lead_leadId } from '../../../services/apiRoutes';

const LeadDetail = () => {
    const { leadId } = useParams();
    const [leadDetails, setLeadDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(GET_Lead_leadId(leadId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setLeadDetails(response.data.lead);
            })
            .catch(error => {
                console.error('Error fetching lead details:', error);
            });
    }, [leadId]);

    if (!leadDetails) {
        return <p>Loading...</p>;
    }

    const tableData = [
        { label: 'Lead ID', value: leadDetails.leadId || 'N/A' },
        { label: 'Lead Owner', value: leadDetails.leadOwner || 'N/A' },
        { label: 'Company ID', value: leadDetails.companyId || 'N/A' },
        { label: 'User', value: `${leadDetails.user?.firstName || 'N/A'} ${leadDetails.user?.lastName || ''}` },
        { label: 'First Name', value: leadDetails.firstName || 'N/A' },
        { label: 'Last Name', value: leadDetails.lastName || 'N/A' },
        { label: 'Title', value: leadDetails.title || 'N/A' },
        { label: 'Email', value: leadDetails.email || 'N/A' },
        { label: 'Phone', value: leadDetails.phone || 'N/A' },
        { label: 'Mobile', value: leadDetails.mobile || 'N/A' },
        { label: 'Industry', value: leadDetails.industry || 'N/A' },
        { label: 'Number of Employees', value: leadDetails.numberOfEmployees || 'N/A' },
        { label: 'Annual Revenue', value: leadDetails.annualRevenue || 'N/A' },
        { label: 'Rating', value: leadDetails.rating || 'N/A' },
        { label: 'Expected Date', value: leadDetails.expectedDate ? new Date(leadDetails.expectedDate).toLocaleDateString() : 'N/A' },
        { label: 'Email Opt-Out', value: leadDetails.emailOptOut ? 'Yes' : 'No' },
        { label: 'Business Group', value: leadDetails.businessGroup || 'N/A' },
        { label: 'Secondary Email', value: leadDetails.secondaryEmail || 'N/A' },
        { label: 'Street', value: leadDetails.street || 'N/A' },
        { label: 'Country', value: leadDetails.country?.name || 'N/A' },
        { label: 'State', value: leadDetails.state?.name || 'N/A' },
        { label: 'City', value: leadDetails.city?.name || 'N/A' },
        { label: 'Zip Code', value: leadDetails.zipCode || 'N/A' },
        { label: 'Description', value: leadDetails.description || 'N/A' },
    ];

    return <DetailPageCustum title="Lead Details" data={tableData} />;
};

export default LeadDetail;
