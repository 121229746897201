import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import CustomModal from '../../../components/common/CustomModal';
import CreateExpense from '../CreateForm/CreateExpense';
import UpdateExpense from '../UpdateForm/UpdateExpense';
import { LIST_EXPENSE_VOUCHERS } from '../../../services/apiRoutes';
import { AppBarMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import { useShipmentDetails } from './useShipmentDetails';
import { genericPost } from '../../../services/authServices';
import MessageBox from '../../common/CustomMessageBox';
import { getCurrencyList, getExpenseLedgerList } from '../../../services/CommonServices';

export default function ExpenseVoucher() {
    const { importShipmentId } = useParams();
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Expense');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currencyList, setCurrencyList] = useState([]);
    const [expenseLedger, setExpenseLedger] = useState([]);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });
    const [expenseVoucherId, setExpenseVoucherId] = useState('');
    const navigate = useNavigate();
    const { shipmentDetails } = useShipmentDetails(importShipmentId);

    const supplierId = useRef(null);
    const shipmentId = useRef(null);
    const cifFob = useRef(null);
    const hasFetched = useRef(false);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const payload = { clientCompanyId: supplierId.current };
            const response = await genericPost(LIST_EXPENSE_VOUCHERS, payload);
            if (response.data.success && response.data.expenseVoucher) {
                const expenseVoucherData = response.data.expenseVoucher.map((voucher) => ({
                    sn: voucher.expenseVoucherId || '',
                    date: voucher.date || '',
                    voucherNo: voucher.voucherNo || '',
                    ledgerName: voucher.expenseLedger?.ledgerName || '',
                    amount1: voucher.amount || '',
                    baseCurrency: voucher.baseCurrency?.currency || '',
                    amount2: voucher.amountInBaseCurrency || '',
                }));
                setData(expenseVoucherData);
            } else {
                setError('No expense vouchers found');
            }
        } catch (error) {
            setError('Error fetching expense vouchers');
            console.error('Error fetching expense vouchers', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const getCommonData = useCallback(async () => {
        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
        const expenseLedger = await getExpenseLedgerList();
        setExpenseLedger(expenseLedger);
    }, []);

    useEffect(() => {
        if (shipmentDetails && !hasFetched.current) {
            supplierId.current = shipmentDetails.supplierId;
            shipmentId.current = shipmentDetails.shipmentId;
            cifFob.current = shipmentDetails.cifFob.cifFobName;
            fetchData();
            getCommonData();
            hasFetched.current = true;
        }
    }, [shipmentDetails, fetchData, getCommonData]);

    const handleViewClick = (id) => {
        // Logic for viewing details
    };

    const handleUpdateClick = (id) => {
        setExpenseVoucherId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setExpenseVoucherId('');
    };

    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    const expenseVoucherColumns = [
        { id: 'sn', label: 'SN', accessor: 'sn' },
        { id: 'date', label: 'Date', accessor: 'date' },
        { id: 'voucherNo', label: 'Voucher No.', accessor: 'voucherNo' },
        { id: 'ledgerName', label: 'Expense Ledger', accessor: 'ledgerName' },
        { id: 'amount1', label: 'Amount', accessor: 'amount1' },
        { id: 'baseCurrency', label: 'Base Currency', accessor: 'baseCurrency' },
        { id: 'amount2', label: 'Amount in Base Currency', accessor: 'amount2' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.sn)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.sn)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.sn)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarMenu(importShipmentId)}
                companyName={shipmentDetails?.supplier.clientCompanyName || ''}
                phoneNumber={shipmentDetails?.supplier.mobile || ''}
                initial={shipmentDetails?.supplier.clientCompanyName?.charAt(0).toUpperCase() || ''}
                onBackClick={handleBackClick}
            />

            <Main open={open}>
                <DetailsPageCard
                    columns={expenseVoucherColumns}
                    title={'Expense Voucher'}
                    buttonTitle={'Create Expense'}
                    handleOpen={handleOpen}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={modal} handleClose={handleClose}>
                    <CreateExpense
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleClose}
                        currencyList={currencyList}
                        expenseLedger={expenseLedger}
                        clientCompanyId={supplierId.current}
                    />
                </CustomModal>
                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateExpense
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                        ExpenseVoucherId={expenseVoucherId}
                        currencyList={currencyList}
                        expenseLedger={expenseLedger}
                    />
                </CustomModal>
                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </Main>
        </div>
    );
}
