import React from 'react';
import { AppBar, Box, Container, IconButton, InputBase, Toolbar, Typography, useMediaQuery, Menu, MenuItem, Link, Avatar, Grid } from '@mui/material';
import { Menu as MenuIcon, Search as SearchIcon } from '@mui/icons-material';
import Logo from '../../assets/images/HRVERSE_logo.svg';
import { Card, CardContent, CardActions, CardHeader, CardMedia } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import BannerSearch from './BannerSearch';


const HelpCenteruse = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <BannerSearch />

            <Container>
                <Typography variant="h1" sx={{ fontWeight: 'bold', color: '#007BFF', marginBottom: 2 }}>
                    Advice and answers from the Exim Team
                </Typography>
                <Grid container spacing={2}>
                    {cardData.map((card, index) => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                            <Card sx={cardStyles}>
                                <Box sx={cardIconContainerStyles}>
                                    <CardMedia
                                        component="img"
                                        image="https://intercom.help/incodocshelp/assets/svg/icon:comms-video/000000"
                                        alt="Icon"
                                        sx={cardIconStyles}
                                    />
                                </Box>
                                <Box sx={cardHeaderStyles}>
                                    <Typography sx={cardTitleStyles}>
                                        {card.title}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {card.content}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pt: 2 }}>
                                    <Avatar src="https://static.intercomassets.com/avatars/878640/square_128/BenS-1499930535.jpg" alt="Ben Thompson" sx={avatarStyles} />
                                    <Typography variant="body2" color="textSecondary">
                                        By Ben and 1 other
                                    </Typography>
                                    <svg width="4" height="4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="m-2">
                                        <path d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8Z"></path>
                                    </svg>
                                    <Typography variant="body2" color="textSecondary">
                                        40 articles
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <br />

            <Container>
                <Box sx={customStyles.container} id="customising-document-templates">
                    {linksData.map((link, index) => (
                        <Link
                            key={index}
                            href={link.href}
                            data-testid="article-link"
                            sx={customStyles.link}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
                                <Typography variant="body1" sx={customStyles.linkText}>
                                    {link.text}
                                </Typography>
                            </Box>
                            <Box sx={customStyles.iconContainer}>
                                <ArrowDropDownIcon sx={customStyles.icon} />
                            </Box>
                        </Link>
                    ))}
                </Box>
            </Container>


        </>
    );
};

export default HelpCenteruse;



const customStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        border: '1px solid #d3d3d3',
        backgroundColor: '#f8f8f8',
        p: 2,
        sm: { p: 3 },
    },
    link: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 2,
        py: 1,
        px: 1,
        textDecoration: 'none',
        transition: 'background-color 250ms ease, color 250ms ease',
        '&:hover': {
            backgroundColor: '#f0f0f0',
            color: '#007bff',
        },
        sm: {
            borderRadius: '8px',
            py: 3,
        },
    },
    linkText: {
        m: 0,
        color: '#333',
        fontWeight: 'regular',
        '&:hover': { color: '#007bff' },
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        p: 0,
    },
    icon: {
        transform: 'rotate(-90deg)',
        color: '#007bff',
        fontSize: '1.25rem',
    },
};

const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    boxShadow: 3,
    maxWidth: "100%",
};

const cardIconContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    mb: 2,
};

const cardIconStyles = {
    height: '36px',
    width: '36px',
    sm: {
        height: '40px',
        width: '40px',
    },
};

const cardHeaderStyles = {
    display: 'flex',
    flexDirection: 'column',
    mb: 1,
};

const cardTitleStyles = {
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: 'text.primary',
};


const cardButtonStyles = {
    display: 'flex',
    flexDirection: 'column',
    mt: 5,
    gap: 2,
};

const avatarStyles = {
    width: 24,
    height: 24,
};

const cardData = [
    {
        title: "How to use IncoDocs - Video Tips & Guides",
        content: "Read and download useful Import / Export information.",
        articles: 40,
    },
];


const linksData = [
    {
        href: '/helpcenterdetail',
        text: 'New Updates: Multiple Page Document Formats',
    },
    {
        href: '#',
        text: 'Landscape and Portrait Document Layouts',
    },
    {
        href: '#',
        text: 'Customising Document Templates',
    },
    {
        href: '#',
        text: 'Exporting and Importing Documents',
    },
    {
        href: '#',
        text: 'Utilizing Digital Solutions for Documents',
    },
    {
        href: '#',
        text: 'Understanding Various Document Formats',
    },
    {
        href: '#',
        text: 'Checklist for Import-Export Documents',
    },
    {
        href: '#',
        text: 'Tracking and Managing Shipments',
    },
    {
        href: '#',
        text: 'Building a Reliable Supply Network',
    },
    {
        href: '#',
        text: 'Staying Updated with Trade Policies',
    },
    {
        href: '#',
        text: 'Leveraging Industry Resources',
    },
    {
        href: '#',
        text: 'Incorporating Feedback for Improvement',
    },
];

