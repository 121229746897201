import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BuildIcon from '@mui/icons-material/Build';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material';

const CustomList = ({ items }) => {
    return (
        <Box sx={{ m: 2 }}>
            <Card variant='outlined' sx={{ width: '100%', maxWidth: 400 }}>
                <CardHeader sx={{ p: 0 }}
                    title={
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', pl: 2, pt: 2 }}>
                            Overview
                        </Typography>
                    }
                />
                <List>
                    {items.map((item, index) => (
                        <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ListItemAvatar>
                                    <Avatar>
                                        {item.icon}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText sx={{ fontWeight: "bold" }} primary={item.title} />
                            </Box>
                            <Box>
                                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                    {item.value.toString()}
                                </Typography>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Card>
        </Box>
    );
};

CustomList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element.isRequired,
            title: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })
    ).isRequired
};

const Overview = () => {
    const items = [
        { icon: <LocalShippingIcon />, title: 'Total Order Containers', value: 5 },
        { icon: <BuildIcon />, title: 'Mfg. in Process Containers', value: 8 },
        { icon: <CheckCircleIcon />, title: 'Ready for Dispatch Containers', value: 6 },
        { icon: <DirectionsBoatIcon />, title: 'In-Transit Containers', value: 10 },
        { icon: <EventAvailableIcon />, title: 'Containers Arriving This Week', value: 4 },
        { icon: <AccessTimeIcon />, title: 'Containers On Time', value: 12 },
        { icon: <ErrorIcon />, title: 'Delayed Containers', value: 2 },
    ];

    return <CustomList items={items} />;
};

export default Overview;
