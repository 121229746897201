import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { genericGet } from "../../src/services/apiServices";
import { useParams } from "react-router-dom";
import SubscriptionPlans from "./Subscription";
import { SUBSCRIPTION_HISTORY } from "../services/apiRoutes";
import { CREATE_ORDER, SAVE_ADDON_PAYMENT_INFO } from "../services/apiRoutes";
import { genericPost } from "../services/authServices";
import DataTable from "../components/common/DataTable"; // Import your DataTable component


const SubscriptionHistory = () => {
  const yearlyBasePrice = 25; // Yearly base price for 1 user in USD
  const yearlyPerUserPrice = 5; // Price per additional user in USD
  const monthlyBasePrice = 3; // Monthly base price for 1 user in USD
  const monthlyPerUserPrice = 1; // Monthly price per additional user in USD

  const { id } = useParams(); // Get the id parameter from the URL
  const [subscriptionHistoryList, setSubscriptionHistoryList] = useState([]);
  const [value, setValue] = useState(0);
  const [latestSubscription, setLatestSubscription] = useState(null); // Store the latest subscription
  const [additionalUsers, setAdditionalUsers] = useState(0);
  const [totalPrice, setTotalPrice] = useState(yearlyBasePrice);
  const [totalPerUserPrice, setTotalPerUserPrice] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState("yearly");

  const handleUserChange = (event) => {
    const users = parseInt(event.target.value, 10) || 1;
    setAdditionalUsers(users);
    updateTotalPrice(subscriptionType, users);
  };

  const updateTotalPrice = (type, users) => {
    const days = calculateRemainingDays(latestSubscription);
    
    if (type === "yearly") {
      // Calculate yearly price per user based on remaining days
      const perDayPrice = yearlyPerUserPrice / 365; // Assuming 365 days in a year
      const proratedPrice = perDayPrice * days; // Prorate for remaining days
      const totalPrice = proratedPrice + (users - 1) * proratedPrice;
      setTotalPerUserPrice(totalPrice.toFixed(2)); // Round to 2 decimal places
      
    } else {
      // Calculate monthly price per user based on remaining days
      const perDayPrice = monthlyPerUserPrice / 30; // Assuming 30 days in a month
      const proratedPrice = perDayPrice * days; // Prorate for remaining days
      const totalPrice = proratedPrice + (users - 1) * proratedPrice;
      setTotalPerUserPrice(totalPrice.toFixed(2)); // Round to 2 decimal places
    }
  };
  

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async () => {
    const isRazorpayLoaded = await loadRazorpayScript();
    console.log("Razorpay loaded:", isRazorpayLoaded);
    if (!isRazorpayLoaded) {
      alert(
        "Failed to load Razorpay SDK. Please check your internet connection."
      );
      return;
    }

    try {
      const response = await genericPost(CREATE_ORDER, { amount: totalPerUserPrice });
      if (response.error) {
        throw new Error(response.error || "Error creating order.");
      }

      const orderData = response.data;
      const options = {
        key: "rzp_test_dD0jhbnTbrfcD3", // Replace with your Razorpay Key ID
        amount: totalPerUserPrice * 100,
        currency: "USD",
        name: "Exim CRM",
        description: `${
          subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1)
        } Subscription Payment`,
        order_id: orderData.id,
        handler: async (paymentResponse) => {
          alert(
            `Payment successful! Payment ID: ${paymentResponse.razorpay_payment_id}`
          );

          const paymentInfo = {
            orderId: orderData.id,
            razorpayPaymentId: paymentResponse.razorpay_payment_id,
            razorpayOrderId: paymentResponse.razorpay_order_id,
            razorpaySignature: paymentResponse.razorpay_signature,
            subscriptionId: subscriptionType === "yearly" ? "1" : "2", // Assuming 1 for yearly and 2 for monthly
            totalPrice: totalPerUserPrice,
            totalUsers: additionalUsers,
            subscriptionPkId: latestSubscription?.id || '',
            addon:1
          };

          const saveResponse = await genericPost(
            SAVE_ADDON_PAYMENT_INFO,
            paymentInfo
          );
          if (saveResponse.status === 201) {
            alert("Payment and subscription information saved successfully!");
          } else {
            alert("Failed to save payment information.");
          }
        },
        prefill: {
          name: "Test User",
          email: "user@example.com",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error creating order:", error);
      alert("Failed to create order. Please try again later.");
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSubscriptionHistoryList = async () => {
    try {
      const response = await genericGet({ url: SUBSCRIPTION_HISTORY });
      let subscriptionHistoryList = response.data.list;
  
      if (subscriptionHistoryList && subscriptionHistoryList.length > 0) {
        // Filter out records where addonValue is equal to 1
        const filteredList = subscriptionHistoryList.filter(
          (item) => item.addonValue != 1
        );
  
        // Check if any filtered records remain
        if (filteredList.length > 0) {
          setSubscriptionHistoryList(filteredList);
  
          // Sort the filtered list by createdAt to find the latest subscription
          const sortedList = filteredList.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
  
          setLatestSubscription(sortedList[0]); // Set the latest subscription
        } else {
          // No valid subscriptions after filtering
          setSubscriptionHistoryList([]);
          setLatestSubscription(null);
        }
        
      } else {
        // No subscriptions found
        setSubscriptionHistoryList([]);
        setLatestSubscription(null);
      }
    } catch (error) {
      console.log("Error fetching subscription history:", error);
    }
  };
  


  useEffect(() => {
    getSubscriptionHistoryList();
  }, [id]);

  // Define the columns for the DataTable
  const columns = useMemo(
    () => [
      {
        accessorKey: "subscriptionId", // Accessor key to get the values
        header: "Subscription ID",
      },
      {
        accessorKey: "totalUsers",
        header: "Total Users",
      },
      {
        accessorKey: "totalPrice",
        header: "Total Price",
      },
      {
        accessorKey: "razorpayPaymentId",
        header: "Razorpay Payment ID",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(), // Format the date
      },
    ],
    []
  );

  // Calculate remaining days logic
  const calculateRemainingDays = (subscription) => {
    const currentDate = new Date();
    const purchaseDate = new Date(subscription.createdAt);
    let remainingDays = 0;

    if (subscription.subscriptionId == 1) {
      // Yearly subscription: 365 days
      const yearDuration = 365;
      const diffInTime = currentDate - purchaseDate;
      const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
      remainingDays = yearDuration - diffInDays;
    } else {
      // Monthly subscription: 30 days
      const monthDuration = 30;
      const diffInTime = currentDate - purchaseDate;
      const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
      remainingDays = monthDuration - diffInDays;
    }

    return remainingDays > 0 ? remainingDays : 0;
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label="Active Subscription"
              {...a11yProps(0)}
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                color: value === 0 ? "#007BFF" : "inherit",
              }}
            />
            <Tab
              label="Buy Subscription"
              {...a11yProps(1)}
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                color: value === 1 ? "#007BFF" : "inherit",
              }}
            />
            <Tab
              label="Subscription Purchase History"
              {...a11yProps(2)}
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                color: value === 2 ? "#007BFF" : "inherit",
              }}
            />
            <Tab
              label="Manage Users"
              {...a11yProps(3)}
              sx={{
                fontWeight: "bold",
                textTransform: "capitalize",
                color: value === 3 ? "#007BFF" : "inherit",
              }}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          {/* Content for Active Subscription */}
          {latestSubscription ? (
            <>
              <Grid
                container
                spacing={4}
                justifyContent="center"
                sx={{ marginTop: 2 }}
              >
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      transition: "0.3s",
                      "&:hover": { boxShadow: 4 },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h2"
                        color="primary"
                        sx={{ fontWeight: "bold", marginBottom: 2 }}
                      >
                        Active Subscription
                      </Typography>

                      <Divider sx={{ marginBottom: 2 }} />

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Subscription Type:</strong>{" "}
                            {latestSubscription.subscriptionId == 1
                              ? "Yearly"
                              : "Monthly"}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Total Users:</strong>{" "}
                            {latestSubscription.totalUsers}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <strong>Total Price:</strong> $
                            {latestSubscription.totalPrice}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <strong>Purchased On:</strong>{" "}
                            {new Date(
                              latestSubscription.createdAt
                            ).toLocaleString()}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">
                            <strong>Razorpay Payment ID:</strong>{" "}
                            {latestSubscription.razorpayPaymentId || "N/A"}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

                      <Button variant="contained" color="primary" fullWidth>
                        Subsription Will Expired in{" "}
                        {calculateRemainingDays(latestSubscription)} days
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          ) : (
            <Typography
              variant="body1"
              sx={{ padding: "20px", textAlign: "center" }}
            >
              No active subscription found.
            </Typography>
          )}
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <SubscriptionPlans />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          {/* Use the DataTable component to display the subscription history */}
          <DataTable
            title="Subscription Purchase History"
            buttonTitle="Export Data"
            columns={columns}
            data={subscriptionHistoryList}
            handleOpen={() => console.log("Handle open clicked")}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={3}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  boxShadow: 3,
                  transition: "0.3s",
                  "&:hover": { boxShadow: 4 },
                }}
              >
                {latestSubscription && (
                    <CardContent>
                    <Typography variant="body1">
                        <strong>Current Plan:</strong>{" "}
                        {latestSubscription.subscriptionId == 1
                        ? "Yearly"
                        : "Monthly"}
                    </Typography>

                    <Grid item xs={6}>
                        <Typography variant="body1">
                        <strong>Total Users:</strong>{" "}
                        {latestSubscription.totalUsers}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                        <strong>Total Paid:</strong> $
                        {latestSubscription.totalPrice}
                        </Typography>
                    </Grid>

                    <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

                    <Typography
                        variant="h4"
                        color="primary"
                        sx={{ fontWeight: "bold", marginBottom: 2 }}
                    >
                        Add Users To Your Exisiting Plan
                    </Typography>
                    <Typography variant="h5" gutterBottom>
                        Per User Price: ${latestSubscription.subscriptionId == 1
                        ? yearlyPerUserPrice
                        : monthlyPerUserPrice}
                    </Typography>


                    {/* Add Additional Users */}
                    <TextField
                        fullWidth
                        label="Additional Users"
                        type="number"
                        variant="outlined"
                        value={additionalUsers}
                        onChange={handleUserChange}
                        inputProps={{ min: 1 }}
                        sx={{ marginY: 2 }}
                    />

                    <Typography variant="h5" gutterBottom>
                        Total Price: ${totalPerUserPrice}
                    </Typography>

                    <Typography variant="h5" gutterBottom color="primary">
                        Note: Price is applied per user with the remaining subscription days
                    </Typography>

                    <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handlePayment}
                    >
                        Add Users
                    </Button>
                    </CardContent>
                )}
              </Card>
            </Grid>
          </Grid>
        </CustomTabPanel>
      </Box>
    </>
  );
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export default SubscriptionHistory;
