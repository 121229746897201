import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Box, Typography, Divider, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from '../common/CustomAutoComplete';
import CustomTextarea from '../common/CustomTextarea';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import { Update_clientCompanyId, GET_clientCompany_Id } from '../../services/apiRoutes';
import { getStates, getCities } from "../../services/CommonServices";


const validationSchema = Yup.object().shape({
    companyAdminId: Yup.number().required('Company Admin ID is required').integer('Company Admin ID must be an integer'),
    ratingId: Yup.number().required('Rating ID is required').integer('Rating ID must be an integer'),
    clientCompanyName: Yup.string().required('Client Company Name is required').max(255, 'Client Company Name cannot exceed 255 characters'),
    phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'phone number must be exactly 10 digits')
        .required('Mobile is required'),
    mobile: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    website: Yup.string().url('Invalid URL').required('Website is required'),
    companyNumber: Yup.string().required('Company Number is required').max(50, 'Company Number cannot exceed 50 characters'),
    companyTypeId: Yup.number().required('Company Type ID is required').integer('Company Type ID must be an integer'),
    ownershipId: Yup.number().required('Ownership ID is required').integer('Ownership ID must be an integer'),
    industryTypeId: Yup.number().required('Industry Type ID is required').integer('Industry Type ID must be an integer'),
    employees: Yup.number().required('Number of Employees is required').integer('Number of Employees must be an integer').positive('Number of Employees must be positive'),
    annualRevenue: Yup.number().required('Annual Revenue is required').positive('Annual Revenue must be positive'),
    businessGroupId: Yup.number().required('Business Group ID is required').integer('Business Group ID must be an integer'),
    billingStreet: Yup.string().required('Billing Street is required').max(255, 'Billing Street cannot exceed 255 characters'),
    billingCountryId: Yup.number().required('Billing Country ID is required').integer('Billing Country ID must be an integer'),
    billingStateId: Yup.number().required('Billing State ID is required').integer('Billing State ID must be an integer'),
    billingCityId: Yup.number().required('Billing City ID is required').integer('Billing City ID must be an integer'),
    billingPincode: Yup.string().required('Billing Pincode is required').matches(/^\d{5}(-\d{4})?$/, 'Billing Pincode must be a valid postal code'),
    mailingStreet: Yup.string().required('Mailing Street is required').max(255, 'Mailing Street cannot exceed 255 characters'),
    mailingCountryId: Yup.number().required('Mailing Country ID is required').integer('Mailing Country ID must be an integer'),
    mailingStateId: Yup.number().required('Mailing State ID is required').integer('Mailing State ID must be an integer'),
    mailingCityId: Yup.number().required('Mailing City ID is required').integer('Mailing City ID must be an integer'),
    mailingPincode: Yup.string().required('Mailing Pincode is required').matches(/^\d{5}(-\d{4})?$/, 'Mailing Pincode must be a valid postal code'),
    description: Yup.string().max(1000, 'Description cannot exceed 1000 characters')
});
// const UpdateClientCompany = ({ handleClose, industryType, rating, leadSource, leadStatus, conutries, getLeadList, setMessage, fetchClientCompanies, clientCompanyId }) => {



const UpdateClientCompany = ({ handleClose,
    owner,
    rating,
    companyTypes,
    countries,
    setMessage,
    fetchClientCompanies,
    companyOwnershipTypes,
    industryType,
    businessGroups,
    clientCompanyId,

}) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);



    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [billingStates, setBillingStates] = useState([]);
    const [billingCities, setBillingCities] = useState([]);

    // Handle country change for Milling
    const handleChangeMillingCountry = async (newValue) => {
        const countryId = newValue ? newValue.id : '';
        if (countryId) {
            const stateData = await getStates({ id: countryId });
            setStates(stateData); // Set Milling states
            setCities([]); // Clear Milling cities when a new country is selected
        } else {
            setStates([]);
            setCities([]);
        }
    };

    // Handle country change for Billing
    const handleChangeBillingCountry = async (newValue) => {
        const countryId = newValue ? newValue.id : '';
        if (countryId) {
            const stateData = await getStates({ id: countryId });
            setBillingStates(stateData); // Set Billing states
            setBillingCities([]); // Clear Billing cities when a new country is selected
        } else {
            setBillingStates([]);
            setBillingCities([]);
        }
    };

    // Handle state change for Milling
    const handleChangeMillingState = async (newValue) => {
        const stateId = newValue ? newValue.id : '';
        if (stateId) {
            const cityData = await getCities({ id: stateId });
            setCities(cityData); // Set Milling cities
        } else {
            setCities([]);
        }
    };

    // Handle state change for Billing
    const handleChangeBillingState = async (newValue) => {
        const stateId = newValue ? newValue.id : '';
        if (stateId) {
            const cityData = await getCities({ id: stateId });
            setBillingCities(cityData); // Set Billing cities
        } else {
            setBillingCities([]);
        }
    };



    useEffect(() => {
        if (!clientCompanyId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(GET_clientCompany_Id(clientCompanyId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.clientCompany);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [clientCompanyId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(Update_clientCompanyId(clientCompanyId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Client Company Update successfully",
                    type: "success",
                });
                fetchClientCompanies()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    companyAdminId: data?.companyAdminId || '',
                    ratingId: data?.ratingId || '',
                    clientCompanyName: data?.clientCompanyName || '',
                    phone: data?.phone || '',
                    mobile: data?.mobile || '',
                    email: data?.email || '',
                    website: data?.website || '',
                    companyNumber: data?.companyNumber || '',
                    companyTypeId: data?.companyTypeId || '',
                    ownershipId: data?.ownershipId || '',
                    employees: data?.employees || '',
                    annualRevenue: data?.annualRevenue || '',
                    businessGroupId: data?.businessGroupId || '',
                    billingStreet: data?.billingStreet || '',
                    billingCountryId: data?.billingCountryId || '',
                    billingStateId: data?.billingStateId || '',
                    billingCityId: data?.billingCityId || '',
                    billingPincode: data?.billingPincode || '',
                    mailingStreet: data?.mailingStreet || '',
                    mailingCountryId: data?.mailingCountryId || '',
                    mailingStateId: data?.mailingStateId || '',
                    mailingCityId: data?.mailingCityId || '',
                    mailingPincode: data?.mailingPincode || '',
                    description: data?.description || '',


                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={6} md={6}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                    Update Client Company
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="companyAdminId"
                                    fieldLabel="Company Admin"
                                    required={true}
                                    options={owner}
                                    getOptionLabel={(option) => option.title}
                                    value={owner.find((option) => option.id === values.companyAdminId) || null}
                                    onChange={(e, newValue) => setFieldValue('companyAdminId', newValue ? newValue.id : '')}
                                    placeholder="Select Company Admin"
                                />
                                {touched.companyAdminId && errors.companyAdminId ? (
                                    <p style={{ color: '#D32F2F', fontSize: '12px' }}>{errors.companyAdminId}</p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="companyTypeId"
                                    fieldLabel="Company Type"
                                    required={true}
                                    options={companyTypes}
                                    getOptionLabel={(option) => option.title}
                                    value={companyTypes.find((option) => option.id === values.companyTypeId) || null}
                                    onChange={(e, newValue) => setFieldValue('companyTypeId', newValue ? newValue.id : '')}
                                    placeholder="Select Company Type"
                                />
                                {touched.companyTypeId && errors.companyTypeId ? (
                                    <p style={{ color: '#D32F2F', fontSize: '12px' }}>{errors.companyTypeId}</p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="ratingId"
                                    fieldLabel="Rating"
                                    required={true}
                                    options={rating}
                                    getOptionLabel={(option) => option.title}
                                    value={rating.find((option) => option.id === values.ratingId) || null}
                                    onChange={(e, newValue) => setFieldValue('ratingId', newValue ? newValue.id : '')}
                                    placeholder="Select Rating"
                                />
                                {touched.ratingId && errors.ratingId ? (
                                    <p style={{ color: '#D32F2F', fontSize: '12px' }}>{errors.ratingId}</p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="clientCompanyName"
                                    fieldLabel="Company Name"
                                    placeholder="Enter Company Name"
                                    required={true}
                                    value={values.clientCompanyName}
                                    onChange={handleChange}
                                    error={touched.clientCompanyName && !!errors.clientCompanyName}
                                    helperText={touched.clientCompanyName && errors.clientCompanyName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="phone"
                                    type="number"
                                    fieldLabel="Phone"
                                    required={true}
                                    placeholder="Enter The Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    error={touched.phone && !!errors.phone}
                                    helperText={touched.phone && errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="mobile"
                                    type="number"
                                    fieldLabel="Mobile"
                                    placeholder="Enter Mobile"
                                    required
                                    value={values.mobile}
                                    onChange={handleChange}
                                    error={touched.mobile && !!errors.mobile}
                                    helperText={touched.mobile && errors.mobile}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="email"
                                    fieldLabel="Email"
                                    placeholder="Enter Email"
                                    required
                                    value={values.email}
                                    onChange={handleChange}
                                    error={touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="website"
                                    fieldLabel="Website"
                                    placeholder="Enter Website"
                                    required
                                    value={values.website}
                                    onChange={handleChange}
                                    error={touched.website && !!errors.website}
                                    helperText={touched.website && errors.website}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="companyNumber"
                                    fieldLabel="Company Number"
                                    placeholder="Enter Company Number"
                                    required
                                    value={values.companyNumber}
                                    onChange={handleChange}
                                    error={touched.companyNumber && !!errors.companyNumber}
                                    helperText={touched.companyNumber && errors.companyNumber}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="ownershipId"
                                    fieldLabel="Ownership"
                                    required={true}
                                    options={companyOwnershipTypes}
                                    getOptionLabel={(option) => option.title}
                                    value={companyOwnershipTypes.find((option) => option.id === values.ownershipId) || null}
                                    onChange={(e, newValue) => setFieldValue('ownershipId', newValue ? newValue.id : '')}
                                    placeholder="Select Ownership"
                                />
                                {touched.ownershipId && errors.ownershipId ? (
                                    <p style={{ color: '#D32F2F', fontSize: '12px' }}>{errors.ownershipId}</p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="industryTypeId"
                                    fieldLabel="Industry Type"
                                    required={true}
                                    options={industryType}
                                    getOptionLabel={(option) => option.title}
                                    value={industryType.find((option) => option.id === values.industryTypeId) || null}
                                    onChange={(e, newValue) => setFieldValue('industryTypeId', newValue ? newValue.id : '')}
                                    placeholder="Select Industry Type"
                                />
                                {touched.industryTypeId && errors.industryTypeId ? (
                                    <p style={{ color: '#D32F2F', fontSize: '12px' }}>{errors.industryTypeId}</p>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="employees"
                                    fieldLabel="Number of Employees"
                                    placeholder="Enter Number of Employees"
                                    required
                                    type="number"
                                    value={values.employees}
                                    onChange={handleChange}
                                    error={touched.employees && !!errors.employees}
                                    helperText={touched.employees && errors.employees}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="annualRevenue"
                                    fieldLabel="Annual Revenue"
                                    placeholder="Enter Annual Revenue"
                                    required
                                    type="number"
                                    value={values.annualRevenue}
                                    onChange={handleChange}
                                    error={touched.annualRevenue && !!errors.annualRevenue}
                                    helperText={touched.annualRevenue && errors.annualRevenue}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="businessGroupId"
                                    fieldLabel="Business Group"
                                    required={true}
                                    options={businessGroups}
                                    getOptionLabel={(option) => option.title}
                                    value={businessGroups.find((option) => option.id === values.businessGroupId) || null}
                                    onChange={(e, newValue) => setFieldValue('businessGroupId', newValue ? newValue.id : '')}
                                    placeholder="Select Business Group"
                                />
                                {touched.businessGroupId && errors.businessGroupId ? (
                                    <p style={{ color: '#D32F2F', fontSize: '12px' }}>{errors.businessGroupId}</p>
                                ) : null}
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
                                        <Grid item xs={12} md={8}>
                                            <Typography
                                                style={{
                                                    color: "#212529",
                                                    fontWeight: 500,
                                                    fontSize: "20px",
                                                }}
                                            >
                                                Address Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <CustomAutocomplete
                                                options={top100Films}
                                                required={false}
                                                getOptionLabel={(option) => option.title}
                                                placeholder="Copy Address"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        name="mailingStreet"
                                        type="text"
                                        fieldLabel="Mailing Street"
                                        placeholder="Enter Mailing Street"
                                        value={values.mailingStreet}
                                        onChange={handleChange}
                                        error={touched.mailingStreet && !!errors.mailingStreet}
                                        helperText={touched.mailingStreet && errors.mailingStreet}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        name="billingStreet"
                                        type="text"
                                        fieldLabel="Billing Street"
                                        placeholder="Enter Billing Street"
                                        value={values.billingStreet}
                                        onChange={handleChange}
                                        error={touched.billingStreet && !!errors.billingStreet}
                                        helperText={touched.billingStreet && errors.billingStreet}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomAutocomplete
                                        options={countries}
                                        fieldLabel="Milling Country"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={countries.find((option) => option.id === values.mailingCountryId) || null}
                                        onChange={(e, newValue) => {
                                            setFieldValue('mailingCountryId', newValue ? newValue.id : '');
                                            handleChangeMillingCountry(newValue);
                                        }}
                                        placeholder="Select Milling Country"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CustomAutocomplete
                                        options={countries}
                                        fieldLabel="Billing Country"
                                        required={false}
                                        getOptionLabel={(option) => option.title}
                                        value={countries.find((option) => option.id === values.billingCountryId) || null}
                                        onChange={(e, newValue) => {
                                            setFieldValue('billingCountryId', newValue ? newValue.id : '');
                                            handleChangeBillingCountry(newValue);
                                        }}
                                        placeholder="Select Billing Country"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CustomAutocomplete
                                        options={states}
                                        fieldLabel="Milling State"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={states.find((option) => option.id === values.mailingStateId) || null}
                                        onChange={(e, newValue) => {
                                            setFieldValue('mailingStateId', newValue ? newValue.id : '');
                                            handleChangeMillingState(newValue);
                                        }}
                                        placeholder="Select Milling State"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CustomAutocomplete
                                        options={billingStates}
                                        fieldLabel="Billing State"
                                        required={false}
                                        getOptionLabel={(option) => option.title}
                                        value={billingStates.find((option) => option.id === values.billingStateId) || null}
                                        onChange={(e, newValue) => {
                                            setFieldValue('billingStateId', newValue ? newValue.id : '');
                                            handleChangeBillingState(newValue);
                                        }}
                                        placeholder="Select Billing State"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CustomAutocomplete
                                        options={cities}
                                        fieldLabel="Milling City"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={cities.find((option) => option.id === values.mailingCityId) || null}
                                        onChange={(e, newValue) => setFieldValue('mailingCityId', newValue ? newValue.id : '')}
                                        placeholder="Select Milling City"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <CustomAutocomplete
                                        options={billingCities}
                                        fieldLabel="Billing City"
                                        required={false}
                                        getOptionLabel={(option) => option.title}
                                        value={billingCities.find((option) => option.id === values.billingCityId) || null}
                                        onChange={(e, newValue) => setFieldValue('billingCityId', newValue ? newValue.id : '')}
                                        placeholder="Select Billing City"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        name="mailingPincode"
                                        fieldLabel="Mailing Pincode"
                                        required={true}
                                        type="number"
                                        placeholder="Enter Mailing Pincode"
                                        value={values.mailingPincode}
                                        onChange={handleChange}
                                        error={touched.mailingPincode && !!errors.mailingPincode}
                                        helperText={touched.mailingPincode && errors.mailingPincode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        name="billingPincode"
                                        fieldLabel="Billing Pincode"
                                        required={true}
                                        type="number"
                                        placeholder="Enter Billing Pincode"
                                        value={values.billingPincode}
                                        onChange={handleChange}
                                        error={touched.billingPincode && !!errors.billingPincode}
                                        helperText={touched.billingPincode && errors.billingPincode}
                                    />
                                </Grid>

                                <Grid item xs={8}>
                                    <CustomTextarea
                                        name="description"
                                        fieldLabel="Description"
                                        required={true}
                                        value={values.description}
                                        onChange={handleChange}
                                        placeholder="Write a description"
                                    />
                                    {touched.description && errors.description ? (
                                        <p style={{ color: "red", fontSize: "12px" }}>
                                            {errors.description}
                                        </p>
                                    ) : null}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateClientCompany;

const top100Films = [
    { title: 'Mailing ', id: 1 },
    { title: 'Billing ', id: 2 },
];