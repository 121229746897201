// ImportConstant.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailPageCustum from '../../common/DetailsPage/DetailPageCustum';
import { READ_IMPORT_SHIPMENT } from '../../../services/apiRoutes';

const ImportDetail = () => {
    const { importShipmentId } = useParams();
    const [shipmentDetails, setShipmentDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(READ_IMPORT_SHIPMENT(importShipmentId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setShipmentDetails(response.data.shipment);
            })
            .catch(error => {
                console.error('Error fetching shipment details:', error);
            });
    }, [importShipmentId]);

    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }

    const tableData = [
        { label: 'Import Shipment ID', value: shipmentDetails.importShipmentId || 'N/A' },
        { label: 'User ID', value: shipmentDetails.userId || 'N/A' },
        { label: 'User Name', value: shipmentDetails.user?.firstName && shipmentDetails.user?.lastName ? `${shipmentDetails.user.firstName} ${shipmentDetails.user.lastName}` : 'N/A' },
        { label: 'Company Name', value: shipmentDetails.company?.companyName || 'N/A' },
        { label: 'Shipment ID', value: shipmentDetails.shipmentId || 'N/A' },
        { label: 'Supplier ID', value: shipmentDetails.supplierId || 'N/A' },
        { label: 'CIF/FOB Name', value: shipmentDetails.cifFob?.cifFobName || 'N/A' },
        { label: 'Expected ETD', value: shipmentDetails.expectedEtd || 'N/A' },
        { label: 'No. of Containers', value: shipmentDetails.noOfContainers || 'N/A' },
        { label: 'Currency ID', value: shipmentDetails.currencyId || 'N/A' },
        { label: 'Shipment Type', value: shipmentDetails.shipmentType?.shipmentTypeName || 'N/A' },
        { label: 'Note', value: shipmentDetails.note || 'N/A' },
        { label: 'Created At', value: shipmentDetails.createdAt ? new Date(shipmentDetails.createdAt).toLocaleString() : 'N/A' },
        { label: 'Updated At', value: shipmentDetails.updatedAt ? new Date(shipmentDetails.updatedAt).toLocaleString() : 'N/A' }
    ];

    return <DetailPageCustum title="Import Shipment Details" data={tableData} />;
};

export default ImportDetail;
