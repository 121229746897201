import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Card, Container, Divider, Grid } from '@mui/material';
import CustomButton from "../common/CustomButton";
import { SaveRounded } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from "../common/CustomAutoComplete";


export default function GetStartedForFree() {
    const [value, setValue] = React.useState('login');
    const [formData, setFormData] = React.useState({
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <Grid container spacing={2} >
                <Grid item xs={12} sm={6} md={7}>
                    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
                        <Container>

                            <Typography variant="h6" component="div" sx={{ fontWeight: '700', mb: 1.5, fontSize: "20px", color: "#007bff" }}>
                                All-In-One Export Documentation Software
                            </Typography>
                            <Typography variant="h2" component="div" sx={{ fontWeight: '700', fontSize: "40px", }}>
                                Export documentation.
                                <Typography style={{ color: '#007bff', fontWeight: '700', mb: 1.5, fontSize: "40px", }}>Easy. Error-free. Everytime.</Typography>
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2.5, fontSize: "20px", fontWeight: "100", }}>
                                Go paperless, eliminate errors, and save hours of tedious work per shipment with your sales, shipments, and export documentation{' '}
                                <span style={{ fontWeight: 'bold' }}> - all in one place.</span>
                            </Typography>
                            <CustomButton
                                variant="contained"
                                color="secondary"
                                title="Get Start For Free"
                            />

                        </Container>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={5}>
                    <Card sx={{ p: 4, color: "#404040" }}>
                        <Typography variant="h6" sx={{ fontSize: "25px", fontWeight: "500", textAlign: "center", pb: 2 }}>
                            Book Live Demo
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    type='text'
                                    name="firstName"
                                    required={true}
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                    placeholder="Enter First Name"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomTextField
                                    type='text'
                                    name="lastName"
                                    required={false}
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                    placeholder="Enter Last Name"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    type='text'
                                    name="company"
                                    required={true}
                                    value={formData.company}
                                    onChange={handleInputChange}
                                    placeholder="Enter Company"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.countries}
                                    onChange={(e, newValue) =>
                                        handleInputChange({ target: { name: "countries", value: newValue } })
                                    }
                                    placeholder="Select Country"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    type='email'
                                    name="email"
                                    required={true}
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Enter Email"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextField
                                    type='number'
                                    name="contactNumber"
                                    required={true}
                                    value={formData.contactNumber}
                                    onChange={handleInputChange}
                                    placeholder="Enter Contact Number"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ justifyContent: "center", display: "flex" }}>
                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    title="Get Start For Free"
                                />
                            </Grid>
                        </Grid>


                    </Card>
                </Grid>
            </Grid >
        </>
    );
}


const top100Films = [
    { title: "United States" },
    { title: "Canada" },
    { title: "United Kingdom" },
    { title: "Germany" },
    { title: "France" },
    { title: "Australia" },
    { title: "Japan" },
    { title: "China" },
    { title: "India" },
    { title: "Brazil" },
];