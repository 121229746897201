import React from 'react'
import {Button} from '@mui/material'
const CustomButton = ({
    startIcon,
    title,
    ...props

}) => {
  return (
    <Button
    type="submit"
    color="primary"
    startIcon={startIcon}
    style={style}
    {...props}
  >
    {title}
  </Button>
  )
}

export default CustomButton

const style = {
    backgroundColor: "#007bff",
    color: "#ffffff",
    textTransform: "capitalize",
    borderRadius: 4,
    border: "2px solid #007bff",
    height: 30,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 5px",

  }