import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

// Styles for the components
const styles = {
    table: {
        minWidth: 650,
        borderCollapse: 'collapse',
        marginBottom: 0,
    },
    textMuted: {
        color: '#6c757d',
    },
    tableCell: {
        width: '150px',
        color: '#6c757d',
        border: 'none',
        borderBottom: '0',
    },
    tableCellMiddle: {
        width: '10px',
        color: '#6c757d',
        border: 'none',
        borderBottom: '0',
    },
    tableCellLast: {
        border: 'none',
        borderBottom: '0',
    },
    tableRow: {
        height: '20px',
    },
    cardTitle: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    expandIcon: {
        color: '#212529',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: "16px",
        color: "#212529",
        fontWeight: "600"
    },
    customTableRow: {
        height: '20px',
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
};

// AccordionUsage component with data passed as props
const AccordionUsage = ({ accordionData }) => {
    return (
        <div>
            {accordionData.map((item, index) => (
                <Accordion key={index} defaultExpanded={item.defaultExpanded}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={styles.expandIcon} />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                        style={styles.title}
                    >
                        {item.title}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Divider />
                        <CardContent style={styles.cardTitle}>
                            <Table style={styles.table} size="small" aria-label="client company details">
                                <TableBody>
                                    {item.details.map((detail, idx) => (
                                        <TableRow key={idx} style={styles.customTableRow}>
                                            <TableCell style={styles.tableCell}>{detail.label}</TableCell>
                                            <TableCell style={styles.tableCellMiddle}>-</TableCell>
                                            <TableCell style={styles.tableCellLast}>{detail.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

// Example data
const exampleData = [
    {
        title: 'Show Details',
        defaultExpanded: false,
        details: [
            { label: 'Company Name', value: 'VARAD AGRO INDUSTRIES' },
            { label: 'Email', value: 'Varadagroindustries171@gmail.com' },
            { label: 'Mobile', value: '+91 9762800171' },
        ],
    },
];

// App component rendering the AccordionUsage component
export default function App() {
    return <AccordionUsage accordionData={exampleData} />;
}
