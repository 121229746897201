import React, { useState } from 'react';
import axios from 'axios';
import { Button, Card, CardActions, CardContent, Container, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Divider, styled } from '@mui/material';
import CustomButton from '../common/CustomButton';
import { Save } from '@mui/icons-material';
import CustomTextField from '../common/CustomTextField';

const SystemCompany = () => {
    const [formData, setFormData] = useState({
        common_sales_executive: '1',
        common_branch: '0',
        cr_dr_setting: '1',
        common_rate: '1',
        common_sales_invnum: '1',
        common_purchase_invnum: '0',
        common_price_level: '1',
        discount: '0',
        add_ledger: '1',
        product_sms_alert: '0',
        product_email_alert: '1',
        product_com: '1',
        bar_code: '0',
        start_current_period: '01/04/2023',
        end_current_period: '31/03/2024',
        book_date: '01/04/2023',
        common_financialyr: '01/04/2023',
        common_deci_format: '0',
        common_signout: ''
    });

    const initialStates = {
        common_sales_executive: true,
        common_branch: true,
        cr_dr_setting: true,
        common_rate: false,
        common_sales_invnum: false,
        common_purchase_invnum: false,
        common_price_level: true,
        discount: false,
        add_ledger: true,
        product_sms_alert: false,
        product_email_alert: true,
        product_com: false,
        bar_code: true
    };

    const [toggleStates, setToggleStates] = useState(initialStates);

    const handleToggleClick = (name) => {
        setToggleStates(prevStates => ({
            ...prevStates,
            [name]: !prevStates[name]
        }));
    };

    const buttonStyle = {
        color: '#ffff',
        padding: '5px 10px',
        border: 'none',
        cursor: 'pointer'
    };

    const onButtonStyle = (isOn) => ({
        ...buttonStyle,
        backgroundColor: isOn ? 'green' : 'red',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
    });

    const offButtonStyle = (isOn) => ({
        ...buttonStyle,
        backgroundColor: isOn ? 'red' : 'green',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
    });

    const handleRadioChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleDateChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };



    const DottedDivider = styled(Grid)(({ theme }) => ({
        borderBottom: '1px dashed #cccccc',
    }));

    const styles = {
        heading: {
            marginBottom: '1px',
            fontSize: '14px'
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1px'
        },
        details: {
            flex: 1,
            marginRight: '1px'
        }
    };

    return (
        <Container>
            <form encType="multipart/form-data">
                <div>
                    <Grid container spacing={2}>
                        {/* Sales Executive */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Sales Executive</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Maintain Sales Executive-wise details</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_sales_executive')}
                                            style={onButtonStyle(toggleStates.common_sales_executive)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_sales_executive')}
                                            style={offButtonStyle(toggleStates.common_sales_executive)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        {/* Branch */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Branch</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Maintain multiple Branch-wise record</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_branch')}
                                            style={onButtonStyle(toggleStates.common_branch)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_branch')}
                                            style={offButtonStyle(toggleStates.common_branch)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <DottedDivider />
                        </Grid>

                        {/* Cr / Dr */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Cr / Dr</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Use Cr / Dr instead of From / To during entry</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('cr_dr_setting')}
                                            style={onButtonStyle(toggleStates.cr_dr_setting)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('cr_dr_setting')}
                                            style={offButtonStyle(toggleStates.cr_dr_setting)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        {/* Price Edit */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Price Edit</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Allow to edit price field in any voucher</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_rate')}
                                            style={onButtonStyle(toggleStates.common_rate)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_rate')}
                                            style={offButtonStyle(toggleStates.common_rate)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <DottedDivider />
                        </Grid>

                        {/* Sales Invoice Numbering */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Sales Invoice Numbering</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Allow Manual Sales Invoice Number</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_sales_invnum')}
                                            style={onButtonStyle(toggleStates.common_sales_invnum)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_sales_invnum')}
                                            style={offButtonStyle(toggleStates.common_sales_invnum)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        {/* Purchase Invoice Numbering */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Purchase Invoice Numbering</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Allow Manual Purchase Invoice Number</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_purchase_invnum')}
                                            style={onButtonStyle(toggleStates.common_purchase_invnum)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_purchase_invnum')}
                                            style={offButtonStyle(toggleStates.common_purchase_invnum)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <DottedDivider />
                        </Grid>

                        {/* Price Level */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Price Level</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Maintain Price Level</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_price_level')}
                                            style={onButtonStyle(toggleStates.common_price_level)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('common_price_level')}
                                            style={offButtonStyle(toggleStates.common_price_level)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        {/* Discount */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Discount</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Enable Multi-discount for item in Sales, Order/Challan & Purchase</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('discount')}
                                            style={onButtonStyle(toggleStates.discount)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('discount')}
                                            style={offButtonStyle(toggleStates.discount)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <DottedDivider />
                        </Grid>

                        {/* Add Ledger */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Add Ledger</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Add new Ledger account while entering voucher</div>
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('add_ledger')}
                                            style={onButtonStyle(toggleStates.add_ledger)}
                                        >
                                            ON
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleToggleClick('add_ledger')}
                                            style={offButtonStyle(toggleStates.add_ledger)}
                                        >
                                            OFF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Grid>



                        {/* Start Current Period */}
                        <Grid item xs={12} sm={6}>
                            <div>
                                <h3 style={styles.heading}>Start Current Period</h3>
                                <div style={styles.container}>
                                    <div style={styles.details}>Enter Start Date of Current Financial Year</div>
                                    <div>
                                        <CustomTextField
                                            type='date'
                                            name="lastName"
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <DottedDivider />
                        </Grid>

                    </Grid>
                    <CardActions>
                        <CustomButton
                            startIcon={<Save />}
                            title="Save"
                            size="small"
                            variant="contained"
                        />
                    </CardActions>
                </div>
            </form>
        </Container>
    );
};

export default SystemCompany;
