import React, { useContext, useState } from "react";
import { Box, CircularProgress, Grid, FormLabel } from "@mui/material";
import { Card, Typography, Divider } from "@mui/material";
import CustomButton from "../common/CustomButton";
import {
  ClearRounded,
  SaveRounded,
  Replay10Rounded,
} from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from "../common/CustomAutoComplete";
import CustomTextarea from "../common/CustomTextarea";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import { Creact_COMPANIES } from '../../services/apiRoutes';
import { genericPost } from "../../services/apiServices";
import { CreateMyCompany } from "../../services/apiRoutes";
import MessageBox from "../common/CustomMessageBox";
import { getStates, getCities } from "../../services/CommonServices";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../../context/AuthContext";
const stateOptions = [
  { id: 1, title: "California" },
  { id: 2, title: "Texas" },
  // Add more options as needed
];

const cityOptions = [
  { id: 1, title: "Los Angeles" },
  { id: 2, title: "San Francisco" },
  // Add more options as needed
];

const CreateCompany = ({
  handleClose,
  stateOptions,
  cityOptions,
  countries,
  firstTimeLogin,
  handleSetDefault
}) => {
  const [message, setMessage] = useState({
    open: false,
    text: "",
    type: "",
  });
  const [states, setStates] = useState([]);
  const [cities, setConutries] = useState([]);
const {token} = useContext(AuthContext)
  const [formData, setFormData] = useState({
    companyName: "",
    mobile: "",
    email: "",
    website: "",
    companyUrl: "",
    defaultCompany: false,
    telephone: "",
    fax: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    countryId: null,
    stateId: null,
    cityId: null,
    talukaId: "",
    pincode: "",
    gstin: "",
    fiscalYearBeginMonth: "",
    prefix: "",
    companyLogo: "",
    companyFooter: "",
    companySignStamp: "",
  });
console.log(countries)
  const companyValidationSchema = Yup.object({
    companyName: Yup.string().required("Company Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    website: Yup.string()
      .url("Invalid URL format")
      .required("Website is required"),
    companyUrl: Yup.string().url("Invalid URL format"),
    defaultCompany: Yup.boolean(),
    telephone: Yup.string(),
    fax: Yup.string(),
    address: Yup.string().required("Address is required"),
    addressLineOne: Yup.string().required("Address Line One is required"),
    addressLineTwo: Yup.string(),
    countryId: Yup.number().required("Country is required"),
    stateId: Yup.number().required("State is required"),
    cityId: Yup.number().required("City is required"),
    talukaId: Yup.string(),
    pincode: Yup.string().required("Pincode is required"),
    gstin: Yup.string(),
    fiscalYearBeginMonth: Yup.string(),
    prefix: Yup.string(),
    companyLogo: Yup.string(),
    companyFooter: Yup.string(),
    companySignStamp: Yup.string(),
  });
console.log(countries)
  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();

    // Append form      values to the FormData object
    Object.keys(values).forEach((key) => {
      if (values[key] instanceof File) {
        // Handle file uploads
        formData.append(key, values[key]);
      } else {
        // Handle other form fields
        formData.append(key, values[key]);
      }
    });
    try {
      const response = await genericPost(CreateMyCompany, values,token);
      if (response.status === 201) {
        console.log(response.data.data)
        if(firstTimeLogin){
          handleSetDefault(response.data.newCompany.companyId,token)
        }
        setMessage({
          open: true,
          text: response.message || "Company created successfully",
          type: "success",
        });
      } else {
        setMessage({
          open: true,
          text: response.error || "Something went wrong",
          type: "error",
        });
      }
    } catch (error) {
      setMessage({
        open: true,
        text: "Something went wrong",
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };
  const handleChangeCountry = async (newValue) => {
    let id = newValue?.id;
    const stateData = await getStates({ id });
    setStates(stateData);
  };
  const handleChangeState = async (newValue) => {
    let id = newValue.id;
    const stateData = await getCities({ id });
    setConutries(stateData);
  };

  const convertFileToBase64 = (file, callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      callback(base64String);
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
    };

    reader.readAsDataURL(file);
  };
  return (
    <>
      <Formik
        initialValues={formData}
        validationSchema={companyValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} style={{ position: "sticky" }}>
              <Grid item xs={4} md={2}>
                <Typography
                  sx={{ fontWeight: 600 }}
                  style={{ color: "#212529", fontSize: "18px" }}
                >
                  Create Company
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                md={10}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box style={{ display: "flex" }}>
                  <CustomButton
                    startIcon={<SaveRounded />}
                    title={
                      isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Save"
                      )
                    }
                    size="small"
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  />
                  <CustomButton
                    startIcon={<SaveRounded />}
                    title="Save and New"
                    size="small"
                    variant="contained"
                  />
                  <CustomButton
                    startIcon={<Replay10Rounded />}
                    title="Convert To Quotation"
                    size="small"
                    variant="contained"
                  />
                  <CustomButton
                    startIcon={<ClearRounded />}
                    title="Cancel"
                    size="small"
                    variant="contained"
                    onClick={handleClose}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              spacing={1}
              style={{ paddingLeft: "5%", paddingRight: "5%" }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="companyName"
                  fieldLabel="Company Name"
                  value={values.companyName}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Company Name"
                />
                {touched.companyName && errors.companyName ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.companyName}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="mobile"
                  fieldLabel="Mobile"
                  value={values.mobile}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Mobile"
                />
                {touched.mobile && errors.mobile ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.mobile}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="email"
                  type="email"
                  fieldLabel="Email"
                  value={values.email}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Email"
                />
                {touched.email && errors.email ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.email}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="website"
                  type="url"
                  fieldLabel="Website"
                  value={values.website}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Website"
                />
                {touched.website && errors.website ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.website}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="companyUrl"
                  type="url"
                  fieldLabel="Company URL"
                  value={values.companyUrl}
                  onChange={handleChange}
                  placeholder="Enter Company URL"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="telephone"
                  fieldLabel="Telephone"
                  value={values.telephone}
                  onChange={handleChange}
                  placeholder="Enter Telephone"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="fax"
                  fieldLabel="Fax"
                  value={values.fax}
                  onChange={handleChange}
                  placeholder="Enter Fax"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="address"
                  fieldLabel="Address"
                  value={values.address}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Address"
                />
                {touched.address && errors.address ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.address}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="addressLineOne"
                  fieldLabel="Address Line One"
                  value={values.addressLineOne}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Address Line One"
                />
                {touched.addressLineOne && errors.addressLineOne ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.addressLineOne}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="addressLineTwo"
                  fieldLabel="Address Line Two"
                  value={values.addressLineTwo}
                  onChange={handleChange}
                  placeholder="Enter Address Line Two"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomAutocomplete
                  options={countries}
                  fieldLabel="Country"
                  required={true}
                  getOptionLabel={(option) => option?.title}
                  // value={values.state}
                  onChange={(e, newValue) => {
                    handleChangeCountry(newValue);
                    handleChange({
                      target: { name: "countryId", value: newValue.id },
                    });
                  }}
                  placeholder="Select Country"
                />
                {touched.countryId && errors.countryId ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.countryId}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomAutocomplete
                  options={states}
                  fieldLabel="State"
                  required={true}
                  getOptionLabel={(option) => option.title}
                  // value={values.state}
                  onChange={(e, newValue) => {
                    handleChangeState(newValue);

                    handleChange({
                      target: { name: "stateId", value: newValue.id },
                    });
                  }}
                  placeholder="Select State"
                />
                {touched.stateId && errors.stateId ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.stateId}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomAutocomplete
                  options={cities}
                  fieldLabel="City"
                  required={true}
                  getOptionLabel={(option) => option.title}
                  // value={values.state}
                  onChange={(e, newValue) =>
                    handleChange({
                      target: { name: "cityId", value: newValue.id },
                    })
                  }
                  placeholder="Select City"
                />
                {touched.city && errors.city ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.city}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="pincode"
                  fieldLabel="Pincode"
                  value={values.pincode}
                  onChange={handleChange}
                  required={true}
                  placeholder="Enter Pincode"
                />
                {touched.pincode && errors.pincode ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errors.pincode}
                  </p>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="gstin"
                  fieldLabel="GSTIN"
                  value={values.gstin}
                  onChange={handleChange}
                  placeholder="Enter GSTIN"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="fiscalYearBeginMonth"
                  fieldLabel="Fiscal Year Begin Month"
                  value={values.fiscalYearBeginMonth}
                  onChange={handleChange}
                  placeholder="Enter Fiscal Year Begin Month"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CustomTextField
                  name="prefix"
                  fieldLabel="Prefix"
                  value={values.prefix}
                  onChange={handleChange}
                  placeholder="Enter Prefix"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <FormLabel style={fieldLabelstyle}>
                    {"Company Logo"}
                  </FormLabel>
                  <input
                    type="file"
                    name="companyLogo"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setFieldValue("companyLogo", file);

                      convertFileToBase64(file, (base64String) => {});
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <FormLabel style={fieldLabelstyle}>
                    {"Company Footer"}
                  </FormLabel>
                  <input
                    type="file"
                    name="companyFooter"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setFieldValue("companyFooter", file);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box>
                  <FormLabel style={fieldLabelstyle}>
                    {"Company Sign Stamp"}
                  </FormLabel>
                  <input
                    type="file"
                    name="companySignStamp"
                    onChange={(e) => {
                      let file = e.target.files[0];
                      setFieldValue("companySignStamp", file);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            {message.open && (
              <MessageBox
                open={message.open}
                text={message.text}
                type={message.type}
                handleClose={() => setMessage({ ...message, open: false })}
              />
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreateCompany;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const fieldLabelstyle = {
  color: "#212529",
  fontWeight: "600",
  fontSize: "14px",
};