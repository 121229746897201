import { useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  MRT_GlobalFilterTextField,
  MRT_FilterOptionMenu,
  MRT_ToggleFiltersButton,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { mkConfig, generateCsv, asBlob } from "export-to-csv";
import { Box, Button, IconButton, Typography, Card } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { AddCircle, Backup, AddCircleOutline } from "@mui/icons-material";
import CustomButton from "./CustomButton";

// Sample data

const DataTable = ({ title, buttonTitle, columns, data, handleOpen }) => {
  // CSV configuration
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    const blob = asBlob(csvConfig)(csv);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "test.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  // Initialize table
  const table = useMaterialReactTable({
    columns,
    data: data, // This will already be filtered on the server
    positionGlobalFilter: 'none',
    enableStickyHeader: true,
    muiTableHeadCellProps: {
      sx: {
        fontSize: '14px',
        background: '#e1dddd69',

      },
    },
    muiTableBodyProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontWeight: 'normal',
        fontSize: '14px',
      },
    },
    //move the search box to the left of the top toolbar
    renderTopToolbarCustomActions: () => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          justifyContent: "space-between",
          padding: "8px",
          flexWrap: "wrap",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
      </Box>
    ),
    renderToolbarInternalActions: ({ table }) => {
      return (
        <>
          <MRT_GlobalFilterTextField table={table} style={style} />
          <MRT_ToggleGlobalFilterButton table={table} sx={{ fontSize: '25px' }} />
          <MRT_ShowHideColumnsButton table={table} sx={{ fontSize: '25px' }} />
          <MRT_ToggleFiltersButton table={table} sx={{ fontSize: '25px' }} />
          <MRT_ToggleFullScreenButton table={table} sx={{ fontSize: '25px' }} />
          <IconButton
            onClick={() => {
              window.print();
            }}
          >
            <PrintIcon sx={{ fontSize: '25px' }} />
          </IconButton>
          <IconButton onClick={handleExportData}>
            <FileDownloadIcon sx={{ fontSize: '25px' }} />
          </IconButton>
          <CustomButton
            startIcon={<Backup />}
            title={buttonTitle}
            size="small"
            variant="contained"
            onClick={handleOpen}
            sx={{ minWidth: 'auto', whiteSpace: 'nowrap' }}

          />

        </>
      );
    },
    muiTablePaperProps: {
      elevation: 0
    }
  });
  return (
    <>
      <MaterialReactTable table={table} columnFilterDisplayMode="popover" />
    </>
  );
};

export default DataTable;

const style = {
  width: 200,
  // border: '1px solid #CED4DA',
  borderRadius: '4px',
  // paddingLeft: '10px',
  height: '35px',
  marginTop: '4px',
  fontFamily: 'Poppins, sans-serif !important',
  color: '#495057',
  fontSize: '14px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    height: '35px',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
  },
  '& .MuiInputBase-input': {
    padding: '0 0',
  },
};
