import React, { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { Typography, Divider } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import {
    ClearRounded,
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";

export default function CustomizedDialogs() {
    const [formData, setFormData] = useState({
        leadName: "",
        leadOwner: null,
        leadSource: null,
        contactName: null,
        clientCompany: "",
        leadStatus: "",
        stage: "",
        priority: "",
        lossReason: "",
        productType: "",
        expectedTimeline: "",
        productRadio: "0",
        leadDescription: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAutocompleteChange = (event, newValue, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: newValue,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
        // You can handle form submission logic here
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                            Attatch Document
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box style={{ display: "flex" }}>
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save and New"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<ClearRounded />}
                                title="Cancel"
                                size="small"
                                variant="contained"
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomAutocomplete
                            options={top100Films}
                            fieldLabel="Document Name"
                            required={true}
                            getOptionLabel={(option) => option.title}
                            value={formData.leadSource}
                            onChange={(e, newValue) =>
                                handleAutocompleteChange(e, newValue, "leadSource")
                            }
                            placeholder="Select Document Name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomTextField
                            type="file"
                            fieldLabel="Upload Document"
                            required={true}
                            placeholder="Upload Document"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextarea
                            name="leadDescription"
                            type="text"
                            fieldLabel="Description"
                            onChange={handleChange}
                            value={formData.leadDescription}
                            placeholder="Write a Description"
                            sx={{ height: '100px !important' }}
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

const top100Films = [
    { title: "Sales Contract" },
    { title: "Packing List" },
    { title: "COA" },
    { title: "COO" },
    { title: "BL" },
    { title: "BOE" }
];
