import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FactoryIcon from '@mui/icons-material/Factory';

const ContentWrapper = styled('section')(({ theme }) => ({
    padding: theme.spacing(0),
}));

const CustomCard = styled(Card)(({ theme }) => ({
    height: '100%',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[1],
    },
}));

const CardBody = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
}));

const CustomLink = styled(RouterLink)(({ theme }) => ({
    display: 'flex',
    fontSize: "12px",
    fontWeight: 600,
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    transition: 'color 0.2s',
    '&:hover': {
        color: '#0652DD',
        textDecoration: 'underline',
    },
}));


const cards = [
    {
        title: 'Import Shipment',
        links: [
            { to: '/app/importshipmentreport', icon: <InsertDriveFileIcon />, text: 'Import Shipment Report' },
            { to: '/app/freedaysreport', icon: <InsertDriveFileIcon />, text: 'Free Days Report' },
            { to: '/app/batchnowiseimportshipmentreport', icon: <InsertDriveFileIcon />, text: 'Batch no. wise Imp. shipment Report' },

        ],
    },
    {
        title: 'Export Shipment',
        links: [
            { to: '/app/proforminvreport', icon: <InsertDriveFileIcon />, text: 'Proform Inv. Report' },
            { to: '/app/salescontractreport', icon: <InsertDriveFileIcon />, text: 'Sales Contract Report' },
            { to: '/app/commercialinvoiceregister', icon: <InsertDriveFileIcon />, text: 'Commercial Invoice Register' },
            { to: '/app/batchnowiseexportshipmentreport', icon: <InsertDriveFileIcon />, text: 'Batch no. wise Exp. shipment Report' },

        ],
    },
    {
        title: 'Ledger',
        links: [
            { to: '/app/customerledger', icon: <InsertDriveFileIcon />, text: 'Customer Ledger' },
            { to: '/app/supplierledger', icon: <InsertDriveFileIcon />, text: 'Supplier Ledger' },
        ],
    },
    {
        title: 'Register and Payment ',
        links: [
            { to: '/app/paymentregister', icon: <InsertDriveFileIcon />, text: 'Payment Register' },
            { to: '/app/receiptregister', icon: <InsertDriveFileIcon />, text: 'Receipt Register' },
            { to: '/app/importpaymentreport', icon: <InsertDriveFileIcon />, text: 'Import payment Report' },
            { to: '/app/exportpaymentreport', icon: <InsertDriveFileIcon />, text: 'Export payment Report' },
        ],
    },
    {
        title: 'Costing',
        links: [
            { to: '/app/shipmentcostingreport', icon: <InsertDriveFileIcon />, text: 'Shipment Costing Report' },
            { to: '/app/productwiseexportshipment', icon: <InsertDriveFileIcon />, text: 'Product wise Export shipment' },
            { to: '/app/importshipmentcostingreport', icon: <InsertDriveFileIcon />, text: 'Import shipment costing Report' },
            { to: '/app/exportshipmentcostingreport', icon: <InsertDriveFileIcon />, text: 'Export shipment costing Report' },
        ],
    },
    {
        title: 'Tracking',
        links: [
            { to: '/app/importshipmenttracking', icon: <InsertDriveFileIcon />, text: 'Import Shipment Tracking' },
            { to: '/app/exportshipmenttracking', icon: <InsertDriveFileIcon />, text: 'Export Shipment Tracking' },
        ],
    },


    {
        title: 'Report',
        links: [


            { to: '/app/blreport', icon: <InsertDriveFileIcon />, text: 'B/L Report' },
            { to: '/app/productwiseimportreport', icon: <InsertDriveFileIcon />, text: 'Product wise import Report' },
            { to: '/app/productwiseexportreport', icon: <InsertDriveFileIcon />, text: 'Product wise export Report' },
        ],
    }

];



const ResponsiveCards = () => {
    return (
        <div>
            <ContentWrapper style={{ marginTop: '10px' }}>
                <Card className="shadow-sm">
                    <CardContent>
                        <Heading variant="h4" style={{ paddingBottom: '10px' }}>
                            Reports
                        </Heading>
                        <Grid container spacing={6}>
                            {cards.map((card, index) => (
                                <Grid item key={index} xs={12} sm={6} md={3}>
                                    <CustomCard>
                                        <CardBody>
                                            <Heading variant="h4">{card.title}</Heading>
                                            {card.links.map((link, linkIndex) => (
                                                <Typography key={linkIndex} variant="body2">
                                                    <CustomLink to={link.to}>
                                                        {link.icon} &nbsp; {link.text}
                                                    </CustomLink>
                                                </Typography>
                                            ))}
                                        </CardBody>
                                        <br />
                                    </CustomCard>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                    <br />
                </Card>
            </ContentWrapper>
        </div>
    );
};

export default ResponsiveCards;
