import React, { useEffect, useState } from 'react';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../common/DetailsPage/DetailsPageCard';
import DetailsPageCompany from '../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../common/DetailsPage/DetailPageClient';
import { TaskCardColumns, CallCardColumns, NotesCardColumns } from '../../constants/DetailsPage';
import { Main } from '../../constants/DetailsMain';
import { Call } from './OverViewMenu/Call';
import { Tasks } from './OverViewMenu/Tasks';
import { Notes } from './OverViewMenu/Notes';
import { useParams } from 'react-router-dom';
import { GET_Lead_leadId } from '../../services/apiRoutes';
import axios from 'axios';
import LeadDetail from './OverViewMenu/LeadDetail';
import { useNavigate } from 'react-router-dom';



export default function PersistentDrawerLeft() {
    const { leadId } = useParams();
    const navigate = useNavigate();  // Initialize useNavigate

    const [open, setOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('Overview');
    const [companyDetails, setCompanyDetails] = useState(null);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const AppBarMenu = [
        { value: 1, menu: 'Overview', to: `/app/leads/${leadId}` },
    ];

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(GET_Lead_leadId(leadId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setCompanyDetails(response.data.lead);
            })
            .catch(error => {
                console.error('Error fetching company details:', error);
            });
    }, [leadId]);

    if (!companyDetails) {
        return <p>Loading...</p>;
    }


    const {
        firstName,
        lastName,
        mobile,
        email,
        phone,

    } = companyDetails;


    const companyName = `${firstName} ${lastName}`;
    const phoneNumber = mobile;
    const initial = firstName.charAt(0).toUpperCase();

    const handleBackClick = () => {
        navigate("/app/leads");
    };

    return (
        <>
            <div>

                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarMenu}

                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />

                <Main open={open}>
                    <LeadDetail />
                    <br />
                    <Call />
                    <br />
                    <Tasks />
                    <br />
                    <Notes />
                    <br />
                    <DetailsPageCompany />
                    <br />
                </Main>
            </div>
        </>
    );
}
