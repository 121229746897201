import React, { useState } from "react";
import { Box, Grid, Table, TableHead, TableRow, TableCell, TableBody, Switch, Card, Typography, Divider } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import { ClearRounded, SaveRounded, Replay10Rounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";

const options = ["Option 1 a", "Option 2 b"];
const leadOwnerOptions = [
    { id: 1, title: "John Doe" },
    { id: 2, title: "Jane Smith" },
    { id: 3, title: "Alex Johnson" },
    // Add more options as needed
];

const leadSourceOptions = [
    { id: 1, title: "Online" },
    { id: 2, title: "Referral" },
    { id: 3, title: "Cold Call" },
    // Add more options as needed
];

const contactNameOptions = [
    { id: 1, title: "Alice Brown" },
    { id: 2, title: "Bob Martin" },
    { id: 3, title: "Charlie Clark" },
    // Add more options as needed
];

const styles = {
    tableCell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        p: '4px',
    },
};

const CreateAdminUsers = ({ handleClose, industryType, rating }) => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        profile_name: "",
        modules: [
            {
                module_name: "Report",
                permission_create: true,
                permission_update: false,
                permission_view: true,
                permission_delete: false
            },
            {
                module_name: "Master",
                permission_create: false,
                permission_update: true,
                permission_view: false,
                permission_delete: true
            }
        ]
    });

    const handleOwnerChange = (event, value) => {
        setFormData({
            ...formData,
            leadOwner: value,
        });
    };

    const handleSourceChange = (event, value) => {
        setFormData({
            ...formData,
            leadSource: value,
        });
    };

    const handleContactChange = (event, value) => {
        setFormData({
            ...formData,
            contactName: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
        // You can handle form submission logic here
    };

    const handleChange = (event, moduleIndex = null) => {
        const { name, type, checked, value } = event.target;

        if (moduleIndex !== null) {
            const newModules = [...formData.modules];
            newModules[moduleIndex] = {
                ...newModules[moduleIndex],
                [name]: type === 'checkbox' ? checked : value
            };
            setFormData({ ...formData, modules: newModules });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1} style={{ position: "sticky" }}>
                    <Grid item xs={4} md={6}>
                        <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                            Admin Company Access
                        </Typography>
                    </Grid>
                    <Grid item xs={8} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box style={{ display: "flex" }}>
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                                type="submit"
                            />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title=" Save and New"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<ClearRounded />}
                                title="Cancel"
                                size="small"
                                variant="contained"
                                onClick={handleClose}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />

                <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomAutocomplete
                            options={top100Films}
                            fieldLabel="Admin Name"
                            getOptionLabel={(option) => option.title}
                            value={formData.leadOwner}
                            required={true}
                            onChange={(e, newValue) =>
                                handleChange({ target: { name: "leadOwner", value: newValue } })
                            }
                            placeholder="Admin Name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomAutocomplete
                            options={top100Films}
                            fieldLabel="Profile Name"
                            getOptionLabel={(option) => option.title}
                            value={formData.leadOwner}
                            required={true}
                            onChange={(e, newValue) =>
                                handleChange({ target: { name: "leadOwner", value: newValue } })
                            }
                            placeholder="Profile Name"
                        />
                    </Grid>


                </Grid>
            </form>
            <br />
            <br />
        </>
    );
};

export default CreateAdminUsers;


const top100Films = [
    { title: "The Shawshank Redemption", id: 1994 },
    { title: "The Godfather", id: 1972 },
    { title: "The Godfather: Part II", id: 1974 },
];