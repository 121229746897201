import React from 'react';
import { AppBar, Box, Container, IconButton, InputBase, Toolbar, Typography, useMediaQuery, Menu, MenuItem, Link, Avatar, Grid } from '@mui/material';
import { Height, Menu as MenuIcon, Search as SearchIcon } from '@mui/icons-material';
import Logo from '../../assets/images/HRVERSE_logo.svg';
import { Card, CardContent, CardActions, } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import BannerSearch from './BannerSearch';




const FAQPage = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

            <BannerSearch />
            <br />
            <Container>
                <Typography variant="h1" sx={{ fontWeight: 'bold', color: '#007BFF', marginBottom: 2 }}>
                    Frequently Asked Questions
                </Typography>
                <Grid container spacing={2}>
                    {cardData.map((card, index) => (
                        <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
                            <Card sx={cardStyles}>
                                <Box sx={cardHeaderStyles}>
                                    <QuestionAnswerIcon sx={cardIconStyles} style={{ fontSize: "40px" }} />
                                    <Box sx={cardHeaderStyles}>
                                        <Typography sx={cardTitleStyles}>
                                            {card.title}
                                        </Typography>
                                    </Box>
                                </Box>
                                <CardContent sx={cardContentStyles}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {card.content}
                                    </Typography>
                                </CardContent>

                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <br />
        </>
    );
};

export default FAQPage;
const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align items to the left
    justifyContent: 'top',
    p: 2,
    boxShadow: 3,
    maxWidth: "100%",
    height: "200px"

};

const cardHeaderStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align header to the left
    width: '100%',
};

const cardIconStyles = {
    mr: 1,
};

const cardTitleStyles = {
    fontWeight: 'bold',
    fontSize: 15,
    transition: 'color 0.3s',
    '&:hover': {
        color: '#007BFF',
    },
    textAlign: 'left', // Align title to the left
};

const cardContentStyles = {
    textAlign: 'left', // Align content text to the left
};

const cardButtonStyles = {
    display: 'flex',
    justifyContent: 'flex-start', // Align buttons to the left
    width: '100%',
};

const cardData = [
    {
        "title": "What is Exim and how does it work?",
        "content": "Exim is a software tool designed to streamline import and export processes. It helps manage documentation, compliance, and logistics efficiently. For detailed usage instructions, refer to our user manual or video tutorials."
    },
    {
        "title": "How can I create export documents using Exim?",
        "content": "To create export documents, navigate to the 'Create Document' section in Exim. Select the type of document you need, fill in the required details, and save or export the document as needed. For step-by-step guidance, watch our tutorial videos."
    },
    {
        "title": "What is a Landed Cost Calculator and how do I use it?",
        "content": "A Landed Cost Calculator helps determine the total cost of importing goods, including shipping, duties, and taxes. To use it, input your shipment details and costs into the calculator, and it will provide a comprehensive cost breakdown."
    },
    {
        "title": "Where can I find industry resources and downloads?",
        "content": "Industry resources and downloadable content can be found in the 'Resources' section of our website. This includes guides, templates, and compliance documents relevant to import and export activities."
    },
    {
        "title": "How can I provide feedback on your services?",
        "content": "We welcome your feedback through our feedback form available on the 'Contact Us' page. Your input helps us improve our services and address any issues you may encounter."
    },
    {
        "title": "What should I do if I need a new document that isn't available?",
        "content": "If you need a document that isn't currently available, please submit a request through our 'Request a Document' form. Provide details about the document you need, and our team will review and consider adding it to our library."
    },
    {
        "title": "How do I track my shipments using Exim?",
        "content": "To track your shipments, go to the 'Track Shipments' section in Exim. Enter your shipment ID or details to view the current status and location of your shipment. You can also set up notifications for updates."
    },
    {
        "title": "What are the common issues with import/export documentation?",
        "content": "Common issues include incorrect or incomplete information, missing signatures, or discrepancies between documents. Ensure all information is accurate and complete, and verify that all necessary signatures and documents are included before submission."
    },
    {
        "title": "How can I update my company details in Exim?",
        "content": "To update your company details, go to the 'Account Settings' section in Exim. Edit your company profile information and save the changes. If you encounter any issues, contact our support team for assistance."
    },
    {
        "title": "Can I integrate Exim with other software tools?",
        "content": "Yes, Exim offers integration options with various software tools for enhanced functionality. Check our integration documentation for details on supported tools and how to set up integrations. Contact our support team for additional assistance if needed."
    }
];

