import React, { useState } from "react";
import { Box, Grid, Table, TableHead, TableRow, TableCell, TableBody, Switch, Card, Typography, Divider } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import { ClearRounded, SaveRounded, Replay10Rounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";

const options = ["Option 1 a", "Option 2 b"];
const leadOwnerOptions = [
  { id: 1, title: "John Doe" },
  { id: 2, title: "Jane Smith" },
  { id: 3, title: "Alex Johnson" },
  // Add more options as needed
];

const leadSourceOptions = [
  { id: 1, title: "Online" },
  { id: 2, title: "Referral" },
  { id: 3, title: "Cold Call" },
  // Add more options as needed
];

const contactNameOptions = [
  { id: 1, title: "Alice Brown" },
  { id: 2, title: "Bob Martin" },
  { id: 3, title: "Charlie Clark" },
  // Add more options as needed
];

const styles = {
  tableCell: {
    border: '1px solid #dee2e6',
    color: '#212529',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    p: '4px',
  },
};

const CreateCompanyModules = ({ handleClose, industryType, rating }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    profile_name: "",
    modules: [
      {
        module_name: "Report",
        permission_create: true,
        permission_update: false,
        permission_view: true,
        permission_delete: false
      },
      {
        module_name: "Master",
        permission_create: false,
        permission_update: true,
        permission_view: false,
        permission_delete: true
      }
    ]
  });

  const handleOwnerChange = (event, value) => {
    setFormData({
      ...formData,
      leadOwner: value,
    });
  };

  const handleSourceChange = (event, value) => {
    setFormData({
      ...formData,
      leadSource: value,
    });
  };

  const handleContactChange = (event, value) => {
    setFormData({
      ...formData,
      contactName: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form data:", formData);
    // You can handle form submission logic here
  };

  const handleChange = (event, moduleIndex = null) => {
    const { name, type, checked, value } = event.target;

    if (moduleIndex !== null) {
      const newModules = [...formData.modules];
      newModules[moduleIndex] = {
        ...newModules[moduleIndex],
        [name]: type === 'checkbox' ? checked : value
      };
      setFormData({ ...formData, modules: newModules });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} style={{ position: "sticky" }}>
          <Grid item xs={4} md={6}>
            <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
              Profile Permissions
            </Typography>
          </Grid>
          <Grid item xs={8} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box style={{ display: "flex" }}>
              <CustomButton
                startIcon={<SaveRounded />}
                title="Save"
                size="small"
                variant="contained"
              />
              <CustomButton
                startIcon={<ClearRounded />}
                title="Cancel"
                size="small"
                variant="contained"
              />

            </Box>
          </Grid>
        </Grid>
        <Divider />

        <Box sx={{ pt: 2 }}>
          <Box sx={{ overflowX: 'auto', overflowY: 'auto' }}>
            <Table sx={{ width: '100%' }}>
              <TableHead>
                <TableRow sx={{ height: '40px', backgroundColor: '#e7f0f7' }}>
                  <TableCell sx={{ ...styles.tableCell, width: '200px' }} rowSpan={2}>Module Name</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Create</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Update</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>View</TableCell>
                  <TableCell sx={{ ...styles.tableCell, width: '100px' }} rowSpan={2}>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formData.modules.map((module, index) => (
                  <TableRow key={index} sx={{ borderBottom: '1px solid #e0e0e0' }}>
                    <TableCell sx={styles.tableCell}>{module.module_name}</TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="permission_create"
                        checked={module.permission_create}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="permission_update"
                        checked={module.permission_update}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="permission_view"
                        checked={module.permission_view}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                    <TableCell sx={styles.tableCell}>
                      <Switch
                        name="permission_delete"
                        checked={module.permission_delete}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </form>
      <br />
      <br />
    </>
  );
};

export default CreateCompanyModules;
