import React, { useState } from 'react';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../common/DetailsPage/DetailsPageCard';
import DetailsPageCompany from '../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../common/DetailsPage/DetailPageClient';
import { TaskCardColumns, CallCardColumns, NotesCardColumns, ExportMenu } from '../../constants/DetailsPage'
import { Main } from '../../constants/DetailsMain';
import { Call } from '../Leads/OverViewMenu/Call';
import { Tasks } from '../Leads/OverViewMenu/Tasks';
import { Notes } from '../Leads/OverViewMenu/Notes';
import ExportDetail from './ExportMenu/ExportDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { useExportShipmentDetails } from './ExportMenu/Hook/useExportShipmentDetails';

export default function PersistentDrawerLeft() {

    const [open, setOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('Overview'); // Track active tab
    const navigate = useNavigate();
    const { exportShipmentId } = useParams();

    const { shipmentDetails } = useExportShipmentDetails(exportShipmentId);
    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const companyName = shipmentDetails.company.companyName;
    const phoneNumber = shipmentDetails.shipmentId;
    const initial = shipmentDetails.company.companyName ? shipmentDetails.company.companyName.charAt(0).toUpperCase() : '';

    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };

    return (
        <>
            <div>
                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={ExportMenu(exportShipmentId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />

                <Main open={open}>
                    {/* <DetailPageClient /> */}
                    <ExportDetail />
                    <br />
                    <Call />
                    <br />
                    <Tasks />
                    <br />
                    <Notes />
                    <br />
                    <DetailsPageCompany />
                    <br />
                </Main>
            </div >
        </>
    );
}

