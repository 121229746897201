// CustomTable.js
import React from 'react';
import { Box, Card, CardContent, Divider, Table, TableBody, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const DetailPageCustum = ({ title, data }) => {
    const cardStyle = {
        // Add custom styles here if needed
    };

    const cardBodyStyle = {
        padding: '1rem',
    };

    const tableContainerStyle = {
        // maxHeight: '400px', 
        overflowY: 'auto',
        overflowX: 'auto',
        display: 'block',
    };

    const tableStyle = {
        minWidth: '600px',
        borderCollapse: 'collapse',
    };

    const tableCellStyle = {
        width: '200px',
        color: '#6c757d',
        border: 'none',
        borderBottom: '0',
    };

    const tableCellStylemiddle = {
        width: '10px',
        color: '#6c757d',
        border: 'none',
        borderBottom: '0',
    };

    const tableCellStylelast = {
        border: 'none', // Remove all borders
        borderBottom: '0', // Remove bottom border from all cells
    };

    const tableRow = {
        height: '20px',
    };

    return (
        <Box id="overview">
            <Card style={cardStyle} variant="outlined">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.2rem', padding: '0.5rem' }}>
                        {title}
                    </span>
                    <span style={{ marginRight: "12px" }}>
                        <EditIcon style={{ color: "#007bff", cursor: "pointer" }} />
                    </span>
                </div>
                <Divider />
                <CardContent style={cardBodyStyle}>
                    <Box style={tableContainerStyle}>
                        <Table style={tableStyle} size="small" aria-label={title}>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow key={index} style={tableRow}>
                                        <TableCell style={tableCellStyle}>{row.label}</TableCell>
                                        <TableCell style={tableCellStylemiddle}>-</TableCell>
                                        <TableCell style={tableCellStylelast}>{row.value}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default DetailPageCustum;




