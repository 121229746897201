// src/components/PersistentDrawerLeft.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCompany from '../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../common/DetailsPage/DetailPageClient';
import { Main } from '../../constants/DetailsMain';
import CustomModal from "../../components/common/CustomModal";
import ImportDetail from './ImportMenu/ImportDetail';
import { Call } from '../Leads/OverViewMenu/Call';
import { Tasks } from '../Leads/OverViewMenu/Tasks';
import { Notes } from '../Leads/OverViewMenu/Notes';
import { AppBarMenu } from '../../constants/DetailsPage';
import { useShipmentDetails } from './ImportMenu/useShipmentDetails';

export default function PersistentDrawerLeft() {
    const { importShipmentId } = useParams();
    const [open, setOpen] = useState(true);
    const [leadModal, setLeadModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Overview');
    const navigate = useNavigate();

    const { shipmentDetails } = useShipmentDetails(importShipmentId);
    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };

    const companyName = shipmentDetails.supplier.clientCompanyName;
    const phoneNumber = shipmentDetails.supplier.mobile;
    const initial = shipmentDetails.supplier.clientCompanyName ? shipmentDetails.supplier.clientCompanyName.charAt(0).toUpperCase() : '';

    const handleBackClick = () => {
        navigate("/app/import/shipement");
    };

    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={AppBarMenu(importShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}>
                <ImportDetail />
                <br />
                <Call />
                <br />
                <Tasks />
                <br />
                <Notes />
                <br />
                <DetailsPageCompany />
                <br />
            </Main>
        </div>
    );
}
