
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailsPageAppbar from '../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../common/DetailsPage/DetailsPageCard';
import DetailsPageCompany from '../common/DetailsPage/DetailsPageCompany';
import DetailPageClient from '../common/DetailsPage/DetailPageClient';
import { TaskCardColumns, CallCardColumns, NotesCardColumns, AppBarCompany } from '../../constants/DetailsPage'
import { Main } from '../../constants/DetailsMain';
import { Call } from '../Leads/OverViewMenu/Call';
import { Tasks } from '../Leads/OverViewMenu/Tasks';
import { Notes } from '../Leads/OverViewMenu/Notes';
import CompanyDetail from './CompanyMenu/CompanyDetail';
import { useNavigate } from 'react-router-dom';
import { GET_clientCompany_Id } from '../../services/apiRoutes';
import { useClientCompanyDetails } from './CompanyMenu/UseClientCompanyDetails';


export default function PersistentDrawerLeft() {
    const navigate = useNavigate();  // Initialize useNavigate
    const { clientCompanyId } = useParams();
    const { companyDetail } = useClientCompanyDetails(clientCompanyId);


    const [open, setOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState('Overview'); // Track active tab

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };


    if (!companyDetail) {
        return <p>Loading...</p>;
    }
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };

    return (
        <>
            <div>
                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={AppBarCompany(clientCompanyId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}

                />

                <Main open={open}>
                    {/* <DetailPageClient /> */}
                    <CompanyDetail />
                    <br />
                    <Call />
                    <br />
                    <Tasks />
                    <br />
                    <Notes />
                    <br />
                    <DetailsPageCompany />
                    <br />
                </Main>
            </div >
        </>
    );
}

