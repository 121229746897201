import React from "react";
import { Navigate } from "react-router-dom";
import Lead from "../Pages/Leads";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Contacts from "../Pages/Contacts";
import Company from "../Pages/Company";
import ImportShipment from "../Pages/ImportShipment";
import ExportShipment from "../Pages/ExportShipment";
import Reports from "../Pages/Reports";
import Settingmaster from "../Pages/settingmaster";
import LeadDetails from "../components/Leads/LeadDetails";
import CompanyDetails from "../components/company/CompanyDetails";
import ImportDetails from "../components/importshipement/ImportDetails";
import ExportDetails from "../components/exportshipement/ExportDetails";
import ContactsDetails from "../components/contacts/ContactsDetails";

import PurchaseOrder from "../components/importshipement/ImportMenu/PurchaseOrder";
import ShippingMark from "../components/importshipement/ImportMenu/ShippingMark";
import Booking from "../components/importshipement/ImportMenu/Booking";
import Tracker from "../components/importshipement/ImportMenu/Tracker";
import Payment from "../components/importshipement/ImportMenu/Payment";
import Expense from "../components/importshipement/ImportMenu/Expense";
import Document from "../components/importshipement/ImportMenu/Document";

import LeadMenu from "../components/company/CompanyMenu/LeadMenu";
import ContactsMenu from "../components/company/CompanyMenu/ContactsMenu";
import ImportMenu from "../components/company/CompanyMenu/ImportMenu";
import ExportMenu from "../components/company/CompanyMenu/ExportMenu";

import SalesOrder from '../components/exportshipement/ExportMenu/SalesOrder';
import ExportShippingMark from '../components/exportshipement/ExportMenu/ShippingMark';
import ProformaInvoice from '../components/exportshipement/ExportMenu/ProformaInvoice';
import SalesContract from '../components/exportshipement/ExportMenu/SalesContract';
import CommercialInvoice from '../components/exportshipement/ExportMenu/CommercialInvoice';
import ExportDocument from '../components/exportshipement/ExportMenu/Document';

import ReceiptVoucher from '../components/exportshipement/ExportMenu/ReceiptVoucher';
import BLDraft from '../components/exportshipement/ExportMenu/BLDraft';
import COA from '../components/exportshipement/ExportMenu/COA';
import COO from '../components/exportshipement/ExportMenu/COO';
import PackingList from '../components/exportshipement/ExportMenu/PackingList';
import ShipmentBooking from '../components/exportshipement/ExportMenu/ShipmentBooking';
import ExpenseVoucher from '../components/exportshipement/ExportMenu/ExpenseVoucher';

// Report route
import ImportShipmentReport from '../components/Reports/ImportShipmentReport';
import FreeDaysReport from '../components/Reports/FreeDaysReport';
import ProformInvReport from '../components/Reports/ProformInvReport';
import SalesContractReport from '../components/Reports/SalesContractReport';
import CommercialInvoiceRegister from '../components/Reports/CommercialInvoiceRegister';
import CustomerLedger from '../components/Reports/CustomerLedger';
import SupplierLedger from '../components/Reports/SupplierLedger';
import PaymentRegister from '../components/Reports/PaymentRegister';
import ReceiptRegister from '../components/Reports/ReceiptRegister';
import ShipmentCostingReport from '../components/Reports/ShipmentCostingReport';
import ProductWiseExportShipment from '../components/Reports/ProductWiseExportShipment';
import ImportShipmentTracking from '../components/Reports/ImportShipmentTracking';
import ExportShipmentTracking from '../components/Reports/ExportShipmentTracking';


import ImportShipmentCostingReport from '../components/Reports/ImportShipmentCostingReport';
import ExportShipmentCostingReport from '../components/Reports/ExportShipmentCostingReport';
import ImportPaymentReport from '../components/Reports/ImportPaymentReport';
import ExportPaymentReport from '../components/Reports/ExportPaymentReport';
import BLReport from '../components/Reports/BLReport';
import BatchNoWiseImportShipmentReport from '../components/Reports/BatchNoWiseImportShipmentReport';
import BatchNoWiseExportShipmentReport from '../components/Reports/BatchNoWiseExportShipmentReport';
import ProductWiseImportReport from '../components/Reports/ProductWiseImportReport';
import ProductWiseExportReport from '../components/Reports/ProductWiseExportReport';
// masters
import AdminUsers from '../components/Masters/AdminUsers';
import UserManagement from '../components/Masters/UserManagement';
import ExpenseLedgerGroup from '../components/Masters/ExpenseLedgerGroup';
import CurrencyMaster from '../components/Masters/CurrencyMaster';
import ShippingLineMaster from '../components/Masters/ShippingLineMaster';
import FreightForwardMaster from '../components/Masters/FreightForwardMaster';
import CustomClearingAgency from '../components/Masters/CustomClearingAgency';
import ManageProducts from '../components/Masters/ManageProducts';
import UnitMaster from '../components/Masters/UnitMaster';
import BusinessGroup from '../components/Masters/BusinessGroup';
import CompanyGroup from '../components/Masters/CompanyGroup';
import ContactGroup from '../components/Masters/ContactGroup';
import CompanyModules from '../components/Masters/CompanyModules';
import CompanyUsers from '../components/Masters/CompanyUsers';
import SecurityControls from '../components/Masters/SecurityControls';
import HelpPage from "../components/Home/HelpPage";
import HelpCenteruse from "../components/Home/HelpCenteruse";
import FAQPage from "../components/Home/FAQPage";
import Homepage from "../components/Home/HomePage";
import Features from "../components/Home/Features";
import Pricing from "../components/Home/Pricing";
import HelpCenterDetail from "../components/Home/HelpCenterDetail";
import Blog from "../components/Home/Blog";
import BlogDetails from "../components/Home/BlogDetails";
import CompanySetting from "../components/Masters/CompanySetting";
import SystemSetting from "../components/Masters/SystemSetting";
import TermConditions from "../components/Masters/TermConditions";
import Dashboard from "../Pages/Dashboard";
import ClientCompany from '../Pages/MyCompany'
import FirstTimeLoginCompany from "../components/AuthPages/Company";
import CompanyType from "../components/Masters/CompanyType";
import LossReasons from "../components/Masters/LossReasons";
import Priorities from "../components/Masters/Priorities";
import LeadStages from "../components/Masters/LeadStages";
import CallOutcomeTypes from "../components/Masters/CallOutcomeTypes";
import CallPurposeTypes from "../components/Masters/CallPurposeTypes";
import OwnershipTypes from "../components/Masters/OwnershipTypes";
import TaskStatuses from "../components/Masters/TaskStatuses";
import Modules from "../components/Masters/Modules";
import ContactTypes from "../components/Masters/ContactTypes";
import Ratings from "../components/Masters/Ratings";
import LeadStatuses from "../components/Masters/LeadStatuses";
import LeadSources from "../components/Masters/LeadSources";
import SuppliersList from "../components/Masters/SuppliersList";
import CurrencyList from "../components/Masters/CurrencyList";
import ShipmentTypes from "../components/Masters/ShipmentTypes";
import Ciffoblist from "../components/Masters/Ciffoblist";
import ProformaInvoiceImport from "../components/importshipement/ImportMenu/ProformaInvoiceImport";
import CommercialInvoiceImport from "../components/importshipement/ImportMenu/CommercialInvoiceImport";
import Subscription from "../Pages/Subscription";
import SubscriptionHistory from "../Pages/SubscriptionHistory";




const authProtectedRoutes = [
  { path: "app/leads", component: <Lead /> },
  { path: "app/dashboard", component: <Dashboard /> },

  { path: "app/leads/:leadId", component: <LeadDetails /> },
  { path: "app/contacts", component: <Contacts /> },
  { path: "app/contacts/:contactId", component: <ContactsDetails /> },
  { path: "app/company", component: <Company /> },
  { path: "app/export/shipement", component: <ExportShipment /> },
  { path: "app/export/shipement/:exportShipmentId", component: <ExportDetails /> },
  { path: "app/reports", component: <Reports /> },
  { path: "app/settingmaster", component: <Settingmaster /> },

  // import path
  { path: "app/import/shipement", component: <ImportShipment /> },
  { path: "app/import/shipement/:importShipmentId", component: <ImportDetails /> },
  { path: "app/import/purchaseorder/:importShipmentId", component: <PurchaseOrder /> },
  { path: '/app/import/shippingmark/:importShipmentId', component: <ShippingMark /> },
  { path: '/app/import/booking/:importShipmentId', component: <Booking /> },
  { path: '/app/import/tracker/:importShipmentId', component: <Tracker /> },
  { path: '/app/import/payment/:importShipmentId', component: <Payment /> },
  { path: '/app/import/expense/:importShipmentId', component: <Expense /> },
  { path: '/app/import/proformainvoice/:importShipmentId', component: <ProformaInvoiceImport /> },
  { path: '/app/import/commercialinvoice/:importShipmentId', component: <CommercialInvoiceImport /> },
  { path: '/app/import/document/:importShipmentId', component: <Document /> },

  // company path
  { path: "app/company/:clientCompanyId", component: <CompanyDetails /> },
  { path: '/app/company/lead/:clientCompanyId', component: <LeadMenu /> },
  { path: '/app/company/contacts/:clientCompanyId', component: <ContactsMenu /> },
  { path: '/app/company/import/:clientCompanyId', component: <ImportMenu /> },
  { path: '/app/company/export/:clientCompanyId', component: <ExportMenu /> },

  // export path
  { path: '/app/export/salesorder/:exportShipmentId', component: <SalesOrder /> },
  { path: '/app/export/shippingmark/:exportShipmentId', component: <ExportShippingMark /> },
  { path: '/app/export/proformainvoice/:exportShipmentId', component: <ProformaInvoice /> },
  { path: '/app/export/salescontract/:exportShipmentId', component: <SalesContract /> },
  { path: '/app/export/commercialinvoice/:exportShipmentId', component: <CommercialInvoice /> },
  { path: '/app/export/document/:exportShipmentId', component: <ExportDocument /> },
  { path: '/app/export/receiptvoucher/:exportShipmentId', component: <ReceiptVoucher /> },
  { path: '/app/export/bldraft/:exportShipmentId', component: <BLDraft /> },
  { path: '/app/export/coa/:exportShipmentId', component: <COA /> },
  { path: '/app/export/coo/:exportShipmentId', component: <COO /> },
  { path: '/app/export/packinglist/:exportShipmentId', component: <PackingList /> },
  { path: '/app/export/shipmentbooking/:exportShipmentId', component: <ShipmentBooking /> },
  { path: '/app/export/expensevoucher/:exportShipmentId', component: <ExpenseVoucher /> },

  // Reports route 
  { path: '/app/importshipmentreport', component: <ImportShipmentReport /> },
  { path: '/app/freedaysreport', component: <FreeDaysReport /> },
  { path: '/app/proforminvreport', component: <ProformInvReport /> },
  { path: '/app/salescontractreport', component: <SalesContractReport /> },
  { path: '/app/commercialinvoiceregister', component: <CommercialInvoiceRegister /> },
  { path: '/app/customerledger/', component: <CustomerLedger /> },
  { path: '/app/supplierledger', component: <SupplierLedger /> },
  { path: '/app/paymentregister', component: <PaymentRegister /> },
  { path: '/app/receiptregister', component: <ReceiptRegister /> },
  { path: '/app/shipmentcostingreport', component: <ShipmentCostingReport /> },
  { path: '/app/productwiseexportshipment', component: <ProductWiseExportShipment /> },
  { path: '/app/importshipmenttracking', component: <ImportShipmentTracking /> },
  { path: '/app/exportshipmenttracking', component: <ExportShipmentTracking /> },

  { path: '/app/importshipmentcostingreport', component: <ImportShipmentCostingReport /> },
  { path: '/app/exportshipmentcostingreport', component: <ExportShipmentCostingReport /> },
  { path: '/app/importpaymentreport', component: <ImportPaymentReport /> },
  { path: '/app/exportpaymentreport', component: <ExportPaymentReport /> },
  { path: '/app/blreport', component: <BLReport /> },
  { path: '/app/batchnowiseimportshipmentreport', component: <BatchNoWiseImportShipmentReport /> },
  { path: '/app/batchnowiseexportshipmentreport', component: <BatchNoWiseExportShipmentReport /> },
  { path: '/app/productwiseimportreport', component: <ProductWiseImportReport /> },
  { path: '/app/productwiseexportreport', component: <ProductWiseExportReport /> },

  // master path

  { path: '/app/adminusers', component: <AdminUsers /> },
  { path: '/app/usermanagement', component: <UserManagement /> },
  { path: '/app/expenseledgergroup', component: <ExpenseLedgerGroup /> },
  { path: '/app/currencymaster', component: <CurrencyMaster /> },
  { path: '/app/shippinglinemaster', component: <ShippingLineMaster /> },
  { path: '/app/freightforwardmaster', component: <FreightForwardMaster /> },
  { path: '/app/customclearingAgency', component: <CustomClearingAgency /> },
  { path: '/app/manageproducts', component: <ManageProducts /> },
  { path: '/app/unitmaster', component: <UnitMaster /> },
  { path: '/app/businessgroup', component: <BusinessGroup /> },
  { path: '/app/companygroup', component: <CompanyGroup /> },
  { path: '/app/contactgroup', component: <ContactGroup /> },
  { path: '/app/companymodules', component: <CompanyModules /> },
  { path: '/app/companytype', component: <CompanyType /> },

  { path: '/app/ownershiptypes', component: <OwnershipTypes /> },
  { path: '/app/lossreasons', component: <LossReasons /> },
  { path: '/app/priorities', component: <Priorities /> },
  { path: '/app/leadstages', component: <LeadStages /> },
  { path: '/app/calloutcometypes', component: <CallOutcomeTypes /> },
  { path: '/app/callpurposetypes', component: <CallPurposeTypes /> },

  { path: '/app/taskstatuses', component: <TaskStatuses /> },
  { path: '/app/modules', component: <Modules /> },
  { path: '/app/contacttypes', component: <ContactTypes /> },
  { path: '/app/ratings', component: <Ratings /> },
  { path: '/app/leadstatuses', component: <LeadStatuses /> },
  { path: '/app/leadsources', component: <LeadSources /> },

  { path: '/app/supplierslist', component: <SuppliersList /> },
  { path: '/app/currencylist', component: <CurrencyList /> },
  { path: '/app/shipmenttypes', component: <ShipmentTypes /> },
  { path: '/app/ciffoblist', component: <Ciffoblist /> },

  // { path: '/app/companysetting', component: <CompanySetting /> },
  { path: "/app/companysetting/:id", component: <CompanySetting /> },
  { path: '/app/companyusers', component: <CompanyUsers /> },
  { path: '/app/securitycontrols', component: <SecurityControls /> },
  { path: '/app/systemsetting', component: <SystemSetting /> },
  { path: '/app/termcondition', component: <TermConditions /> },
  { path: '/app/mycompany', component: <ClientCompany /> },
  { path: '/app/subscription', component: <Subscription /> },
  { path: '/app/subscription-history', component: <SubscriptionHistory /> },

];

const publicRoutes = [
  { path: "/", component: <Homepage /> },
  { path: "/faqpage", component: <FAQPage /> },
  { path: "/features", component: <Features /> },
  { path: "/pricing", component: <Pricing /> },
  { path: "/blog", component: <Blog /> },
  { path: "/blog/details", component: <BlogDetails /> },
  { path: "/helpcenter", component: <HelpPage /> },
  { path: "/helpcenteruse", component: <HelpCenteruse /> },
  { path: "/helpcenterdetail", component: <HelpCenterDetail /> },

];

const openRoutes = [

  { path: "/login", component: <Login /> },
  { path: "/add/company", component: <FirstTimeLoginCompany /> },


];


export { authProtectedRoutes, publicRoutes, openRoutes };


