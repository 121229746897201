import React, { useState } from "react";
import { Box, Grid, Divider, Typography } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";

const userLevels = ["Admin", "Super Admin", "User"];

const CreateAdmin = ({ handleClose }) => {
    const [formData, setFormData] = useState({
        adminID: "",
        username: "",
        password: "",
        userLevel: "",
        adminEmail: "",
        creditAmount: "",
        mobile: "",
        companyName: "",
        companyID: "",
        expireDate: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUserLevelChange = (event, value) => {
        setFormData({ ...formData, userLevel: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
        // You can handle form submission logic here
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1} style={{ position: "sticky" }}>
                    <Grid item xs={4} md={6}>
                        <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                            Create Admin
                        </Typography>
                    </Grid>
                    <Grid item xs={8} md={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Box style={{ display: "flex" }}>
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                                type="submit"
                            />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save and New"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<ClearRounded />}
                                title="Cancel"
                                size="small"
                                variant="contained"
                                onClick={handleClose}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="adminID"
                            type="text"
                            fieldLabel="Admin ID"
                            value={formData.adminID}
                            onChange={handleChange}
                            placeholder="Enter Admin ID"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="username"
                            type="text"
                            fieldLabel="Username"
                            value={formData.username}
                            onChange={handleChange}
                            placeholder="Enter Username"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="password"
                            type="password"
                            fieldLabel="Password"
                            value={formData.password}
                            onChange={handleChange}
                            placeholder="Enter Password"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomAutocomplete
                            options={userLevels}
                            fieldLabel="User Level"
                            getOptionLabel={(option) => option}
                            value={formData.userLevel}
                            onChange={(e, newValue) => handleUserLevelChange(e, newValue)}
                            placeholder="Select User Level"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="adminEmail"
                            type="email"
                            fieldLabel="Admin Email"
                            value={formData.adminEmail}
                            onChange={handleChange}
                            placeholder="Enter Admin Email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="creditAmount"
                            type="number"
                            fieldLabel="Credit Amount"
                            value={formData.creditAmount}
                            onChange={handleChange}
                            placeholder="Enter Credit Amount"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="mobile"
                            type="text"
                            fieldLabel="Mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            placeholder="Enter Mobile"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="companyName"
                            type="text"
                            fieldLabel="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            placeholder="Enter Company Name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="companyID"
                            type="text"
                            fieldLabel="Company ID"
                            value={formData.companyID}
                            onChange={handleChange}
                            placeholder="Enter Company ID"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CustomTextField
                            name="expireDate"
                            type="date"
                            fieldLabel="Expire Date"
                            value={formData.expireDate}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                            placeholder="MM/DD/YYYY"
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default CreateAdmin;
