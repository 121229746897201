import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Card, Typography, Divider } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import {
    ClearRounded,
    SaveRounded,
    Replay10Rounded,
    Height,
} from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";

const options = ["Option 1 a", "Option 2 b"];
const leadOwnerOptions = [
    { id: 1, title: "John Doe" },
    { id: 2, title: "Jane Smith" },
    { id: 3, title: "Alex Johnson" },
    // Add more options as needed
];

const leadSourceOptions = [
    { id: 1, title: "Online" },
    { id: 2, title: "Referral" },
    { id: 3, title: "Cold Call" },
    // Add more options as needed
];

const contactNameOptions = [
    { id: 1, title: "Alice Brown" },
    { id: 2, title: "Bob Martin" },
    { id: 3, title: "Charlie Clark" },
    // Add more options as needed
];

export default function CustomizedDialogs() {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        leadName: "",
        leadOwner: null,
        leadSource: null,
        contactName: null,
        clientCompany: "",
        leadStatus: "",
        stage: "",
        priority: "",
        lossReason: "",
        productType: "",
        expectedTimeline: "",
        productRadio: "0",
        leadDescription: "",
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleOwnerChange = (event, value) => {
        setFormData({
            ...formData,
            leadOwner: value,
        });
    };

    const handleSourceChange = (event, value) => {
        setFormData({
            ...formData,
            leadSource: value,
        });
    };

    const handleContactChange = (event, value) => {
        setFormData({
            ...formData,
            contactName: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
        // You can handle form submission logic here
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid
                    container
                    spacing={1}
                // sx={{ paddingBottom: 2 }}
                >
                    <Grid item xs={12} md={4} >
                        <Typography
                            sx={{ fontWeight: 600 }}
                            style={{ color: "#212529", fontSize: "18px", }}
                        >
                            Create Shipping Mark
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Box style={{ display: "flex" }}>
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title=" Save and New"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<ClearRounded />}
                                title="Cancel"
                                size="small"
                                variant="contained"
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />

                <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomTextField
                            name="lastName"
                            fieldLabel="Batch No."
                            value={formData.lastName}
                            required={true}
                            onChange={handleChange}
                            placeholder="Enter Batch No."
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomAutocomplete
                            options={top100Films}
                            fieldLabel="Product Name"
                            required={true}
                            getOptionLabel={(option) => option.title}
                            value={formData.company}
                            onChange={(e, newValue) =>
                                handleChange({ target: { name: "company", value: newValue } })
                            }
                            placeholder="Select Product Name"
                        />
                    </Grid>


                    <Grid item xs={8}>
                        <CustomTextarea
                            name="country"
                            type="text"
                            fieldLabel="Shipping Mark"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Write Shipping Mark"
                            sx={{
                                height: '100px !important',
                            }}
                        />
                    </Grid>

                </Grid>
            </form>
        </>
    );
}
const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
];
