import { Box, Typography, Grid, CircularProgress, Divider, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import axios from 'axios';
import React, { useState, useEffect } from "react";
import { UPDATE_BL_DRAFT_EXPORT, READ_BL_DRAFT_EXPORT } from '../../../services/apiRoutes';
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import IconButton from '@mui/material/IconButton';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import AddRounded from '@mui/icons-material/AddRounded';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import Card from '@mui/material/Card';
import CustomAutocomplete from '../../common/CustomAutoComplete';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

const ValidationSchema = Yup.object().shape({
    shipper: Yup.string().required("Shipper is required"),
    paymentMethod: Yup.string().required("Payment Method is required"),
    blNumber: Yup.string().required("BL Number is required"),
    orderMethod: Yup.string().oneOf(["FOB", "CIF"], "Invalid Order Method").required("Order Method is required"),
    consignee: Yup.string().required("Consignee is required"),
    hsCode: Yup.string().required("HS Code is required"),
    specialRequirement: Yup.string(),
    notifyParty: Yup.string().required("Notify Party is required"),
    alsoNotify: Yup.string(),
    oceanVesselVoyNo: Yup.string().required("Ocean Vessel Voyage No. is required"),
    portOfLoading: Yup.string().required("Port of Loading is required"),
    excessValueDeclaration: Yup.string().required("Excess Value Declaration is required"),
    portOfDestination: Yup.string().required("Port of Destination is required"),
    finalDestination: Yup.string().required("Final Destination is required"),
    freightPayableAt: Yup.string().required("Freight Payable At is required"),
    noOfOriginals: Yup.number().required("Number of Originals is required").positive().integer(),
    marks: Yup.string(),
    noOfPkgs: Yup.number().required("Number of Packages is required").positive().integer(),
    descriptionOfGoods: Yup.string().required("Description of Goods is required"),
    grossWeight: Yup.number().required("Gross Weight is required").positive(),
    measurement: Yup.number().required("Measurement is required").positive(),
    particulars: Yup.array()
        .of(
            Yup.object().shape({
                sealNo: Yup.string().required("Seal No. is required"),
                type: Yup.string().required("Type is required"),
                noOfPkgs: Yup.number().required("Number of Packages is required").positive().integer(),
                grossWeight: Yup.number().required("Gross Weight is required").positive(),
                measurement: Yup.number().required("Measurement is required").positive(),
            })
        )
        .required("Particulars are required"),
});


const UpdateBLDraft = ({ handleClose, setMessage, fetchData, blDraftId, }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // Fetch data for the commercial invoice
    useEffect(() => {
        if (!blDraftId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_BL_DRAFT_EXPORT(blDraftId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setData(response.data.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [blDraftId]);

    if (loading) return <CircularProgress />;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_BL_DRAFT_EXPORT(blDraftId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Proforma Invoice updated successfully",
                    type: "success",
                });
                fetchData();
                setTimeout(() => {
                    handleClose();
                }, 500);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    shipper: data?.shipper || '',
                    paymentMethod: data?.paymentMethod || '',
                    blNumber: data?.blNumber || '',
                    orderMethod: data?.orderMethod || '',
                    consignee: data?.consignee || '',
                    hsCode: data?.hsCode || '',
                    specialRequirement: data?.specialRequirement || '',
                    notifyParty: data?.notifyParty || '',
                    alsoNotify: data?.alsoNotify || '',
                    oceanVesselVoyNo: data?.oceanVesselVoyNo || '',
                    portOfLoading: data?.portOfLoading || '',
                    excessValueDeclaration: data?.excessValueDeclaration || '',
                    portOfDestination: data?.portOfDestination || '',
                    finalDestination: data?.finalDestination || '',
                    freightPayableAt: data?.freightPayableAt || '',
                    noOfOriginals: data?.noOfOriginals || "",
                    marks: data?.marks || '',
                    noOfPkgs: data?.noOfPkgs || "",
                    descriptionOfGoods: data?.descriptionOfGoods || '',
                    grossWeight: data?.grossWeight || "",
                    measurement: data?.measurement || "",
                    particulars: data?.particulars || [{
                        sealNo: '',
                        type: '',
                        noOfPkgs: "",
                        grossWeight: "",
                        measurement: "",
                    }],
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid
                            container
                            spacing={1}
                            style={{ position: "sticky" }}
                        >
                            <Grid item xs={4} md={4}>
                                <Typography
                                    sx={{ fontWeight: 600 }}
                                    style={{ color: "#212529", fontSize: "18px" }}
                                >
                                    Update BL Draft
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                md={8}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ marginTop: "5px" }}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Shipper
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            placeholder="Enter Shipper"
                                            name="shipper"
                                            required={true}
                                            value={values.shipper}
                                            onChange={handleChange}
                                            error={touched.shipper && !!errors.shipper}
                                            helperText={touched.shipper && errors.shipper}
                                            sx={{ height: '80px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined'>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" flexDirection="row" alignItems="center" mb={0}>
                                            <Typography style={{ width: "250px", fontWeight: 500, color: "#212529", fontSize: "16px", padding: "5px", backgroundColor: "#e7f0f7", flex: 1 }}>
                                                Payment Method
                                            </Typography>
                                            <Typography sx={{ marginLeft: "2px", marginRight: "2px", }}>
                                                <CustomTextField
                                                    placeholder="Enter Payment Method"
                                                    name="paymentMethod"
                                                    required={true}
                                                    value={values.paymentMethod}
                                                    onChange={handleChange}
                                                    error={touched.paymentMethod && !!errors.paymentMethod}
                                                    helperText={touched.paymentMethod && errors.paymentMethod}
                                                />
                                            </Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center" mb={0}>
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", padding: "5px", backgroundColor: "#e7f0f7", flex: 1 }}>
                                                BL Number
                                            </Typography>
                                            <Typography sx={{ marginLeft: "2px", marginRight: "2px" }}>
                                                <CustomTextField
                                                    placeholder="Enter BL Number"
                                                    name="blNumber"
                                                    required={true}
                                                    value={values.blNumber}
                                                    onChange={handleChange}
                                                    error={touched.blNumber && !!errors.blNumber}
                                                    helperText={touched.blNumber && errors.blNumber}
                                                />
                                            </Typography>
                                        </Box>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px", padding: "5px", backgroundColor: "#e7f0f7", flex: 1 }}>
                                                Order Method
                                            </Typography>
                                            <Typography sx={{ marginLeft: "2px", marginRight: "2px" }}>
                                                <CustomTextField
                                                    placeholder="Enter Order Method "
                                                    name="orderMethod"
                                                    required={true}
                                                    value={values.orderMethod}
                                                    onChange={handleChange}
                                                    error={touched.orderMethod && !!errors.orderMethod}
                                                    helperText={touched.orderMethod && errors.orderMethod}

                                                />
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Consignee
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="consignee"
                                            required={true}
                                            value={values.consignee}
                                            onChange={handleChange}
                                            error={touched.consignee && !!errors.consignee}
                                            helperText={touched.consignee && errors.consignee}
                                            placeholder="Enter Consignee"
                                            sx={{ height: '105px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                HS Code
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter HS Code"
                                            name="hsCode"
                                            required={true}
                                            value={values.hsCode}
                                            onChange={handleChange}
                                            error={touched.hsCode && !!errors.hsCode}
                                            helperText={touched.hsCode && errors.hsCode}
                                        />
                                    </Typography>

                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Please Indicate any special requirement below
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            required={true}
                                            placeholder="Enter any special requirement"
                                            name="specialRequirement"
                                            value={values.specialRequirement}
                                            onChange={handleChange}
                                            error={touched.specialRequirement && !!errors.specialRequirement}
                                            helperText={touched.specialRequirement && errors.specialRequirement}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Notify Party
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Notify Party"
                                            name="notifyParty"
                                            required={true}
                                            value={values.notifyParty}
                                            onChange={handleChange}
                                            error={touched.notifyParty && !!errors.notifyParty}
                                            helperText={touched.notifyParty && errors.notifyParty}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Also Notify
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            required={true}
                                            placeholder="Enter Also Notify"
                                            name="alsoNotify"
                                            value={values.alsoNotify}
                                            onChange={handleChange}
                                            error={touched.alsoNotify && !!errors.alsoNotify}
                                            helperText={touched.alsoNotify && errors.alsoNotify}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Ocean Vessel Voy No.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Ocean Vessel Voy No."
                                            name="oceanVesselVoyNo"
                                            required={true}
                                            value={values.oceanVesselVoyNo}
                                            onChange={handleChange}
                                            error={touched.oceanVesselVoyNo && !!errors.oceanVesselVoyNo}
                                            helperText={touched.oceanVesselVoyNo && errors.oceanVesselVoyNo}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>

                                                Port of Loading
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Port of Loading"
                                            name="portOfLoading"
                                            required={true}
                                            value={values.portOfLoading}
                                            onChange={handleChange}
                                            error={touched.portOfLoading && !!errors.portOfLoading}
                                            helperText={touched.portOfLoading && errors.portOfLoading}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Excess Value Declaration
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Excess Value Declaration"
                                            name="excessValueDeclaration"
                                            required={true}
                                            value={values.excessValueDeclaration}
                                            onChange={handleChange}
                                            error={touched.excessValueDeclaration && !!errors.excessValueDeclaration}
                                            helperText={touched.excessValueDeclaration && errors.excessValueDeclaration}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Port Of Destination
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Port of Destination"
                                            name="portOfDestination"
                                            required={true}
                                            value={values.portOfDestination}
                                            onChange={handleChange}
                                            error={touched.portOfDestination && !!errors.portOfDestination}
                                            helperText={touched.portOfDestination && errors.portOfDestination}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Final Destination
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Final Destination"
                                            name="finalDestination"
                                            required={true}
                                            value={values.finalDestination}
                                            onChange={handleChange}
                                            error={touched.finalDestination && !!errors.finalDestination}
                                            helperText={touched.finalDestination && errors.finalDestination}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Freight Payable at
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Freight Payable At"
                                            name="freightPayableAt"
                                            required={true}
                                            value={values.freightPayableAt}
                                            onChange={handleChange}
                                            error={touched.freightPayableAt && !!errors.freightPayableAt}
                                            helperText={touched.freightPayableAt && errors.freightPayableAt}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                No.of originals
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextField
                                            placeholder="Enter Number of Originals"
                                            name="noOfOriginals"
                                            required={true}
                                            type="number"
                                            value={values.noOfOriginals}
                                            onChange={handleChange}
                                            error={touched.noOfOriginals && !!errors.noOfOriginals}
                                            helperText={touched.noOfOriginals && errors.noOfOriginals}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Marks
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            placeholder="Enter Marks"
                                            name="marks"
                                            value={values.marks}
                                            onChange={handleChange}
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                No. Of PKGS
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="noOfPkgs"
                                            required={true}
                                            type="number"
                                            value={values.noOfPkgs}
                                            onChange={handleChange}
                                            error={touched.noOfPkgs && !!errors.noOfPkgs}
                                            helperText={touched.noOfPkgs && errors.noOfPkgs}
                                            placeholder="Enter No. Of PKGS"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Description of Goods
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="descriptionOfGoods"
                                            required={true}
                                            value={values.descriptionOfGoods}
                                            onChange={handleChange}
                                            error={touched.descriptionOfGoods && !!errors.descriptionOfGoods}
                                            helperText={touched.descriptionOfGoods && errors.descriptionOfGoods}
                                            placeholder="Enter Description of Goods"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Gross Weight
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="grossWeight"
                                            required={true}
                                            type="number"
                                            value={values.grossWeight}
                                            onChange={handleChange}
                                            error={touched.grossWeight && !!errors.grossWeight}
                                            helperText={touched.grossWeight && errors.grossWeight}
                                            placeholder="Enter Gross Weight"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={3} md={2} lg={2}>
                                <Card variant='outlined' >
                                    <Box display="flex" flexDirection="row" paddingLeft={1} style={{ height: '30px', backgroundColor: "#e7f0f7" }}>
                                        <Box >
                                            <Typography style={{ fontWeight: 500, color: "#212529", fontSize: "16px" }}>
                                                Measurement
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Typography style={{ padding: "2px" }}>
                                        <CustomTextarea
                                            name="measurement"
                                            required={true}
                                            type="number"
                                            value={values.measurement}
                                            onChange={handleChange}
                                            error={touched.measurement && !!errors.measurement}
                                            helperText={touched.measurement && errors.measurement}
                                            placeholder="Enter Measurement"
                                            sx={{ height: '250px !important' }}
                                        />
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>


                        <FieldArray
                            name="particulars"
                            render={arrayHelpers => (
                                <>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <AssignmentLateOutlinedIcon />
                                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                                    Particulars
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Box style={{ overflowX: 'auto', overflowY: 'auto' }}>
                                            <Table style={{ width: "900px" }}>
                                                <TableHead>
                                                    <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>No.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Seal No.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Type</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>No. of PKGS</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Gross Weight</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Measurement</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {values.particulars.map((particular, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell style={styles.tablecell}>{index + 1}</TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="Seal No."
                                                                    name={`particulars[${index}].sealNo`}
                                                                    value={particular.sealNo}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.sealNo && !!errors.particulars?.[index]?.sealNo}
                                                                    helperText={touched.particulars?.[index]?.sealNo && errors.particulars?.[index]?.sealNo}
                                                                />

                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="Type"
                                                                    name={`particulars[${index}].type`}
                                                                    value={particular.type}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.type && !!errors.particulars?.[index]?.type}
                                                                    helperText={touched.particulars?.[index]?.type && errors.particulars?.[index]?.type}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="No. of Packages"
                                                                    name={`particulars[${index}].noOfPkgs`}
                                                                    type="number"
                                                                    value={particular.noOfPkgs}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.noOfPkgs && !!errors.particulars?.[index]?.noOfPkgs}
                                                                    helperText={touched.particulars?.[index]?.noOfPkgs && errors.particulars?.[index]?.noOfPkgs}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="Gross Weight"
                                                                    name={`particulars[${index}].grossWeight`}
                                                                    type="number"
                                                                    value={particular.grossWeight}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.grossWeight && !!errors.particulars?.[index]?.grossWeight}
                                                                    helperText={touched.particulars?.[index]?.grossWeight && errors.particulars?.[index]?.grossWeight}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <CustomTextField
                                                                    placeholder="Measurement"
                                                                    name={`particulars[${index}].measurement`}
                                                                    type="number"
                                                                    value={particular.measurement}
                                                                    onChange={handleChange}
                                                                    error={touched.particulars?.[index]?.measurement && !!errors.particulars?.[index]?.measurement}
                                                                    helperText={touched.particulars?.[index]?.measurement && errors.particulars?.[index]?.measurement}
                                                                />
                                                            </TableCell>
                                                            <TableCell style={styles.tablecell}>
                                                                <IconButton onClick={() => arrayHelpers.remove(index)} color="error">
                                                                    <DeleteRounded />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>

                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecell} colSpan={7}>
                                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                                            <CustomButton
                                                                sx={Additemstyle}
                                                                startIcon={<AddRounded />}
                                                                title="Add Item"
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => arrayHelpers.push({
                                                                    particularName: '',
                                                                    qty: '',
                                                                    rate: '',
                                                                    unit: '',
                                                                    description: ''
                                                                })}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        />
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateBLDraft;

const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },

    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: '8px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
        cursor: 'pointer',
    },
    expandIcon: {
        color: '#212529',
    },
    boxheading: {
        width: 70,
        height: 70,
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 14,
        boxShadow: 3,
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
    avatarcircle: {
        backgroundColor: '#007BFF',
        width: '30px',
        height: '30px',
        fontSize: '15px',
    },
    cardtittile: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: 'capitalize',
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    tablecellfooter: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'right',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },

};


const Additemstyle = {
    color: '#212529 !important',
    textTransform: 'capitalize !important',
    borderRadius: '4px !important',
    border: '1px dashed #aecce4 !important',
    height: '30px !important',
    display: 'inline-flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    backgroundColor: '#e7f0f7 !important',
    width: '100% !important',
};

