import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from '../common/CustomAutoComplete';
import CustomTextarea from "../common/CustomTextarea";
import { genericPost } from '../../services/authServices';
import { Create_contact } from '../../services/apiRoutes';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import { getStates, getCities } from "../../services/CommonServices";


const ValidationSchema = Yup.object().shape({
    contactAdminId: Yup.number().required('Contact Admin ID is required'),
    leadSourceId: Yup.number().required('Lead Source ID is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    selectCompanyNameId: Yup.number().required('Company Name ID is required'),
    title: Yup.string().required('Title is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    departmentId: Yup.number().required('Department ID is required'),
    mobile: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile is required'),
    otherPhone: Yup.string()
        .matches(/^[0-9]{10}$/, 'otherPhone number must be exactly 10 digits')
        .required('otherPhone is required'),
    phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'phone number must be exactly 10 digits')
        .required('phone is required'),
    asstPhone: Yup.string()
        .matches(/^[0-9]{10}$/, 'asstPhone number must be exactly 10 digits')
        .required('asstPhone is required'),
    assistant: Yup.string().required('Assistant name is required'),
    dateOfBirth: Yup.date().required('Date of Birth is required').nullable(),
    businessGroupId: Yup.number().required('Business Group ID is required'),
    emailOptOutId: Yup.number().required('Email Opt Out ID is required'),
    skypeId: Yup.string().required('required'),
    secondaryEmail: Yup.string().email('Invalid secondary email format'),
    reportingTo: Yup.number().required('Reporting To ID is required'),
    mailingStreet: Yup.string().required('Mailing Street is required'),
    millingCountryId: Yup.number().required('Milling Country ID is required'),
    millingStateId: Yup.number().required('Milling State ID is required'),
    millingCityId: Yup.number().required('Milling City ID is required'),
    millingPincode: Yup.string().matches(/^\d{6}$/, 'Invalid pincode').required('Milling Pincode is required'),
    othersStreet: Yup.string().required('required'),
    otherCountryId: Yup.number().required('required'),
    otherStateId: Yup.number().required('required'),
    otherCityId: Yup.number().required('required'),
    otherPincode: Yup.string().matches(/^\d{6}$/, 'Invalid pincode').required('required'),
    description: Yup.string().required('Description is required'),
});

const CreateContact = ({ handleClose, companies, rating, leadSource, businessGroups, conutries, contactDepartments, setMessage, fetchContacts, owner, clientCompany, }) => {



    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);


    // Define state variables for other countries, states, and cities
    const [otherStates, setOtherStates] = useState([]);
    const [otherCities, setOtherCities] = useState([]);

    const handleSubmit = async (values, { setSubmitting }) => {

        try {
            const response = await genericPost(Create_contact, values, {
            });

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Create_contact added successfully",
                    type: "success",
                });
                fetchContacts()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    // Define the handlers for country and state changes
    const handleChangeMillingCountry = async (newValue) => {
        const countryId = newValue ? newValue.id : '';
        if (countryId) {
            const stateData = await getStates({ id: countryId });
            setStates(stateData); // Set milling states
            setCities([]); // Clear milling cities when a new country is selected
        } else {
            setStates([]);
            setCities([]);
        }
    };

    const handleChangeOtherCountry = async (newValue) => {
        const countryId = newValue ? newValue.id : '';
        if (countryId) {
            const stateData = await getStates({ id: countryId });
            setOtherStates(stateData); // Set other states
            setOtherCities([]); // Clear other cities when a new country is selected
        } else {
            setOtherStates([]);
            setOtherCities([]);
        }
    };

    const handleChangeMillingState = async (newValue) => {
        const stateId = newValue ? newValue.id : '';
        if (stateId) {
            const cityData = await getCities({ id: stateId });
            setCities(cityData); // Set milling cities
        } else {
            setCities([]);
        }
    };

    const handleChangeOtherState = async (newValue) => {
        const stateId = newValue ? newValue.id : '';
        if (stateId) {
            const cityData = await getCities({ id: stateId });
            setOtherCities(cityData); // Set other cities
        } else {
            setOtherCities([]);
        }
    };


    const [formData, setFormData] = useState({
        contactAdminId: '',
        leadSourceId: '',
        firstName: '',
        lastName: '',
        selectCompanyNameId: '',
        title: '',
        email: '',
        departmentId: '',
        mobile: '',
        otherPhone: '',
        phone: '',
        asstPhone: '',
        assistant: '',
        dateOfBirth: "",
        businessGroupId: '',
        emailOptOutId: '',
        skypeId: '',
        secondaryEmail: '',
        reportingTo: '',
        mailingStreet: '',
        millingCountryId: '',
        millingStateId: '',
        millingCityId: '',
        millingPincode: '',
        othersStreet: '',
        otherCountryId: '',
        otherStateId: '',
        otherCityId: '',
        otherPincode: '',
        description: '',
    });



    useEffect(() => {
        setFormData({
            contactAdminId: '',
            leadSourceId: '',
            firstName: '',
            lastName: '',
            selectCompanyNameId: '',
            title: '',
            email: '',
            departmentId: '',
            mobile: '',
            otherPhone: '',
            phone: '',
            asstPhone: '',
            assistant: '',
            dateOfBirth: "",
            businessGroupId: '',
            emailOptOutId: '',
            skypeId: '',
            secondaryEmail: '',
            reportingTo: '',
            mailingStreet: '',
            millingCountryId: '',
            millingStateId: '',
            millingCityId: '',
            millingPincode: '',
            othersStreet: '',
            otherCountryId: '',
            otherStateId: '',
            otherCityId: '',
            otherPincode: '',
            description: '',
        })
    }, [])

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} sx={{ position: "sticky" }}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600, color: "#212529", fontSize: "18px" }}>
                                    Create Contacts
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box sx={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveRounded />}
                                        title="Save"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title="Save and New"
                                        size="small"
                                        variant="contained"
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={owner}
                                    fieldLabel="Contact Admin"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={owner.find((option) => option.id === values.contactAdminId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('contactAdminId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Admin"

                                />
                                {touched.contactAdminId && errors.contactAdminId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.contactAdminId}</p> : null}

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={leadSource}
                                    fieldLabel="Lead Source"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={leadSource.find((option) => option.id === values.leadSourceId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('leadSourceId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Lead Source"

                                />
                                {touched.leadSourceId && errors.leadSourceId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.leadSourceId}</p> : null}

                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="firstName"
                                    fieldLabel="First Name"
                                    required={false}
                                    placeholder="Enter First Name"
                                    value={values.firstName}
                                    onChange={handleChange}
                                />
                                {touched.firstName && errors.firstName ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.firstName}</p> : null}

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="lastName"
                                    fieldLabel="Last Name"
                                    required={false}
                                    placeholder="Enter Last Name"
                                    value={values.lastName}
                                    onChange={handleChange}
                                />
                                {touched.lastName && errors.lastName ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.lastName}</p> : null}

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={clientCompany}
                                    fieldLabel="Select Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={clientCompany.find((option) => option.id === values.selectCompanyNameId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('selectCompanyNameId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Company"

                                />
                                {touched.selectCompanyNameId && errors.selectCompanyNameId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.selectCompanyNameId}</p> : null}

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="title"
                                    fieldLabel="Title"
                                    required
                                    value={values.title}
                                    onChange={handleChange}
                                    placeholder="Enter Title"
                                    error={touched.title && !!errors.title}
                                    helperText={touched.title && errors.title}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="email"
                                    fieldLabel="Email"
                                    required={false}
                                    placeholder="Enter Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    error={touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={contactDepartments}
                                    fieldLabel="Department"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={contactDepartments.find((option) => option.id === values.departmentId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('departmentId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Department"

                                />
                                {touched.departmentId && errors.departmentId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.departmentId}</p> : null}

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="number"
                                    name="mobile"
                                    fieldLabel="Mobile"
                                    required={false}
                                    placeholder="Enter Mobile"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    error={touched.mobile && !!errors.mobile}
                                    helperText={touched.mobile && errors.mobile}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="number"
                                    name="otherPhone"
                                    fieldLabel="Other Phone"
                                    required={false}
                                    placeholder="Enter Other Phone"
                                    value={values.otherPhone}
                                    onChange={handleChange}
                                    error={touched.otherPhone && !!errors.otherPhone}
                                    helperText={touched.otherPhone && errors.otherPhone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="number"
                                    name="phone"
                                    fieldLabel="Phone"
                                    required={false}
                                    placeholder="Enter Phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    error={touched.phone && !!errors.phone}
                                    helperText={touched.phone && errors.phone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="number"
                                    name="asstPhone"
                                    fieldLabel="Asst Phone"
                                    required={false}
                                    placeholder="Enter Asst Phone"
                                    value={values.asstPhone}
                                    onChange={handleChange}
                                    error={touched.asstPhone && !!errors.asstPhone}
                                    helperText={touched.asstPhone && errors.asstPhone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="assistant"
                                    fieldLabel="Assistant"
                                    required={false}
                                    placeholder="Enter Assistant"
                                    value={values.assistant}
                                    onChange={handleChange}
                                    error={touched.assistant && !!errors.assistant}
                                    helperText={touched.assistant && errors.assistant}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="date"
                                    name="dateOfBirth"
                                    fieldLabel="Date Of Birth"
                                    required={false}
                                    placeholder="Enter Date Of Birth"
                                    value={values.dateOfBirth}
                                    onChange={handleChange}
                                    error={touched.dateOfBirth && !!errors.dateOfBirth}
                                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={businessGroups}
                                    fieldLabel="Business Group"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={businessGroups.find((option) => option.id === values.businessGroupId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('businessGroupId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Business Group"
                                    error={touched.businessGroupId && !!errors.businessGroupId}
                                    helperText={touched.businessGroupId && errors.businessGroupId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="emailOptOutId"
                                    fieldLabel="Email Opt Out"
                                    required={false}
                                    placeholder="Enter Email Opt Out"
                                    value={values.emailOptOutId}
                                    onChange={handleChange}
                                    error={touched.emailOptOutId && !!errors.emailOptOutId}
                                    helperText={touched.emailOptOutId && errors.emailOptOutId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="skypeId"
                                    fieldLabel="Skype Id"
                                    required={false}
                                    placeholder="Enter Skype Id"
                                    value={values.skypeId}
                                    onChange={handleChange}
                                    error={touched.skypeId && !!errors.skypeId}
                                    helperText={touched.skypeId && errors.skypeId}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="secondaryEmail"
                                    fieldLabel="Secondary Email"
                                    required={false}
                                    placeholder="Enter Secondary Email"
                                    value={values.secondaryEmail}
                                    onChange={handleChange}
                                    error={touched.secondaryEmail && !!errors.secondaryEmail}
                                    helperText={touched.secondaryEmail && errors.secondaryEmail}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="reportingTo"
                                    fieldLabel="Reporting To"
                                    required={false}
                                    placeholder="Enter Reporting To"
                                    value={values.reportingTo}
                                    onChange={handleChange}
                                    error={touched.reportingTo && !!errors.reportingTo}
                                    helperText={touched.reportingTo && errors.reportingTo}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} style={{ paddingBottom: "5px" }}>
                                    <Grid item xs={12} md={8}>
                                        <Typography style={{ color: "#212529", fontWeight: 500, fontSize: "20px" }}>
                                            Address Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <CustomAutocomplete
                                            options={top100Films}
                                            required={false}
                                            getOptionLabel={(option) => option.title}
                                            placeholder="Copy Address"
                                        />
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="mailingStreet"
                                    type="text"
                                    fieldLabel="Mailing Street"
                                    placeholder="Enter Mailing Street"
                                    value={values.mailingStreet}
                                    onChange={handleChange}
                                    error={touched.mailingStreet && !!errors.mailingStreet}
                                    helperText={touched.mailingStreet && errors.mailingStreet}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="othersStreet"
                                    type="text"
                                    fieldLabel="Others Street"
                                    placeholder="Enter Other Street"
                                    value={values.othersStreet}
                                    onChange={handleChange}
                                    error={touched.othersStreet && !!errors.othersStreet}
                                    helperText={touched.othersStreet && errors.othersStreet}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={conutries}
                                    fieldLabel="Milling Country"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={conutries.find((option) => option.id === values.millingCountryId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('millingCountryId', newValue ? newValue.id : '');
                                        handleChangeMillingCountry(newValue);
                                    }}
                                    placeholder="Select Milling Country"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={conutries}
                                    fieldLabel="Other Country"
                                    required={false}
                                    getOptionLabel={(option) => option.title}
                                    value={conutries.find((option) => option.id === values.otherCountryId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('otherCountryId', newValue ? newValue.id : '');
                                        handleChangeOtherCountry(newValue);
                                    }}
                                    placeholder="Select Other Country"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={states}
                                    fieldLabel="Milling State"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={states.find((option) => option.id === values.millingStateId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('millingStateId', newValue ? newValue.id : '');
                                        handleChangeMillingState(newValue);
                                    }}
                                    placeholder="Select Milling State"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={otherStates}
                                    fieldLabel="Other State"
                                    required={false}
                                    getOptionLabel={(option) => option.title}
                                    value={otherStates.find((option) => option.id === values.otherStateId) || null}
                                    onChange={(e, newValue) => {
                                        setFieldValue('otherStateId', newValue ? newValue.id : '');
                                        handleChangeOtherState(newValue);
                                    }}
                                    placeholder="Select Other State"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={cities}
                                    fieldLabel="Milling City"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={cities.find((option) => option.id === values.millingCityId) || null}
                                    onChange={(e, newValue) => setFieldValue('millingCityId', newValue ? newValue.id : '')}
                                    placeholder="Select Milling City"
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <CustomAutocomplete
                                    options={otherCities}
                                    fieldLabel="Other City"
                                    required={false}
                                    getOptionLabel={(option) => option.title}
                                    value={otherCities.find((option) => option.id === values.otherCityId) || null}
                                    onChange={(e, newValue) => setFieldValue('otherCityId', newValue ? newValue.id : '')}
                                    placeholder="Select Other City"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="millingPincode"
                                    type="number"
                                    fieldLabel="Milling Pincode"
                                    placeholder="Enter Milling Pincode"
                                    value={values.millingPincode}
                                    onChange={handleChange}
                                    error={touched.millingPincode && !!errors.millingPincode}
                                    helperText={touched.millingPincode && errors.millingPincode}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="otherPincode"
                                    type="number"
                                    fieldLabel="Other Pincode"
                                    placeholder="Enter Other Pincode"
                                    value={values.otherPincode}
                                    onChange={handleChange}
                                    error={touched.otherPincode && !!errors.otherPincode}
                                    helperText={touched.otherPincode && errors.otherPincode}
                                />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <CustomTextarea
                                    name="description"
                                    fieldLabel="Description"
                                    multiline
                                    rows={4}
                                    required={true}
                                    value={values.description}
                                    onChange={handleChange}
                                    placeholder="Enter Description"
                                />
                                {touched.description && errors.description ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.description}</p> : null}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateContact;


const top100Films = [
    { title: "Mailing ", id: 1 },
    { title: "Others ", id: 2 },
];
