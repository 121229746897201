import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Popover,
  TextField,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  useMediaQuery,
  useTheme,
  Select,
  FormControl,
} from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import ContactsIcon from "@mui/icons-material/Contacts";
import BusinessIcon from "@mui/icons-material/Business";
import BarChartIcon from "@mui/icons-material/BarChart";
import Logo from "../assets/images/logo.png";
import styles from '../assets/css/Header.module.css'; // Import the CSS module
import { Search } from '@mui/icons-material'
import Userprofile from '../Pages/userprofile'

import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

const Headers = ({ styles }) => {

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchFocused, setSearchFocused] = useState(false);
  const { t, i18n } = useTranslation();
  let navigate = useNavigate()
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handlePopoverOpen = (event) => setPopoverAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setPopoverAnchorEl(null);

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const openPopover = Boolean(popoverAnchorEl);
  const popoverId = openPopover ? "add-popover" : undefined;


  const menuItems = [
    { text: t("leads"), icon: <LeaderboardOutlinedIcon />, Link: '/app/leads' },
    { text: t("contacts"), icon: <ConnectWithoutContactOutlinedIcon />, Link: '/app/contacts' },
    { text: t("company"), icon: <BusinessOutlinedIcon />, Link: '/app/company' },
    { text: t("importShipment"), icon: <DirectionsBoatFilledOutlinedIcon />, Link: '/app/import/shipement' },
    { text: t("exportShipment"), icon: <LocalShippingOutlinedIcon />, Link: '/app/export/shipement' },
    { text: t("reports"), icon: <ReportOutlinedIcon />, Link: '/app/reports' },
  ];

  const menuItemsdropdown = [
    { text: t("leads"), icon: <LeaderboardOutlinedIcon />, Link: '/app/leads' },
    { text: t("contacts"), icon: <ConnectWithoutContactOutlinedIcon />, Link: '/app/contacts' },
    { text: t("company"), icon: <BusinessOutlinedIcon />, Link: '/app/company' },
    { text: t("importShipment"), icon: <DirectionsBoatFilledOutlinedIcon />, Link: '/app/import/shipement' },
    { text: t("exportShipment"), icon: <LocalShippingOutlinedIcon />, Link: '/app/export/shipement' },
    { text: t("Call"), icon: <ContactsIcon />, Link: '' },
    { text: t("Task"), icon: <ReportOutlinedIcon />, Link: '' },
  ];

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };


  return (
    <div>
      <AppBar
        position="fixed"
        className={styles.appBarRoot} color="inherit"
        sx={{
          height: "45px"
        }}
      >
        <Toolbar className={styles.toolbar}>

          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", paddingBottom: "20px" }} >
            <Typography variant="h6" component="div">
              <Link to={'/app/dashboard'}> <img src={Logo} alt="Logo" className={styles.logo} style={{ width: "103px", height: "22px" }} /> </Link>
            </Typography>
            {!isMobile && (
              <Box className={styles.menuContainer}
                sx={{
                  paddingLeft: "5%"
                }}
              >
                {menuItems.map((item) => (
                  <Typography
                    variant="body1"
                    component="p"
                    key={item.text}
                    onClick={() => navigate(item?.Link)}
                    className={styles.menuItem}
                    style={{
                      textDecoration: 'none',
                      // color: isActive(item.to) ? '#0086c9' : '#333',
                      fontFamily: 'Poppins',
                      fontWeight: 600,
                      fontSize: '14px',
                      lineHeight: '1.5',
                      // borderBottom: isActive(item.to) ? '3px solid #0086c9' : 'none',

                    }}
                  >
                    {item.text}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontFamily: theme.typography.fontFamily,
              fontSize: theme.typography.fontSize,
              paddingBottom: "20px"
            }}
          >
            {searchFocused ? (
              <TextField
                variant="outlined"
                placeholder={t("searchPlaceholder")}
                size="small"
                className={styles.searchField}
                InputProps={{
                  classes: {
                    input: styles.searchInput,
                    inputLabel: styles.searchLabel,
                    formLabel: styles.searchFormLabel,
                    placeholder: styles.searchPlaceholder,
                  }
                }}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
              />
            ) : (
              <Search onClick={() => setSearchFocused(true)} className={styles.iconButton} />
            )}
            <FormControl variant="outlined" size="small" className={styles.languageSelect} >
              <Select
                native
                onChange={handleLanguageChange}
                defaultValue={i18n.language}
                inputProps={{
                  name: "language",
                  id: "language-selector",
                }}
              >
                <option value="en">English</option>
                <option value="hi">हिंदी</option>
                <option value="zh">中文</option>
              </Select>
            </FormControl>

            {/* <IconButton className={styles.iconButton}>
              <CalendarTodayIcon />
            </IconButton> */}
            <IconButton
              className={styles.iconButton}
              aria-describedby={popoverId}
              onClick={handlePopoverOpen}
            >
              <AddIcon />
            </IconButton>
            <Popover
              id={popoverId}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              {menuItemsdropdown.map((item) => (
                <ListItem
                  key={item.text}
                  onClick={() => navigate(item?.Link)}
                  className={styles.drawerListItem}
                  style={{ padding: "5px", cursor: "pointer" }}>
                  <ListItemIcon style={{ minWidth: "25px" }}>{item.icon} </ListItemIcon>
                  <ListItemText style={{ margin: "0px", paddingRight: "10px" }} primary={item.text} />
                </ListItem>
              ))}
            </Popover>
            <IconButton className={styles.iconButton} onClick={() => navigate('/app/settingmaster')}>
              <SettingsIcon />
            </IconButton>
            <IconButton className={styles.iconButton} onClick={handleDrawerToggle}>
              <AccountCircleIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        {/* <List>
          {menuItems.map((item) => (
            <ListItem button key={item.text} className={styles.drawerListItem}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List> */}
        <Userprofile />
      </Drawer>
    </div>
  );
};

export default Headers;
