import React from 'react';
import { AppBar, Box, Container, IconButton, InputBase, Toolbar, Typography, useMediaQuery, Menu, MenuItem, Avatar, Grid } from '@mui/material';
import { Menu as MenuIcon, Search as SearchIcon } from '@mui/icons-material';
import Logo from '../../assets/images/HRVERSE_logo.svg';
import { Card, CardContent, CardActions, } from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Link } from 'react-router-dom';
import BannerSearch from './BannerSearch';




const HelpPage = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <BannerSearch />
            <Container>

                <Typography variant="h1" sx={{ fontWeight: 'bold', color: '#007BFF', marginBottom: 2 }}>
                    Advice and answers from the Exim Team
                </Typography>
                <Grid container spacing={2}>
                    {cardData.map((card, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <Card sx={cardStyles}>
                                <Box>
                                    <QuestionAnswerIcon sx={cardIconStyles} style={{ fontSize: "50px" }} />
                                </Box>
                                <Box sx={cardHeaderStyles}>
                                    <Link to={card.link}
                                        style={{ textDecoration: 'none', color: "rgba(0, 0, 0, 0.87)" }}
                                    >
                                        <Typography sx={cardTitleStyles}>
                                            {card.title}
                                        </Typography>
                                    </Link>
                                </Box>
                                <CardContent sx={cardContentStyles}>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {card.content}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={cardButtonStyles}>
                                    <Link href="/articles" variant="body2" component="p" sx={{ pb: 2, textDecoration: 'none' }}>
                                        {card.articles} Articles
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <br />
        </>
    );
};

export default HelpPage;

const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 2,
    boxShadow: 3,
    maxWidth: 445,

};

const cardHeaderStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
};

const cardIconStyles = {
    mr: 1,
};

const cardTitleStyles = {
    fontWeight: 'bold',
    fontSize: 15,
    transition: 'color 0.3s',
    '&:hover': {
        color: '#007BFF',
    },

};

const cardContentStyles = {
    textAlign: 'center',
};

const cardButtonStyles = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
};


const cardData = [
    {
        "title": "How to use Exim - Video Tips & Guides",
        "content": "Read and download useful Import / Export information.",
        "articles": 40,
        "link": "/helpcenteruse"
    },
    {
        "title": "How to Create Documents - Video Guides",
        "content": "Watch Videos on how to create shipping documents in IncoDocs.",
        "articles": 14,
        "link": "/helpcenteruse"

    },
    {
        "title": "Landed Cost Calculator",
        "content": "Learn how to use the IncoDocs Landed Cost Calculator.",
        "articles": 1,
        "link": "/helpcenteruse"

    },
    {
        "title": "Industry Resources and Downloads",
        "content": "Read industry information and downloads.",
        "articles": 10,
        "link": "/helpcenteruse"

    },
    {
        "title": "Leave Your Feedback",
        "content": "We are always open to feedback, let us know what you think!",
        "articles": 1,
        "link": "/helpcenteruse"

    },
    {
        "title": "Request a New Document",
        "content": "Didn't find the document in IncoDocs? Tell us what you need!",
        "articles": 1,
        "link": "/helpcenteruse"

    }
];
