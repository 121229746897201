import React from "react";
import { Box, Grid } from "@mui/material";
import { Card, Typography, Divider, CircularProgress } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import {
    ClearRounded,
    SaveRounded,
} from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { genericPost } from "../../../services/authServices";
import { Create_task } from "../../../services/apiRoutes";

const taskValidationSchema = Yup.object().shape({
    taskOwnerId: Yup.number().required('Task Owner ID is required'),
    assignToId: Yup.number().required('Assign To ID is required'),
    contactId: Yup.number().required('Contact ID is required'),
    clientCompanyId: Yup.number().required('Client Company ID is required'),
    moduleId: Yup.number().required('Module ID is required'),
    moduleModelId: Yup.number().required('Module Model ID is required'),
    leadId: Yup.number().required('Lead ID is required'),
    taskName: Yup.string().required('Task Name is required'),
    subject: Yup.string().required('Subject is required'),
    dueDate: Yup.date().required('Due Date is required'),
    taskStatusId: Yup.number().required('Task Status ID is required'),
    priorityId: Yup.number().required('Priority ID is required'),
    description: Yup.string().required('Description is required'),
});

const CreateTasks = ({
    handleClose,
    fetchTaskData,
    setMessage,
    leadSource,
    companies,
    modules,
    taskStatuses,
    priorities,
}) => {

    const initialValues = {
        taskOwnerId: '',
        assignToId: '',
        contactId: '',
        clientCompanyId: '',
        moduleId: '',
        moduleModelId: '',
        leadId: '',
        taskName: '',
        subject: '',
        dueDate: '',
        taskStatusId: '',
        priorityId: '',
        description: '',
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await genericPost(Create_task, values);
            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "call added successfully",
                    type: "success",
                });
                fetchTaskData()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };


    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={initialValues}
                validationSchema={taskValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} sx={{ position: "sticky" }}>
                            <Grid item xs={4} md={2}>
                                <Typography sx={{ fontWeight: 600, color: "#212529", fontSize: "18px" }}>
                                    Create Task
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={10} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box sx={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : <SaveRounded />}
                                        title="Save"
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "5%" }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="taskOwnerId"
                                    required={true}
                                    fieldLabel="Task Owner"
                                    placeholder="Select"
                                    options={taskOwners}
                                    value={values.taskOwnerId}
                                    onChange={(event, newValue) => setFieldValue("taskOwnerId", newValue?.id || '')}
                                    error={touched.taskOwnerId && !!errors.taskOwnerId}
                                    helperText={touched.taskOwnerId && errors.taskOwnerId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="assignToId"
                                    fieldLabel="Assign To"
                                    required={true}
                                    options={assignees}
                                    placeholder="Select"
                                    value={values.assignToId}
                                    onChange={(event, newValue) => setFieldValue("assignToId", newValue?.id || '')}
                                    error={touched.assignToId && !!errors.assignToId}
                                    helperText={touched.assignToId && errors.assignToId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    name="contactId"
                                    fieldLabel="Select Contact Person"
                                    required={true}
                                    options={contacts}
                                    placeholder="Select"
                                    value={values.contactId}
                                    onChange={(event, newValue) => setFieldValue("contactId", newValue?.id || '')}
                                    error={touched.contactId && !!errors.contactId}
                                    helperText={touched.contactId && errors.contactId}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="taskName"
                                    fieldLabel="Task"
                                    required={true}
                                    value={values.taskName}
                                    onChange={handleChange}
                                    error={touched.taskName && !!errors.taskName}
                                    helperText={touched.taskName && errors.taskName}
                                    placeholder="Enter Task"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomTextField
                                    name="subject"
                                    fieldLabel="Subject"
                                    required
                                    value={values.subject}
                                    onChange={handleChange}
                                    error={touched.subject && !!errors.subject}
                                    helperText={touched.subject && errors.subject}
                                    placeholder="Enter Subject"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="dueDate"
                                    fieldLabel="Due Date"
                                    type="datetime-local"
                                    required
                                    value={values.dueDate}
                                    onChange={handleChange}
                                    error={touched.dueDate && !!errors.dueDate}
                                    helperText={touched.dueDate && errors.dueDate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CustomAutocomplete
                                    options={taskStatuses}
                                    fieldLabel="Status"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={taskStatuses.find((option) => option.id === values.taskStatusId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('taskStatusId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Status"
                                />
                                {touched.taskStatusId && errors.taskStatusId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.taskStatusId}</p> : null}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={priorities}
                                    fieldLabel="Priority"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={priorities.find((option) => option.id === values.priorityId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('priorityId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select"
                                />
                                {touched.priorityId && errors.priorityId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.priorityId}</p> : null}
                            </Grid>
                            <Grid item xs={8}>
                                <CustomTextarea
                                    sx={{ height: "100px !important" }}
                                    name="description"
                                    fieldLabel="Description"
                                    required
                                    value={values.description}
                                    onChange={handleChange}
                                    error={touched.description && !!errors.description}
                                    helperText={touched.description && errors.description}
                                    placeholder="Enter Description"
                                    rows={3}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                                {/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ */}
                                -------------------------------Not confirm Feild---------------------------
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={leadSource}
                                    fieldLabel="Lead ID"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={leadSource.find((option) => option.id === values.leadId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('leadId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Lead ID"

                                />
                                {touched.leadId && errors.leadId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.leadId}</p> : null}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={companies}
                                    fieldLabel="Client Company"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={companies.find((option) => option.id === values.clientCompanyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Client Company"
                                />
                                {touched.clientCompanyId && errors.clientCompanyId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.clientCompanyId}</p> : null}

                            </Grid>
                            <Grid item xs={12} md={6}>

                                <CustomAutocomplete
                                    options={modules}
                                    fieldLabel="Module"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={modules.find((option) => option.id === values.moduleId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('moduleId', newValue ? newValue.id : '')
                                    }
                                />
                                {touched.moduleId && errors.moduleId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.moduleId}</p> : null}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={modules}
                                    fieldLabel="Module"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={modules.find((option) => option.id === values.moduleModelId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('moduleModelId', newValue ? newValue.id : '')
                                    }
                                />
                                {touched.moduleModelId && errors.moduleModelId ? <p style={{ color: "#D32F2F", fontSize: "12px" }}>{errors.moduleModelId}</p> : null}
                            </Grid>

                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateTasks;

const taskOwners = [
    { id: 1, label: 'Owner 1' },
    { id: 2, label: 'Owner 2' },
    // Add more task owners here
];

const assignees = [
    { id: 1, label: 'Assignee 1' },
    { id: 2, label: 'Assignee 2' },
    // Add more assignees here
];

const contacts = [
    { id: 1, label: 'Contact 1' },
    { id: 2, label: 'Contact 2' },
    // Add more contacts here
];



const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
];

