// CompanyDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailPageCustum from '../../common/DetailsPage/DetailPageCustum';
import { GET_clientCompany_Id } from '../../../services/apiRoutes';

const CompanyDetail = () => {
    const { clientCompanyId } = useParams();
    const [companyDetails, setCompanyDetails] = useState(null);

    useEffect(() => {
        const authToken = sessionStorage.getItem('accessToken');

        axios.get(GET_clientCompany_Id(clientCompanyId), {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        })
            .then(response => {
                setCompanyDetails(response.data.clientCompany);
            })
            .catch(error => {
                console.error('Error fetching company details:', error);
            });
    }, [clientCompanyId]);

    if (!companyDetails) {
        return <p>Loading...</p>;
    }

    const tableData = [
        { label: 'Client Company ID', value: companyDetails.clientCompanyId || 'N/A' },
        { label: 'Company ID', value: companyDetails.companyId || 'N/A' },
        { label: 'Owner ID', value: companyDetails.ownerId || 'N/A' },
        { label: 'Rating', value: companyDetails.rating?.ratingName || 'N/A' },
        { label: 'Client Company Group ID', value: companyDetails.clientCompanyGroupId || 'N/A' },
        { label: 'Company Type', value: companyDetails.companyType?.companyTypeName || 'N/A' },
        { label: 'Industry Type', value: companyDetails.industryType?.industryTypeName || 'N/A' },
        { label: 'Ownership Type', value: companyDetails.ownershipType?.ownershipTypeName || 'N/A' },
        { label: 'Company Name', value: companyDetails.clientCompanyName || 'N/A' },
        { label: 'Phone', value: companyDetails.phone || 'N/A' },
        { label: 'Mobile', value: companyDetails.mobile || 'N/A' },
        { label: 'Email', value: companyDetails.email || 'N/A' },
        { label: 'Address', value: companyDetails.address || 'N/A' },
        { label: 'Address Line 1', value: companyDetails.addressLineOne || 'N/A' },
        { label: 'Address Line 2', value: companyDetails.addressLineTwo || 'N/A' },
        { label: 'Country', value: companyDetails.country?.name || 'N/A' },
        { label: 'State', value: companyDetails.state?.name || 'N/A' },
        { label: 'City', value: companyDetails.city?.name || 'N/A' },
        { label: 'Pincode', value: companyDetails.pincode || 'N/A' },
        { label: 'GSTIN', value: companyDetails.gstin || 'N/A' },
        { label: 'Mailing Address Line 1', value: companyDetails.mailingAddressLineOne || 'N/A' },
        { label: 'Mailing Address Line 2', value: companyDetails.mailingAddressLineTwo || 'N/A' },
        { label: 'Mailing State ID', value: companyDetails.mailingStateId || 'N/A' },
        { label: 'Mailing City ID', value: companyDetails.mailingCityId || 'N/A' },
        { label: 'Mailing Country ID', value: companyDetails.mailingCountryId || 'N/A' },
        { label: 'Mailing Pincode', value: companyDetails.mailingPincode || 'N/A' },
        { label: 'Website', value: companyDetails.website || 'N/A' },
        { label: 'Description', value: companyDetails.description || 'N/A' },
        { label: 'Created At', value: companyDetails.createdAt ? new Date(companyDetails.createdAt).toLocaleString() : 'N/A' },
        { label: 'Updated At', value: companyDetails.updatedAt ? new Date(companyDetails.updatedAt).toLocaleString() : 'N/A' }
    ];

    return <DetailPageCustum title="Company Details" data={tableData} />;
};

export default CompanyDetail;
