import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import CustomModal from '../../../components/common/CustomModal';
import CreateExpCommercialInv from '../CreateForm/CreateExpCommercialInv';
import UpdateExpCommercialInv from '../UpdateForm/UpdateExpCommercialInv';
import MessageBox from '../../common/CustomMessageBox';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';
import { LIST_COMMERCIAL_INVOICES_EXPORT } from '../../../services/apiRoutes';
import { genericGet } from '../../../services/apiServices';

const CommercialInvoice = () => {
    const [open, setOpen] = useState(true);
    const [modal, setModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Commercial Invoice');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState({ open: false, text: '', type: '' });
    const [commercialInvExpId, setCommercialInvExpId] = useState('');

    const navigate = useNavigate();
    const { exportShipmentId } = useParams();
    const { shipmentDetails } = useExportShipmentDetails(exportShipmentId);

    // Fetch commercial invoices
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: LIST_COMMERCIAL_INVOICES_EXPORT });
            if (response.data.success && response.data.data) {
                const invoiceData = response.data.data.map((invoice) => ({
                    commercialInvExpId: invoice.commercialInvExpId || '',
                    date: invoice.date || '',
                    invoiceNo: invoice.proformaInvoiceNo || '',
                    customerName: '', // Add logic if available
                    amount: '', // Add logic if available
                    dueDate: invoice.dueDate || '',
                    note: invoice.note || '',
                }));
                setData(invoiceData);
            } else {
                setError('No commercial invoices found');
            }
        } catch (error) {
            setError('Error fetching commercial invoices');
            console.error('Error fetching commercial invoices', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Table columns
    const columns = [
        { id: 'commercialInvExpId', label: 'SN', accessor: 'commercialInvExpId' },
        { id: 'porformNo', label: 'Porform No.', accessor: 'porformNo' },
        { id: 'date', label: 'Date', accessor: 'date' },
        { id: 'shippingId', label: 'Shipping Id', accessor: 'shippingId' },
        { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
        { id: 'amount', label: 'Amount', accessor: 'amount' },
        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.commercialInvExpId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: '#059212' }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.commercialInvExpId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: '#050C9C' }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.commercialInvExpId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: '#E4003A' }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    // Event handlers
    const handleViewClick = (id) => {
        // Logic for viewing details
    };

    const handleUpdateClick = (id) => {
        setCommercialInvExpId(id);
        setUpdateModal(true);
    };

    const handleOpenDeleteAlert = (id) => {
        // Logic for opening a delete alert
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };

    const handleOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
        setCommercialInvExpId('');
    };

    const handleBackClick = () => {
        navigate('/app/export/shipment');
    };

    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }

    const companyName = shipmentDetails.company.companyName;
    const phoneNumber = shipmentDetails.shipmentId;
    const initial = companyName ? companyName.charAt(0).toUpperCase() : '';

    // JSX Structure
    return (
        <div>
            <DetailsPageAppbar
                handleScrollTo={handleScrollTo}
                activeTab={activeTab}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                AppBarMenu={ExportMenu(exportShipmentId)}
                companyName={companyName}
                phoneNumber={phoneNumber}
                initial={initial}
                onBackClick={handleBackClick}
            />

            <Main open={open}>
                <DetailsPageCard
                    columns={columns}
                    title="Commercial Invoice"
                    buttonTitle="Create Commercial Invoice"
                    handleOpen={handleOpen}
                    data={data}
                    loading={loading}
                    error={error}
                />
                <CustomModal open={modal} handleClose={handleClose}>
                    <CreateExpCommercialInv
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleClose} />
                </CustomModal>

                <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                    <UpdateExpCommercialInv
                        fetchData={fetchData}
                        setMessage={setMessage}
                        handleClose={handleCloseUpdate}
                        commercialInvExpId={commercialInvExpId}
                    />
                </CustomModal>

                <MessageBox
                    open={message.open}
                    onClose={() => setMessage({ ...message, open: false })}
                    message={message.text}
                    handleClose={() => setMessage({ ...message, open: false })}
                    type={message.type}
                />
            </Main>
        </div>
    );
};

export default CommercialInvoice;
