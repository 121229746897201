import React, { useEffect, useState } from "react";
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { AppBarCompany, } from '../../../constants/DetailsPage';
import { Main } from '../../../constants/DetailsMain';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MessageBox from "../../common/CustomMessageBox";
import CustomDelete from '../../../components/common/CustomDeleteAlert';
import axios from "axios";
import CustomModal from "../../../components/common/CustomModal";
import { getCIFFOBList, getCurrencyList, getShipmentTypes, getSuppliersList } from '../../../services/CommonServices';
import { genericGet } from "../../../services/apiServices";
import { IconButton } from "@mui/material";
import { DELETE_EXPORT_SHIPMENT, LIST_ALL_EXPORT_SHIPMENT } from "../../../services/apiRoutes";
import Createexportshipement from "../../exportshipement/createexportshipement";
import UpdateExportshipement from "../../exportshipement/UpdateExportshipement";
import { useNavigate, useParams } from "react-router-dom";
import { useClientCompanyDetails } from "./UseClientCompanyDetails";

export default function ExportShipment() {
    const navigate = useNavigate();
    const { clientCompanyId } = useParams();
    const { companyDetail } = useClientCompanyDetails(clientCompanyId);
    const [open, setOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Export Shipment');
    const [leadModal, setLeadModal] = useState(false);
    const [exportShipments, setExportShipments] = useState([]);
    const [cifFobList, setCIFFOBList] = useState([]);
    const [shipmentTypes, setShipmentTypes] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [rowId, setRowId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [message, setMessage] = useState({
        open: false,
        text: '',
        type: ''
    });

    useEffect(() => {
        fetchExportShipments();
        getCommonData();
    }, []);

    const handleUpdateClick = (exportShipmentId) => {
        setSelectedId(exportShipmentId);
        setUpdateModal(true);
    };

    const handleCloseUpdate = () => {
        setUpdateModal(false);
    };

    const handleOpen = () => {
        setLeadModal(true);
    };

    const handleClose = () => {
        setLeadModal(false);
    };

    const handleOpenDeleteAlert = (rowId) => {
        setDeleteAlert(true);
        setRowId(rowId);
    };

    const handleViewClick = (exportShipmentId) => {
        console.log(`View details for exportShipmentId: ${exportShipmentId}`);
    };

    const handleDelete = async (exportShipmentId) => {
        setLoading(true);
        const authToken = sessionStorage.getItem('accessToken');
        try {
            const response = await axios.delete(DELETE_EXPORT_SHIPMENT(exportShipmentId), {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.status === 200) {
                fetchExportShipments();
                setDeleteAlert(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error deleting export shipment", error);
        }
    };

    const fetchExportShipments = async () => {
        setLoading(true);
        try {
            const response = await genericGet({ url: LIST_ALL_EXPORT_SHIPMENT });

            if (response.data.success && response.data.shipments) {
                const shipmentData = response.data.shipments.map((shipment, index) => ({
                    sn: index + 1,
                    exportShipmentId: shipment.exportShipmentId || '',
                    date: shipment.createdAt || '',
                    shipmentId: shipment.shipmentId || '',
                    customerName: shipment.customerId || '',
                    country: shipment.country || '',
                    noOfContainer: shipment.noOfContainers || '',
                    cifFob: shipment.cifFobId || '',
                    currency: shipment.currencyId || '',
                }));
                setExportShipments(shipmentData);
            } else {
                setError('No export shipments found');
            }
        } catch (error) {
            setError('Error fetching export shipments');
            console.error('Error fetching export shipments', error);
        } finally {
            setLoading(false);
        }
    };

    const getCommonData = async () => {
        const cifFobListData = await getCIFFOBList();
        setCIFFOBList(cifFobListData);

        const shipmentTypesData = await getShipmentTypes();
        setShipmentTypes(shipmentTypesData);

        const currencyListData = await getCurrencyList();
        setCurrencyList(currencyListData);
    };

    const exportShipmentColumns = [
        { accessor: "exportShipmentId", label: "SN.", id: "sn" },
        { accessor: "date", label: "Date", id: "date" },
        { accessor: "shipmentId", label: "Shipment ID", id: "shipmentId" },
        { accessor: "customerName", label: "Customer Name", id: "customerName" },
        { accessor: "country", label: "Country", id: "country" },
        { accessor: "noOfContainer", label: "No. Of Containers", id: "noOfContainer" },
        { accessor: "currency", label: "Currency", id: "currency" },
        { accessor: "cifFob", label: "CIF/FOB", id: "cifFob" },

        {
            id: 'action',
            label: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => handleViewClick(row.exportShipmentId)} style={{ marginRight: '1px' }}>
                        <VisibilityIcon style={{ color: "#059212" }} />
                    </IconButton>
                    <IconButton onClick={() => handleUpdateClick(row.exportShipmentId)} style={{ marginRight: '1px' }}>
                        <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteAlert(row.exportShipmentId)}>
                        <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
                    </IconButton>
                </div>
            ),
        },
    ];

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;


    if (!companyDetail) {
        return <p>Loading...</p>;
    }
    const companyName = companyDetail.clientCompanyName;
    const phoneNumber = companyDetail.mobile;
    const initial = companyDetail.clientCompanyName ? companyDetail.clientCompanyName.charAt(0).toUpperCase() : '';
    const handleBackClick = () => {
        navigate("/app/company");
    };

    return (
        <>
            <div>
                <DetailsPageAppbar
                    handleScrollTo={setActiveTab}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={() => setOpen(true)}
                    handleDrawerClose={() => setOpen(false)}
                    AppBarMenu={AppBarCompany(clientCompanyId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}
                />

                <Main open={open}>
                    <DetailsPageCard
                        columns={exportShipmentColumns}
                        title={'Export Shipment'}
                        buttonTitle={'Create Export Shipment'}
                        handleOpen={handleOpen}
                        data={exportShipments}
                    />

                    <CustomModal open={leadModal} handleClose={handleClose}>
                        <Createexportshipement
                            handleClose={handleClose}
                            fetchExportShipments={fetchExportShipments}
                            setMessage={setMessage}
                            cifFobList={cifFobList}
                            shipmentTypes={shipmentTypes}
                            currencyList={currencyList}
                        />
                    </CustomModal>

                    <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
                        <UpdateExportshipement
                            handleClose={handleCloseUpdate}
                            exportShipmentId={selectedId}
                            fetchExportShipments={fetchExportShipments}
                            setMessage={setMessage}
                            cifFobList={cifFobList}
                            shipmentTypes={shipmentTypes}
                            currencyList={currencyList}
                        />
                    </CustomModal>

                    <MessageBox
                        open={message.open}
                        onClose={() => setMessage({ ...message, open: false })}
                        message={message.text}
                        handleClose={() => setMessage({ ...message, open: false })}
                        type={message.type}
                    />

                    <CustomDelete
                        handleDelete={handleDelete}
                        open={deleteAlert}
                        rowId={rowId}
                        handleClose={() => setDeleteAlert(false)}
                    />
                </Main>
            </div>
        </>
    );
}
