import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SecurityIcon from '@mui/icons-material/Security';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ListAltIcon from '@mui/icons-material/ListAlt';
import BalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import StoreIcon from '@mui/icons-material/Store';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import StorageIcon from '@mui/icons-material/Storage';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FactoryIcon from '@mui/icons-material/Factory';

const ContentWrapper = styled('section')(({ theme }) => ({
    padding: theme.spacing(0),
}));

const CustomCard = styled(Card)(({ theme }) => ({
    height: '100%',
    transition: 'transform 0.2s, box-shadow 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[1],
    },
}));

const CardBody = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const Heading = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
}));

const CustomLink = styled(RouterLink)(({ theme }) => ({
    display: 'flex',
    fontWeight: 600,
    fontSize: "12px",
    alignItems: 'center',
    color: theme.palette.text.primary,
    textDecoration: 'none',
    marginTop: theme.spacing(1),
    transition: 'color 0.2s',
    '&:hover': {
        color: '#0652DD',
        textDecoration: 'underline',
    },
}));

const cards = [
    {
        title: 'GENERAL',
        links: [
            { to: '/app/mycompany', icon: <PersonIcon />, text: 'Personal Settings' },
            { to: '/app/mycompany', icon: <BusinessIcon />, text: 'Company Settings' },
        ],
    },
    {
        title: 'USERS & CONTROL',
        links: [
            { to: '/app/companyusers', icon: <SupervisedUserCircleIcon />, text: 'Company Users' },
            { to: '/app/securitycontrols', icon: <SecurityIcon />, text: 'Security Controls' },
            { to: '/app/adminusers', icon: <PersonIcon />, text: 'Admin Users' },
            { to: '/app/usermanagement', icon: <SecurityIcon />, text: 'User Management' },
        ],
    },
    {
        title: 'Master',
        links: [
            { to: '/app/expenseledgergroup', icon: <DescriptionIcon />, text: 'Expense Ledger Group' },
            { to: '/app/currencymaster', icon: <DescriptionIcon />, text: 'Currency Master' },
            { to: '/app/shippinglinemaster', icon: <DescriptionIcon />, text: 'Shipping Line Master' },
            { to: '/app/freightforwardmaster', icon: <DescriptionIcon />, text: 'Freight Forward Master' },
            { to: '/app/customclearingagency', icon: <DescriptionIcon />, text: 'Custom Clearing Agency' },
        ],
    },
    {
        title: 'Products',
        links: [
            { to: '/app/manageproducts', icon: <StoreIcon />, text: 'Manage Products' },
            { to: '/app/unitmaster', icon: <BalanceIcon />, text: 'Unit Master' },
        ],
    },
    {
        title: 'Business Master',
        links: [
            { to: '/app/businessgroup', icon: <DescriptionIcon />, text: 'Business Group' },
            { to: '/app/companygroup', icon: <StoreIcon />, text: 'Company Group' },
            { to: '/app/contactgroup', icon: <DescriptionIcon />, text: 'Contact Group' },
            { to: '/app/companytype', icon: <DescriptionIcon />, text: 'Company Type' },

            { to: '/app/lossreasons', icon: <DescriptionIcon />, text: 'Loss Reasons' },
            { to: '/app/priorities', icon: <DescriptionIcon />, text: 'Priorities' },
            { to: '/app/leadstages', icon: <DescriptionIcon />, text: 'Lead Stages' },

        ],
    },
    {
        title: 'Business Type',
        links: [
            { to: '/app/taskstatuses', icon: <DescriptionIcon />, text: 'Task Statuses' },
            { to: '/app/modules', icon: <DescriptionIcon />, text: 'Modules' },
            { to: '/app/contacttypes', icon: <DescriptionIcon />, text: 'Contact Types' },
            { to: '/app/ratings', icon: <DescriptionIcon />, text: 'Ratings' },
            { to: '/app/leadstatuses', icon: <DescriptionIcon />, text: 'Lead Statuses' },
            { to: '/app/leadsources', icon: <DescriptionIcon />, text: 'Lead Sources' },
            { to: '/app/calloutcometypes', icon: <DescriptionIcon />, text: 'Call Outcome Types' },
            { to: '/app/callpurposetypes', icon: <DescriptionIcon />, text: 'Call Purpose Types' },
            { to: '/app/ownershiptypes', icon: <DescriptionIcon />, text: 'Ownership Type' },
            { to: '/app/supplierslist', icon: <DescriptionIcon />, text: 'Suppliers List' },
            { to: '/app/currencylist', icon: <DescriptionIcon />, text: 'Currency List' },
            { to: '/app/shipmenttypes', icon: <DescriptionIcon />, text: 'Shipment Types' },
            { to: '/app/ciffoblist', icon: <DescriptionIcon />, text: 'CIF/FOB List' },
        ],
    },
    {
        title: 'MODULES',
        links: [
            { to: '/app/companymodules', icon: <ViewModuleIcon />, text: 'Module Settings' },
            { to: '/app/termcondition', icon: <ViewModuleIcon />, text: 'Terms & Conditions ' },
            { to: '/app/systemsetting', icon: <ViewModuleIcon />, text: 'System Setting' },
        ],
    },
];



const ResponsiveCards = () => {
    return (
        <div>

            <ContentWrapper style={{ marginTop: '10px' }}>
                <Card className="shadow-sm">
                    <CardContent>
                        <Heading variant="h4" style={{ paddingBottom: '10px' }}>
                            Setup
                        </Heading>
                        <Grid container spacing={6}>
                            {cards.map((card, index) => (
                                <Grid item key={index} xs={12} sm={6} md={3}>
                                    <CustomCard>
                                        <CardBody>
                                            <Heading variant="h5">{card.title}</Heading>
                                            {card.links.map((link, linkIndex) => (
                                                <Typography key={linkIndex} variant="body2">
                                                    <CustomLink to={link.to}>
                                                        {link.icon} &nbsp; {link.text}
                                                    </CustomLink>
                                                </Typography>
                                            ))}
                                        </CardBody>
                                        <br />
                                    </CustomCard>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                    <br />
                </Card>
            </ContentWrapper>
        </div>
    );
};

export default ResponsiveCards;
