import React from 'react'
import { AuthProvider } from './AuthContext'
const Index = ({children}) => {
  return (
    <AuthProvider>
      {children}
    </AuthProvider>
  )
}

export default Index
