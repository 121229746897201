import React, { useState } from "react";
import { Box, Typography, Grid, CircularProgress, Divider, Table, TableBody, TableCell, TableHead, TableRow, Card, } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import * as Yup from 'yup';
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import AssignmentLateOutlinedIcon from '@mui/icons-material/AssignmentLateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomButton from "../../common/CustomButton";
import CustomTextField from "../../common/CustomTextField";
import CustomTextarea from "../../common/CustomTextarea";
import { genericPost } from '../../../services/authServices';
import { CREATE_PURCHASE_ORDER } from '../../../services/apiRoutes';
import CustomAutocomplete from "../../common/CustomAutoComplete";

const ValidationSchema = Yup.object().shape({
    supplierNamesId: Yup.number().required('Customer Name is required'),
    reference: Yup.string().required('Reference is required'),
    contactPersonId: Yup.number().required('Contact Person ID is required'),
    quotationNo: Yup.string().required('Quotation No. is required'),
    date: Yup.date().required('Date is required'),
    dueDate: Yup.date().required('Due Date is required'),
    note: Yup.string().required('Note is required'),
    particulars: Yup.array().of(
        Yup.object().shape({
            particularName: Yup.string().required('Particular Name is required'),
            qty: Yup.number().required('Quantity is required'),
            rate: Yup.number().required('Rate is required'),
            unit: Yup.string().required('Unit is required'),
            description: Yup.string().required('Description is required')
        })
    ).required('Particulars are required')
});

const CreatePurchaseOrder = ({ handleClose, setMessage, fetchData, suppliersList, contactPersonList, productsList, supplierIdName }) => {
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const payload = {
                supplierNamesId: values.supplierNamesId,
                reference: values.reference,
                contactPersonId: values.contactPersonId,
                quotationNo: values.quotationNo,
                date: values.date,
                dueDate: values.dueDate,
                note: values.note,
                particulars: values.particulars
            };

            const response = await genericPost(CREATE_PURCHASE_ORDER, payload);

            if (response.status === 201) {
                setMessage({
                    open: true,
                    text: response?.data?.message || "Purchase Order created successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchData();
                    handleClose();
                }, 1000);
            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const [formData] = useState({
        // supplierNamesId: supplierId,
        supplierNamesId: parseInt(supplierIdName),
        reference: '',
        contactPersonId: '',
        quotationNo: '',
        date: '',
        dueDate: '',
        note: '',
        particulars: [
            {
                particularName: '',
                qty: '',
                rate: '',
                unit: '',
                description: ''
            }
        ]
    });


    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={formData}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                    <Form>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4} md={4}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                    Create Purchase Order
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save And New"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} sx={{ px: "0%" }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <InsertDriveFileOutlinedIcon />
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Bill To
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={suppliersList}
                                    fieldLabel="Supplier Name"
                                    required={true}
                                    readOnly={true}
                                    getOptionLabel={(option) => option.title}
                                    value={suppliersList.find((option) => option.id === values.supplierNamesId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('supplierNamesId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Supplier Name"
                                    error={touched.supplierNamesId && !!errors.supplierNamesId}
                                    helperText={touched.supplierNamesId && errors.supplierNamesId}
                                    sx={{
                                        backgroundColor: "#E9ECEF"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Reference"
                                    name="reference"
                                    fieldLabel="Reference"
                                    required={true}
                                    value={values.reference}
                                    onChange={handleChange}
                                    error={touched.reference && !!errors.reference}
                                    helperText={touched.reference && errors.reference}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomAutocomplete
                                    options={contactPersonList}
                                    fieldLabel="Contact Person"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={contactPersonList.find((option) => option.id === values.contactPersonId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('contactPersonId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Enter Contact Person"
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <InsertDriveFileOutlinedIcon />
                                    <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                        Purchase Order  Details
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <CustomTextField
                                    placeholder="Enter Purchase Order No."
                                    name="quotationNo"
                                    fieldLabel="Purchase Order No."
                                    required={true}
                                    value={values.quotationNo}
                                    onChange={handleChange}
                                    error={touched.quotationNo && !!errors.quotationNo}
                                    helperText={touched.quotationNo && errors.quotationNo}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <CustomTextField
                                    placeholder="Enter Date"
                                    name="date"
                                    fieldLabel="Date"
                                    required={true}
                                    type="date"
                                    value={values.date}
                                    onChange={handleChange}
                                    error={touched.date && !!errors.date}
                                    helperText={touched.date && errors.date}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <CustomTextField
                                    placeholder="Enter Due Date"
                                    name="dueDate"
                                    fieldLabel="Due Date"
                                    required={true}
                                    type="date"
                                    value={values.dueDate}
                                    onChange={handleChange}
                                    error={touched.dueDate && !!errors.dueDate}
                                    helperText={touched.dueDate && errors.dueDate}
                                />
                            </Grid>

                        </Grid>
                        <FieldArray
                            name="particulars"
                            render={arrayHelpers => (
                                <>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={6}>
                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                <AssignmentLateOutlinedIcon />
                                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px", marginLeft: "4px" }}>
                                                    Particulars
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Box style={{ overflowX: 'auto', overflowY: 'auto' }}>
                                            <Table style={{ width: "100%" }}>
                                                <TableHead>
                                                    <TableRow style={{ height: '40px', backgroundColor: "#e7f0f7" }}>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>No.</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "300px" }} rowSpan={2}>Particular</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Qty</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Rate</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Unit</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "100px" }} rowSpan={2}>Amount</TableCell>
                                                        <TableCell style={{ ...styles.tablecell, width: "50px" }} rowSpan={2}>Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.particulars.map((particular, index) => {
                                                        const amount = (particular.qty || 0) * (particular.rate || 0);
                                                        return (
                                                            <TableRow key={index}>
                                                                <TableCell style={styles.tablecell}>{index + 1}</TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomAutocomplete
                                                                        options={productsList || []}
                                                                        required={true}
                                                                        getOptionLabel={(option) => option.productName || ''}
                                                                        value={productsList?.find((option) => option.productsId === particular?.particularName) || null}
                                                                        onChange={(e, newValue) => {
                                                                            setFieldValue(`particulars.${index}.particularName`, newValue ? newValue.productsId : '');
                                                                            setFieldValue(`particulars.${index}.unit`, newValue ? newValue.productsUnit?.productsUnitId : '');
                                                                        }}
                                                                        placeholder="Select Product Name"
                                                                    />
                                                                    <CustomTextarea
                                                                        name={`particulars.${index}.description`}
                                                                        value={particular?.description || ''}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Description"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        name={`particulars.${index}.qty`}
                                                                        type="number"
                                                                        value={particular.qty || ''}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Quantity"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        name={`particulars.${index}.rate`}
                                                                        type="number"
                                                                        value={particular.rate || ''}
                                                                        onChange={handleChange}
                                                                        placeholder="Enter Rate"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomAutocomplete
                                                                        options={productsList?.map((product) => product.productsUnit) || []}
                                                                        readOnly={true}
                                                                        getOptionLabel={(option) => option.unitName || ''}
                                                                        value={productsList
                                                                            ?.find((option) => option.productsId === particular?.particularName)
                                                                            ?.productsUnit || null}
                                                                        onChange={(e, newValue) =>
                                                                            setFieldValue(`particulars.${index}.unit`, newValue ? newValue.productsUnitId : '')
                                                                        }
                                                                        placeholder="Select Unit"
                                                                        sx={{
                                                                            backgroundColor: "#E9ECEF"
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <CustomTextField
                                                                        type="number"
                                                                        value={amount}
                                                                        placeholder="Amount"
                                                                        readOnly={true}
                                                                        sx={{
                                                                            backgroundColor: "#E9ECEF"
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={styles.tablecell}>
                                                                    <DeleteOutlineOutlinedIcon
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                        style={{ fontSize: "20px" }} />
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>

                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecell} colSpan={7}>
                                                        <div style={{ margin: "auto", textAlign: "center" }}>
                                                            <CustomButton
                                                                startIcon={<AddOutlinedIcon />}
                                                                onClick={() => arrayHelpers.push({
                                                                    particularName: '',
                                                                    qty: '',
                                                                    rate: '',
                                                                    unit: '',
                                                                    description: ''
                                                                })}
                                                                title="Add Item"
                                                                size="small"
                                                                variant="outlined"
                                                                sx={Additemstyle}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow style={{ height: '40px' }}>
                                                    <TableCell style={styles.tablecellfooter} colSpan={2}  > Total</TableCell>
                                                    <TableCell style={styles.tablecellfooter}  >
                                                        {values.particulars.reduce((total, particular) => total + (particular.qty || 0), 0)}
                                                    </TableCell>

                                                    <TableCell style={styles.tablecellfooter} colSpan={2}  > </TableCell>
                                                    <TableCell style={styles.tablecellfooter} >
                                                        {values.particulars.reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)}

                                                    </TableCell>
                                                    <TableCell style={styles.tablecellfooter} > </TableCell>
                                                </TableRow>
                                            </Table>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        />

                        <Grid container spacing={1} style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                            <Grid item xs={4} md={6}>
                                <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                    In Words

                                </Typography>
                                <Typography style={{ color: "#212529", fontWeight: 400, fontSize: "14px" }}>
                                    &nbsp;
                                    {values.particulars.reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)}
                                    &nbsp; Only
                                </Typography>
                                <br />
                                <CustomTextarea
                                    placeholder="Enter Note"
                                    name="note"
                                    fieldLabel="Note"
                                    required={true}
                                    value={values.note}
                                    onChange={handleChange}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                />
                            </Grid>

                            <Grid item xs={8} md={6} style={{ display: 'flex', justifyContent: 'flex-end', color: "#212529", fontWeight: 500, fontSize: "20px" }}>
                                <div>
                                    <Box display="flex" flexDirection="row">
                                        <Box  >
                                            <CurrencyRupeeOutlinedIcon />
                                        </Box>
                                        <Box >
                                            <Typography style={{ fontWeight: 700, color: "#212529", fontSize: "16px" }}>
                                                Summary
                                            </Typography>

                                        </Box>
                                    </Box>

                                    <Card variant="outlined" style={{ padding: "2px" }}>
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            <Typography style={{ fontWeight: "600", fontSize: "15px", paddingRight: "5px" }}>Grand Total:</Typography>
                                            <CustomTextField
                                                name="totalAmount"
                                                value={values.particulars.reduce((total, particular) => total + ((particular.qty || 0) * (particular.rate || 0)), 0)}
                                                readOnly={true}
                                                placeholder="Total Amount"
                                                sx={{
                                                    backgroundColor: "#E9ECEF"
                                                }}
                                            />
                                        </Box>
                                    </Card>
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreatePurchaseOrder;


const customerOption = [
    { title: 'supplierOption 1', id: 1 },
    { title: 'supplierOption 2', id: 2 },
    { title: 'supplierOption 3', id: 3 }
];

const Additemstyle = {
    color: '#212529 !important',
    textTransform: 'capitalize !important',
    borderRadius: '4px !important',
    border: '1px dashed #aecce4 !important',
    height: '30px !important',
    display: 'inline-flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    backgroundColor: '#e7f0f7 !important',
    width: '100% !important',
};


const styles = {
    table: {
        minWidth: 650,
    },
    textMuted: {
        color: '#6c757d',
    },

    labelCell: {
        width: '180px',
    },
    tableContainer: {
        marginBottom: '16px',
    },
    header: {
        marginBottom: '16px',
    },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    customTableRow: {
        height: '20px',
    },
    editButton: {
        textAlign: 'right',
    },
    showDetailsSpan: {
        padding: '8px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
        cursor: 'pointer',
    },
    expandIcon: {
        color: '#212529',
    },
    boxheading: {
        width: 70,  // Width of the box
        height: 70, // Height of the box, making it square
        background: 'radial-gradient(circle, #007BFF 0, rgba(0, 134, 201, .5) 0, #007BFF 100%)', // Radial gradient background
        color: 'white', // Text color
        display: 'flex', // Center content horizontally
        alignItems: 'center', // Center content vertically
        justifyContent: 'center', // Center content horizontally
        borderRadius: 14, // Rounded corners
        boxShadow: 3, // Box shadow for depth
    },
    headingname: {
        paddingTop: '10px',
        fontWeight: '600',
        fontSize: '20px',
        color: '#212529',
    },
    avatarcircle: {
        backgroundColor: '#007BFF',
        width: '30px',
        height: '30px',
        fontSize: '15px',
    },
    cardtittile: {
        padding: '8px',
        paddingLeft: '35px',
        fontWeight: 600,
        fontSize: '16px',
        color: '#212529',
    },
    tablebutton: {
        backgroundColor: '#007bff',
        color: '#ffff',
        textTransform: 'capitalize',
        borderRadius: 4,
        border: '2px solid #007bff',
        height: 30,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tablecell: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'center',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    tablecellfooter: {
        border: '1px solid #dee2e6',
        color: '#212529',
        fontWeight: 600,
        fontSize: '14px',
        textAlign: 'right',
        padding: '1px',
        paddingLeft: "10px",
        paddingRight: "10px",
    },

};
