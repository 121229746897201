import React, { useEffect } from "react";
import Header from "../components/Home/Header";
import Styles from '../assets/css/Header.module.css'
import HomeFooter from "../components/Home/HomeFooter";
const LayoutHome = props => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header styles={Styles} />
        <br />
        <br />
        <div className="main-content">{props.children}</div>
        <br />
        <HomeFooter />

      </div>
      {/* {showRightSidebar ? <RightSidebar /> : null} */}
    </React.Fragment>
  );
};


export default LayoutHome;
