import React, { useState } from 'react';
import DetailsPageAppbar from '../../common/DetailsPage/DetailsPageAppbar';
import DetailsPageCard from '../../common/DetailsPage/DetailsPageCard';
import { ExportMenu, } from '../../../constants/DetailsPage'
import { Main } from '../../../constants/DetailsMain';
import CustomModal from "../../../components/common/CustomModal";
import CreateShippingBooking from "../CreateForm/CreateShippingBooking";
import { useNavigate, useParams } from 'react-router-dom';
import { useExportShipmentDetails } from './Hook/useExportShipmentDetails';


export default function PersistentDrawerLeft() {

    const [open, setOpen] = React.useState(true);
    const [leadModal, setLeadModal] = useState(false);

    const [activeTab, setActiveTab] = React.useState('Shipment Booking');

    const navigate = useNavigate();
    const { exportShipmentId } = useParams();

    const { shipmentDetails } = useExportShipmentDetails(exportShipmentId);
    if (!shipmentDetails) {
        return <p>Loading...</p>;
    }



    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleScrollTo = (menu) => {
        setActiveTab(menu);
    };


    const handleOpen = () => {
        setLeadModal(true);
    };
    const handleClose = () => {
        setLeadModal(false)
    }


    const columns = [
        { id: 'sn', label: 'SN', accessor: 'sn' },
        { id: 'porformNo', label: 'Porform No.', accessor: 'porformNo' },
        { id: 'date', label: 'Date', accessor: 'date' },
        { id: 'shippingId', label: 'Shipping Id', accessor: 'shippingId' },
        { id: 'customerName', label: 'Customer Name', accessor: 'customerName' },
        { id: 'amount', label: 'Amount', accessor: 'amount' },
        { id: 'action', label: 'Action', accessor: 'action' }

    ];

    const companyName = shipmentDetails.company.companyName;
    const phoneNumber = shipmentDetails.shipmentId;
    const initial = shipmentDetails.company.companyName ? shipmentDetails.company.companyName.charAt(0).toUpperCase() : '';

    const handleBackClick = () => {
        navigate("/app/export/shipement");
    };
    return (
        <>
            <div>
                <DetailsPageAppbar
                    handleScrollTo={handleScrollTo}
                    activeTab={activeTab}
                    open={open}
                    handleDrawerOpen={handleDrawerOpen}
                    handleDrawerClose={handleDrawerClose}
                    AppBarMenu={ExportMenu(exportShipmentId)}
                    companyName={companyName}
                    phoneNumber={phoneNumber}
                    initial={initial}
                    onBackClick={handleBackClick}

                />

                <Main open={open}>

                    <DetailsPageCard columns={columns}
                        title={'Shipment Booking'}
                        buttonTitle={'Create Shipment Booking'}
                        handleOpen={handleOpen}
                    />
                    <CustomModal open={leadModal} handleClose={handleClose}>
                        <CreateShippingBooking />
                    </CustomModal>

                </Main>
            </div >
        </>
    );
}

