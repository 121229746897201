import React from 'react'
import { useState, useEffect, useContext } from "react"
import CustomModal from '../common/CustomModal'
import AddCompany from '../MyCompany/AddCompany'
import { getCountries, getStates, } from "../../services/CommonServices";
import { SET_DEFAULT_COMPANY } from "../../services/apiRoutes"
import { genericPut } from "../../services/apiServices"
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const Company = () => {
  const [addCompanyModal, setAddCompanyModal] = useState(true);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false)

  const { login } = useContext(AuthContext)

  const navigate = useNavigate()
  const getCommonData = async () => {
    const getCountriesData = await getCountries();
    if (getCountriesData) {
      setCountries(getCountriesData);
    }
  };

  useEffect(() => {
    getCommonData();
  }, []);

  const handleSetDefault = async (companyId, token) => {
    setLoading(true)
    try {
      const response = await genericPut(`${SET_DEFAULT_COMPANY}/${companyId}`, {}, token);
      if (response.data.accessToken) {
        sessionStorage.setItem('accessToken', response.data.accessToken)
        const accessToken = response.data.accessToken
        login(accessToken)

        setLoading(false)
        navigate('/app/dashboard')

      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false)

    }
  };
  return (
    <div>
      <CustomModal open={addCompanyModal}>
        <AddCompany
          //   handleClose={handleClose}
          countries={countries}
          getStates={getStates}
          firstTimeLogin={true}
          handleSetDefault={handleSetDefault}
        />
      </CustomModal>
    </div>
  )
}

export default Company