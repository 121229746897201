import React, { useState } from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import CustomButton from "../../common/CustomButton";
import { ClearRounded, SaveRounded } from "@mui/icons-material";
import CustomTextField from "../../common/CustomTextField";
import CustomAutocomplete from "../../common/CustomAutoComplete";
import CustomTextarea from "../../common/CustomTextarea";


export default function CustomizedDialogs() {
    const [formData, setFormData] = useState({
        leadName: "",
        leadOwner: null,
        leadSource: null,
        contactName: null,
        clientCompany: "",
        leadStatus: "",
        stage: "",
        priority: "",
        lossReason: "",
        productType: "",
        expectedTimeline: "",
        productRadio: "0",
        leadDescription: "",
        shippingOption: null,
        fobValue: "",
        cifValue: ""
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAutocompleteChange = (event, newValue) => {
        setFormData({
            ...formData,
            shippingOption: newValue,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form data:", formData);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <Typography
                            sx={{ fontWeight: 600 }}
                            style={{ color: "#212529", fontSize: "18px" }}
                        >
                            Shipping Booking
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Box style={{ display: "flex" }}>
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title="Save"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<SaveRounded />}
                                title=" Save and New"
                                size="small"
                                variant="contained"
                            />
                            <CustomButton
                                startIcon={<ClearRounded />}
                                title="Cancel"
                                size="small"
                                variant="contained"
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Divider />

                <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <CustomAutocomplete
                            options={shippingOptions}
                            fieldLabel="Select Type"
                            required={true}
                            getOptionLabel={(option) => option.title}
                            value={formData.shippingOption}
                            onChange={handleAutocompleteChange}
                            placeholder="Select Type"
                        />
                    </Grid>

                    {formData.shippingOption?.title === "FOB" && (
                        <>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Port Of Loading"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Port Of Loading"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Port Of Destination"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Port Of Destination"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Direct/Indirect"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Direct/Indirect"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Shipping Line"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Shipping Line"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Sea Freight"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Sea Freight"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select Currency"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="date"
                                    name="fobValue"
                                    fieldLabel="ETD"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="date"
                                    name="fobValue"
                                    fieldLabel="Cut Off"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="date"
                                    name="fobValue"
                                    fieldLabel="ETA"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Destination Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Destination Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="CFS/Local Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter CFS/Local Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Shipping Line Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Shipping Line Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="BL Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter BL Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="No. of Containers"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter No. of Containers"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="20FCL/40FCL"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select 20FCL/40FCL"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="Freight Forward Agency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select Freight Forward Agency"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="Clearing Agency (CHA)"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select Clearing Agency (CHA)"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Free Days at Destination"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Free Days at Destination"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <CustomTextarea
                                    name="country"
                                    type="text"
                                    fieldLabel="Note "
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Write a Note..."
                                />
                            </Grid>

                        </>
                    )}



                    {formData.shippingOption?.title === "CIF" && (
                        <>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Port Of Loading"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Port Of Loading"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Port Of Destination"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Port Of Destination"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Direct/Indirect"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Direct/Indirect"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Shipping Line"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Shipping Line"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Sea Freight"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Sea Freight"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select Currency"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="date"
                                    name="fobValue"
                                    fieldLabel="ETD"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="date"
                                    name="fobValue"
                                    fieldLabel="Cut Off"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    type="date"
                                    name="fobValue"
                                    fieldLabel="ETA"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="mm/dd/yyyy"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Destination Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Destination Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="CFS/Local Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter CFS/Local Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Shipping Line Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Shipping Line Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="BL Charges"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter BL Charges"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="No. of Containers"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter No. of Containers"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="20FCL/40FCL"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select 20FCL/40FCL"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="Freight Forward Agency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select Freight Forward Agency"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomAutocomplete
                                    options={top100Films}
                                    fieldLabel="Clearing Agency (CHA)"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={formData.company}
                                    onChange={(e, newValue) =>
                                        handleChange({ target: { name: "company", value: newValue } })
                                    }
                                    placeholder="Select Clearing Agency (CHA)"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CustomTextField
                                    name="fobValue"
                                    fieldLabel="Free Days at Destination"
                                    required={true}
                                    value={formData.fobValue}
                                    onChange={handleChange}
                                    placeholder="Enter Free Days at Destination"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                <CustomTextarea
                                    name="country"
                                    type="text"
                                    fieldLabel="Note "
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Write a Note.."
                                />
                            </Grid>

                        </>
                    )}
                </Grid>
            </form>
            <br />
            <br />
        </>
    );
}

const shippingOptions = [
    { title: "FOB" },
    { title: "CIF" },
];


const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
];