import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid, CircularProgress, Divider } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../common/CustomButton';
import CustomTextField from '../common/CustomTextField';
import CustomAutocomplete from '../common/CustomAutoComplete';
import { SaveRounded, ClearRounded } from "@mui/icons-material";
import { UPDATE_EXPORT_SHIPMENT, READ_EXPORT_SHIPMENT } from '../../services/apiRoutes';
import CustomTextarea from '../common/CustomTextarea';

const ValidationSchema = Yup.object().shape({
    shipmentId: Yup.string().required('Shipment ID is required'),
    customerId: Yup.number().required('Customer ID is required'),
    cifFobId: Yup.number().required('CIF/FOB ID is required'),
    noOfContainers: Yup.number().required('Number of Containers is required'),
    currencyId: Yup.number().required('Currency ID is required'),
    shipmentTypeId: Yup.number().required('Shipment Type ID is required'),
    note: Yup.string().required('Note is required'),
    expectedEtd: Yup.string().required('expectedEtd is required'),


});

const UpdateExportshipement = ({ handleClose, exportShipmentId, setMessage, fetchExportShipments, cifFobList, shipmentTypes, currencyList, custumersList }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!exportShipmentId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');
                const response = await axios.get(READ_EXPORT_SHIPMENT(exportShipmentId), {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });

                setData(response.data.shipment);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [exportShipmentId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');
            const response = await axios.put(UPDATE_EXPORT_SHIPMENT(exportShipmentId), values, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });

            if (response.status === 200) {
                setMessage({
                    open: true,
                    text: response.data.message || "Export updated successfully",
                    type: "success",
                });
                setTimeout(() => {
                    fetchExportShipments();
                    handleClose();
                },);
            } else {
                setMessage({
                    open: true,
                    text: 'Something went wrong',
                    type: 'error',
                });
            }
        } catch (error) {
            setMessage({
                open: true,
                text: 'Something went wrong',
                type: 'error',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Box sx={{ mx: 'auto', mt: 0 }}>
            <Formik
                initialValues={{
                    shipmentId: data?.shipmentId || '',
                    customerId: data?.customerId || '',
                    cifFobId: data?.cifFobId || '',
                    expectedEtd: data?.expectedEtd || '',
                    noOfContainers: data?.noOfContainers || '',
                    currencyId: data?.currencyId || '',
                    shipmentTypeId: data?.shipmentTypeId || '',
                    note: data?.note || '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched, handleChange, values, setFieldValue }) => (
                    <Form>
                        <Grid
                            container
                            spacing={1}
                            style={{ position: "sticky" }}
                        >
                            <Grid item xs={4} md={4} >
                                <Typography
                                    sx={{ fontWeight: 600 }}
                                    style={{ color: "#212529", fontSize: "18px", }}
                                >
                                    Update Export Shipment
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                md={8}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Box style={{ display: "flex" }}>

                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title=" Save and New"
                                        size="small"
                                        variant="contained"
                                    />

                                    <CustomButton
                                        startIcon={<ClearRounded />}
                                        title="Cancel"
                                        size="small"
                                        variant="contained"
                                        onClick={handleClose}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} sx={{ px: "5%" }}>
                            <Grid item xs={12} md={6}>
                                <CustomTextField
                                    placeholder="Select Shipment ID"
                                    name="shipmentId"
                                    fieldLabel="Shipment ID"
                                    required={true}
                                    value={values.shipmentId}
                                    onChange={handleChange}
                                    error={touched.shipmentId && !!errors.shipmentId}
                                    helperText={touched.shipmentId && errors.shipmentId}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={custumersList}
                                    fieldLabel="Customer"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={custumersList.find((option) => option.id === values.customerId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('customerId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Customer"
                                    error={touched.customerId && !!errors.customerId}
                                    helperText={touched.customerId && errors.customerId}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={cifFobList}
                                    fieldLabel="CIF/FOB"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={cifFobList.find((option) => option.id === values.cifFobId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('cifFobId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select CIF/FOB"
                                    error={touched.cifFobId && !!errors.cifFobId}
                                    helperText={touched.cifFobId && errors.cifFobId}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <CustomTextField
                                    name="expectedEtd"
                                    fieldLabel="Expected ETD"
                                    required={true}
                                    type="datetime-local"
                                    value={values.expectedEtd}
                                    onChange={handleChange}
                                    error={touched.expectedEtd && !!errors.expectedEtd}
                                    helperText={touched.expectedEtd && errors.expectedEtd}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <CustomTextField
                                    type="number"
                                    name="noOfContainers"
                                    fieldLabel="Number of Containers"
                                    required={true}
                                    value={values.noOfContainers}
                                    onChange={handleChange}
                                    error={touched.noOfContainers && !!errors.noOfContainers}
                                    helperText={touched.noOfContainers && errors.noOfContainers}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={currencyList}
                                    fieldLabel="Currency"
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={currencyList.find((option) => option.id === values.currencyId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('currencyId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Currency"
                                    error={touched.currencyId && !!errors.currencyId}
                                    helperText={touched.currencyId && errors.currencyId}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <CustomAutocomplete
                                    options={shipmentTypes}
                                    fieldLabel="Shipment Type "
                                    required={true}
                                    getOptionLabel={(option) => option.title}
                                    value={shipmentTypes.find((option) => option.id === values.shipmentTypeId) || null}
                                    onChange={(e, newValue) =>
                                        setFieldValue('shipmentTypeId', newValue ? newValue.id : '')
                                    }
                                    placeholder="Select Shipment Type "
                                    error={touched.shipmentTypeId && !!errors.shipmentTypeId}
                                    helperText={touched.shipmentTypeId && errors.shipmentTypeId}
                                />
                            </Grid>

                            <Grid item xs={8}>
                                <CustomTextarea
                                    name="note"
                                    fieldLabel="Note"
                                    required={true}
                                    value={values.note}
                                    onChange={handleChange}
                                    error={touched.note && !!errors.note}
                                    helperText={touched.note && errors.note}
                                />
                            </Grid>
                        </Grid>


                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default UpdateExportshipement;


const CustomerOption = [
    { id: 1, title: 'customer 1' },
    { id: 2, title: 'customer 2' },
    { id: 3, title: 'customer 3' },
];

const cifFobOptions = [
    { id: 1, title: 'CIF 1' },
    { id: 2, title: 'FOB 1' },
];

const currencyOptions = [
    { id: 1, title: 'USD' },
    { id: 2, title: 'EUR' },
    { id: 3, title: 'GBP' },
];

const shipmentTypeOptions = [
    { id: 1, title: 'Air Freight' },
    { id: 2, title: 'Sea Freight' },
    { id: 3, title: 'Road Transport' },
];
