import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Box, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useTranslation } from "react-i18next";
import DataTable from '../components/common/DataTable';
import MessageBox from "../components/common/CustomMessageBox";
import CustomDelete from '../components/common/CustomDeleteAlert';
import CustomModal from "../components/common/CustomModal";
import Createexportshipement from "../components/exportshipement/createexportshipement";
import UpdateExportshipement from "../components/exportshipement/UpdateExportshipement";
import { DELETE_EXPORT_SHIPMENT, LIST_ALL_EXPORT_SHIPMENT } from "../services/apiRoutes";
import { genericGet } from "../services/apiServices";
import axios from "axios";
import { formatDate, getCIFFOBList, getCurrencyList, getCustumersList, getShipmentTypes, getSuppliersList } from "../services/CommonServices";

const title = 'Export Shipment';

const ExportShipment = () => {
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null); // State to hold selected ID
  const [exportShipments, setExportShipments] = useState([]);
  const [cifFobList, setCIFFOBList] = useState([]);
  const [shipmentTypes, setShipmentTypes] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [custumersList, setCustumersList] = useState([]);

  const [message, setMessage] = useState({
    open: false,
    text: '',
    type: ''
  });
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [rowId, setRowId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleUpdateClick = (exportShipmentId) => {
    setSelectedId(exportShipmentId);
    setUpdateModal(true);
  };

  const handleOpenDeleteAlert = (rowId) => {
    setDeleteAlert(true);
    setRowId(rowId);
  };

  const handleCloseUpdate = () => {
    setUpdateModal(false);
  };

  const handleDelete = async (exportShipmentId) => {
    setLoading(true);
    const authToken = sessionStorage.getItem('accessToken');
    try {
      const response = await axios.delete(DELETE_EXPORT_SHIPMENT(exportShipmentId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      if (response.status === 200) {
        fetchExportShipments();
        setDeleteAlert(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error deleting export shipment", error);
    }
  };

  useEffect(() => {
    fetchExportShipments();
    getCommonData();
  }, []);

  const getCommonData = async () => {

    const cifFobListData = await getCIFFOBList();
    setCIFFOBList(cifFobListData);

    const shipmentTypesData = await getShipmentTypes();
    setShipmentTypes(shipmentTypesData);

    const currencyListData = await getCurrencyList();
    setCurrencyList(currencyListData);

    const custumersList = await getCustumersList();
    setCustumersList(custumersList);
  }


  const columns = useMemo(
    () => [
      { accessorKey: "exportShipmentId", header: "SN.", size: 50 },
      { accessorKey: "createdAt", header: "Date", size: 50 },
      { accessorKey: "shipmentId", header: "Shipment ID", size: 50 },
      { accessorKey: "customerName", header: "Customer Name", size: 50 },
      { accessorKey: "country", header: "Country", size: 50 },
      { accessorKey: "noOfContainer", header: "No. of Container", size: 50 },
      { accessorKey: "currency", header: "Currency", size: 50 },
      { accessorKey: "cifFob", header: "FOB/CIF", size: 50 },
      {
        accessorKey: "action",
        header: "Action",
        size: 50,
        Cell: ({ row }) => (
          <>
            <IconButton onClick={() => handleViewClick(row.original.exportShipmentId)}>
              <VisibilityIcon style={{ color: "#059212" }} />
            </IconButton>
            <IconButton onClick={() => handleUpdateClick(row.original.exportShipmentId)}>
              <ModeEditOutlineOutlinedIcon style={{ color: "#050C9C" }} />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteAlert(row.original.exportShipmentId)}>
              <DeleteOutlineOutlinedIcon style={{ color: "#E4003A" }} />
            </IconButton>
          </>
        ),
      },
    ],
    []
  );

  const fetchExportShipments = async () => {
    setLoading(true);
    try {
      const response = await genericGet({ url: LIST_ALL_EXPORT_SHIPMENT });

      if (response.data.success && response.data.shipments) {
        const shipmentData = response.data.shipments.map((shipment, index) => ({
          sn: index + 1,
          exportShipmentId: shipment.exportShipmentId || '',
          createdAt: formatDate(shipment.createdAt) || '',
          shipmentId: shipment.shipmentId || '',
          customerName: shipment.customer.clientCompanyName || '',
          country: shipment.customer.mailingCountry.name || '',
          noOfContainer: shipment.noOfContainers || '',
          cifFob: shipment.cifFob.cifFobName || '',
          currency: shipment.currency.currency || '',
        }));
        setExportShipments(shipmentData);
      } else {
        setError('No export shipments found');
      }
    } catch (error) {
      setError('Error fetching export shipments');
      console.error('Error fetching export shipments', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleViewClick = (exportShipmentId) => {
    navigate(`/app/export/shipement/${exportShipmentId}`);
  };

  return (
    <>
      <Box sx={{ padding: '10px' }}>
        <DataTable
          title={title}
          columns={columns}
          data={exportShipments}
          handleOpen={handleOpen}
          buttonTitle="Create Export Shipment"
        />
        <CustomModal open={modal} handleClose={handleClose}>
          <Createexportshipement
            handleClose={handleClose}
            fetchExportShipments={fetchExportShipments}
            setMessage={setMessage}
            cifFobList={cifFobList}
            shipmentTypes={shipmentTypes}
            currencyList={currencyList}
            custumersList={custumersList}

          />
        </CustomModal>

        <CustomModal open={updateModal} handleClose={handleCloseUpdate}>
          <UpdateExportshipement
            handleClose={handleCloseUpdate}
            exportShipmentId={selectedId}
            fetchExportShipments={fetchExportShipments}
            setMessage={setMessage}
            cifFobList={cifFobList}
            shipmentTypes={shipmentTypes}
            currencyList={currencyList}
            custumersList={custumersList}

          />
        </CustomModal>

        <MessageBox
          open={message.open}
          onClose={() => setMessage({ ...message, open: false })}
          message={message.text}
          handleClose={() => setMessage({ ...message, open: false })}
          type={message.type}
        />
        <CustomDelete
          handleDelete={handleDelete}
          open={deleteAlert}
          rowId={rowId}
          handleClose={() => setDeleteAlert(false)}
        />
      </Box>
    </>
  );
};

export default ExportShipment;
