import React from 'react';
import { Card, CardContent, CardHeader, Typography, Box, Container, Grid } from '@mui/material';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CustomButton from '../common/CustomButton';

// PricingCardsection component
const PricingCardsection = ({ title, price, features, buttonText, onButtonClick }) => {
    return (
        <Card
            sx={{
                maxWidth: "100%",
                margin: '20px',
                boxShadow: 3,
                height: "80vh",
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s ease-in-out', // Smooth transition
                '&:hover': {
                    transform: 'scale(1.05)', // Zoom in effect
                }
            }}
        >
            <CardHeader
                title={title}
                titleTypographyProps={{ variant: 'h6', align: 'center', fontSize: "25px", fontWeight: "bold" }}
                sx={{ backgroundColor: '#007BFF', color: "#FFFF", pb: 10 }}
            />
            <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ textAlign: 'center', mb: 2 }}>
                    <Card sx={{ p: 4, m: 2, mt: -8 }}>
                        <Typography variant="h4" component="p" sx={{ fontSize: "20px", fontWeight: "bold" }}>
                            {price}
                        </Typography>
                    </Card>
                </Box>
                <Box sx={{ textAlign: 'left', mb: 2 }}>
                    {features.map((feature, index) => (

                        <Typography
                            variant="body2"
                            key={index}
                            sx={{
                                mb: 1,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ArrowCircleRightOutlinedIcon sx={{ mr: 1, fontSize: "20px" }} /> {feature}
                        </Typography>
                    ))}
                </Box>
            </CardContent>
            <Box sx={{ textAlign: 'center', p: 2 }}>
                <CustomButton
                    variant="contained"
                    color="secondary"
                    title={buttonText}
                    onClick={onButtonClick}
                />
            </Box>
        </Card>
    );
};

// Main component
const PricingCard = () => {
    const features = [
        'Streamlined import',
        'Real-time tracking of shipments',
        'Automated customs clearance',
        'Advanced reporting and analytics',
        'Dedicated support team',
        '24/7 customer support',
        'Multi-currency support',
        // 'Customizable dashboards',
        // 'Integrated payment solutions',
        // 'Enhanced security protocols'
    ];

    const handleButtonClick = () => {
        alert('Button clicked!');
    };

    return (
        <Container>
            <Grid container spacing={1} justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                    <PricingCardsection
                        title="Basic Plan"
                        price="$50/month"
                        features={features}
                        buttonText="Choose Plan"
                        onButtonClick={handleButtonClick}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <PricingCardsection
                        title="Pro Plan"
                        price="$100/month"
                        features={features}
                        buttonText="Choose Plan"
                        onButtonClick={handleButtonClick}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <PricingCardsection
                        title="Premium Plan"
                        price="$200/month"
                        features={features}
                        buttonText="Choose Plan"
                        onButtonClick={handleButtonClick}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default PricingCard;
