import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox, Divider, Grid, CircularProgress } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CustomButton from "../common/CustomButton";
import CustomTextField from "../common/CustomTextField";
import CustomAutocomplete from '../common/CustomAutoComplete';
import { ClearRounded, Replay10Rounded, SaveRounded } from "@mui/icons-material";
import { getStates, getCities } from "../../services/CommonServices";
import CustomTextarea from '../common/CustomTextarea';
import { Update_Lead_leadId, GET_Lead_leadId } from '../../services/apiRoutes';
import { genericGet } from '../../services/apiServices';


const leadValidationSchema = Yup.object().shape({
    leadOwner: Yup.string().required('Lead Owner is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    annualRevenue: Yup.number().required('Annual Revenue is required'),
    title: Yup.string().required('Title is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
        .required('Phone is required'),
    mobile: Yup.string()
        .matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits')
        .required('Mobile is required'),

    leadSourceId: Yup.string().required('Lead Source ID is required'),
    leadStatusId: Yup.string().required('Lead Status ID is required'),
    industry: Yup.string().required('Industry is required'),
    numberOfEmployees: Yup.number().required('Number of Employees is required'),
    rating: Yup.string().required('Rating is required'),
    expectedDate: Yup.date().required('Expected Date is required'),
    emailOptOut: Yup.number().required('Email Opt-Out is required'),
    businessGroup: Yup.string().required('Business Group is required'),
    secondaryEmail: Yup.string().email('Invalid email'),
    street: Yup.string().required('Street is required'),
    countryId: Yup.string().required('Country ID is required'),
    stateId: Yup.string().required('State ID is required'),
    cityId: Yup.string().required('City ID is required'),
    clientCompanyId: Yup.string().required('clientCompanyId ID is required'),
    zipCode: Yup.string()
        .matches(/^[0-9]{6}$/, 'Zip Code number must be exactly 6 digits')
        .required('Zip Code is required'),

    description: Yup.string().required('Description is required'),
});

const UpdateLeads = ({ handleClose, industryType, rating, leadSource, leadStatus, conutries, businessGroup, leadId, getLeadList, setMessage, clientCompany, leadOwner }) => {

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    // const [message, setMessage] = useState({ open: false, text: '', type: '' });



    useEffect(() => {
        if (!leadId) return;

        const fetchData = async () => {
            try {
                const accessToken = sessionStorage.getItem('accessToken');

                const response = await axios.get(GET_Lead_leadId(leadId), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,

                    },
                });

                setData(response.data.lead);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [leadId]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const accessToken = sessionStorage.getItem('accessToken');

            const response = await axios.put(Update_Lead_leadId(leadId), values, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            if (response.status === 200) {
                // alert('Lead updated successfully');
                setMessage({
                    open: true,
                    text: response?.data?.message || "Lead Update successfully",
                    type: "success",
                });
                getLeadList()
                setTimeout(() => {
                    handleClose()
                }, 3000)
                handleClose()


            } else {
                alert('Something went wrong');
            }
        } catch (error) {
            alert('Something went wrong');
        } finally {
            setSubmitting(false);
        }
    };

    const handleChangeCountry = async (newValue) => {
        const countryId = newValue ? newValue.id : '';
        if (countryId) {
            const stateData = await getStates({ id: countryId });
            setStates(stateData);
            setCities([]);
        } else {
            setStates([]);
            setCities([]);
        }
    };

    const handleChangeState = async (newValue) => {
        const stateId = newValue ? newValue.id : '';
        if (stateId) {
            const cityData = await getCities({ id: stateId });
            setCities(cityData);
        } else {
            setCities([]);
        }
    };


    return (
        <>
            <Box sx={{ mx: 'auto', mt: 0 }}>
                <Formik
                    initialValues={{
                        leadOwner: data?.leadOwner || '',
                        leadOwner: data?.leadOwner ? parseInt(data.leadOwner) : '',
                        firstName: data?.firstName || '',
                        lastName: data?.lastName || '',
                        annualRevenue: data?.annualRevenue || '',
                        title: data?.title || '',
                        email: data?.email || '',
                        phone: data?.phone || '',
                        mobile: data?.mobile || '',
                        leadSourceId: data?.leadSourceId || '',
                        leadStatusId: data?.leadStatusId || '',
                        industry: data?.industry ? parseInt(data.industry) : '',
                        numberOfEmployees: data?.numberOfEmployees || '',
                        rating: data?.rating ? parseInt(data.rating) : '',

                        expectedDate: data?.expectedDate || '',
                        emailOptOut: data?.emailOptOut ? 1 : 0,
                        businessGroup: data?.businessGroup || '',
                        secondaryEmail: data?.secondaryEmail || '',
                        street: data?.street || '',
                        countryId: data?.countryId || '',
                        clientCompanyId: data?.clientCompanyId || '',
                        stateId: data?.stateId || '',
                        cityId: data?.cityId || '',
                        zipCode: data?.zipCode || '',
                        description: data?.description || '',
                    }}
                    validationSchema={leadValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, errors, touched, handleChange, setFieldValue, values }) => (
                        <Form>
                            <Grid container spacing={1} style={{ position: "sticky" }}>
                                <Grid item xs={4} md={2}>
                                    <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                        Update Leads
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} md={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Box style={{ display: "flex" }}>
                                        <CustomButton
                                            startIcon={<SaveRounded />}
                                            title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            disabled={isSubmitting}
                                        />
                                        <CustomButton
                                            startIcon={<SaveRounded />}
                                            title=" Save and New"
                                            size="small"
                                            variant="contained"
                                        />
                                        <CustomButton
                                            startIcon={<Replay10Rounded />}
                                            title="Convert To Quotation"
                                            size="small"
                                            variant="contained"
                                        />
                                        <CustomButton
                                            startIcon={<ClearRounded />}
                                            title="Cancel"
                                            size="small"
                                            variant="contained"
                                            onClick={handleClose}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomAutocomplete
                                        options={leadOwner}
                                        fieldLabel="Lead Owner"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={leadOwner.find((option) => option.id === values.leadOwner) || null}
                                        onChange={(e, newValue) => setFieldValue('leadOwner', newValue ? newValue.id : '')}
                                        placeholder="Select Lead Owner"
                                        error={touched.leadOwner && !!errors.leadOwner}
                                        helperText={touched.leadOwner && errors.leadOwner}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                    <CustomAutocomplete
                                        options={clientCompany}
                                        fieldLabel="Select Company"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={clientCompany.find((option) => option.id === values.clientCompanyId) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('clientCompanyId', newValue ? newValue.id : '')
                                        }
                                        placeholder="Select Company"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="First Name"
                                        name="firstName"
                                        required
                                        onChange={handleChange}
                                        value={values.firstName}
                                        error={touched.firstName && !!errors.firstName}
                                        helperText={touched.firstName && errors.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="Last Name"
                                        name="lastName"
                                        required
                                        onChange={handleChange}
                                        value={values.lastName}
                                        error={touched.lastName && !!errors.lastName}
                                        helperText={touched.lastName && errors.lastName}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="Title"
                                        name="title"
                                        required
                                        onChange={handleChange}
                                        value={values.title}
                                        error={touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="Email"
                                        name="email"
                                        type="email"
                                        required
                                        onChange={handleChange}
                                        value={values.email}
                                        error={touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        type='number'
                                        fieldLabel="Phone"
                                        name="phone"
                                        required
                                        onChange={handleChange}
                                        value={values.phone}
                                        error={touched.phone && !!errors.phone}
                                        helperText={touched.phone && errors.phone}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        type='number'
                                        fieldLabel="Mobile"
                                        name="mobile"
                                        required
                                        onChange={handleChange}
                                        value={values.mobile}
                                        error={touched.mobile && !!errors.mobile}
                                        helperText={touched.mobile && errors.mobile}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomAutocomplete
                                        options={leadSource}
                                        fieldLabel="Select Source"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={leadSource.find((option) => option.id === values.leadSourceId) || null}
                                        onChange={(e, newValue) => setFieldValue('leadSourceId', newValue ? newValue.id : '')}
                                        placeholder="Select Source"
                                        error={touched.leadSourceId && !!errors.leadSourceId}
                                        helperText={touched.leadSourceId && errors.leadSourceId}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomAutocomplete
                                        options={leadStatus}
                                        fieldLabel="Select Lead Status"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={leadStatus.find((option) => option.id === values.leadStatusId) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('leadStatusId', newValue ? newValue.id : '')
                                        }
                                        placeholder="Select Lead Status"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomAutocomplete
                                        options={industryType}
                                        fieldLabel="Select Industry"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={industryType.find((option) => option.id === values.industry) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('industry', newValue ? newValue.id : '')
                                        }
                                        placeholder="Select Industry"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="Number of Employees"
                                        name="numberOfEmployees"
                                        type="number"
                                        required
                                        onChange={handleChange}
                                        value={values.numberOfEmployees}
                                        error={touched.numberOfEmployees && !!errors.numberOfEmployees}
                                        helperText={touched.numberOfEmployees && errors.numberOfEmployees}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="Annual Revenue"
                                        name="annualRevenue"
                                        type="number"
                                        required
                                        onChange={handleChange}
                                        value={values.annualRevenue}
                                        error={touched.annualRevenue && !!errors.annualRevenue}
                                        helperText={touched.annualRevenue && errors.annualRevenue}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                    <CustomAutocomplete
                                        options={rating}
                                        fieldLabel="Select Rating"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={rating.find((option) => option.id === values.rating) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('rating', newValue ? newValue.id : '')
                                        }
                                        placeholder="Select Rating"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        name="expectedDate"
                                        fieldLabel="Expected Date"
                                        required={true}
                                        type="date"
                                        value={values.expectedDate}
                                        onChange={handleChange}
                                        placeholder="Enter Expected Date"
                                        error={touched.expectedDate && !!errors.expectedDate}
                                        helperText={touched.expectedDate && errors.expectedDate}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.emailOptOut === 1}
                                                onChange={(e) => setFieldValue('emailOptOut', e.target.checked ? 1 : 0)}
                                            />
                                        }
                                        label="Opt Out of Email"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                    <CustomAutocomplete
                                        options={businessGroup}
                                        fieldLabel="Business Group"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={businessGroup.find((option) => option.id === values.businessGroup) || null}
                                        onChange={(e, newValue) =>
                                            setFieldValue('businessGroup', newValue ? newValue.id : '')
                                        }
                                        placeholder="Enter Business Group"
                                        error={touched.businessGroup && !!errors.businessGroup}
                                        helperText={touched.businessGroup && errors.businessGroup}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="Secondary Email"
                                        name="secondaryEmail"
                                        type="email"
                                        onChange={handleChange}
                                        value={values.secondaryEmail}
                                        error={touched.secondaryEmail && !!errors.secondaryEmail}
                                        helperText={touched.secondaryEmail && errors.secondaryEmail}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        fieldLabel="Street"
                                        name="street"
                                        required
                                        onChange={handleChange}
                                        value={values.street}
                                        error={touched.street && !!errors.street}
                                        helperText={touched.street && errors.street}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomAutocomplete
                                        options={conutries}
                                        fieldLabel="Country"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={conutries.find((option) => option.id === values.countryId) || null}
                                        onChange={(e, newValue) => {
                                            setFieldValue('countryId', newValue ? newValue.id : '');
                                            handleChangeCountry(newValue);
                                        }}
                                        placeholder="Select Country"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                    <CustomAutocomplete
                                        options={states}
                                        fieldLabel="State"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={states.find((option) => option.id === values.stateId) || null}
                                        onChange={(e, newValue) => {
                                            setFieldValue('stateId', newValue ? newValue.id : '');
                                            handleChangeState(newValue);
                                        }}
                                        placeholder="Select State"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomAutocomplete
                                        options={cities}
                                        fieldLabel="City"
                                        required={true}
                                        getOptionLabel={(option) => option.title}
                                        value={cities.find((option) => option.id === values.cityId) || null}
                                        onChange={(e, newValue) => setFieldValue('cityId', newValue ? newValue.id : '')}
                                        placeholder="Select City"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <CustomTextField
                                        type='number'
                                        fieldLabel="Zip Code"
                                        name="zipCode"
                                        required
                                        onChange={handleChange}
                                        value={values.zipCode}
                                        error={touched.zipCode && !!errors.zipCode}
                                        helperText={touched.zipCode && errors.zipCode}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                    <CustomTextarea
                                        name="description"
                                        fieldLabel="Description"
                                        required={true}
                                        multiline
                                        rows={4}
                                        value={values.description}
                                        onChange={handleChange}
                                        placeholder="Enter Description"
                                        error={touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                    />
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default UpdateLeads;

