import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, CardContent, CardMedia, Container, Grid } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Link } from 'react-router-dom';
import CustomButton from "../common/CustomButton";
import HomepageBanners from './HomepageBanners';


const styles = {
    accordionSummary: {
        fontWeight: 'bold',
        '&:hover': {
            color: '#007bff',
        },
    },
    accordionDetails: {
        backgroundColor: '#f5f5f5',
        padding: '16px',
    },
    heading: {
        fontWeight: 'bold',
        fontSize: '18px',
    },
    card: {
        maxWidth: 300,
        borderRadius: 2,
        boxShadow: 3,
        height: 400
    },
    cardMedia: {
        height: 200,
        objectFit: 'cover',
    },
    cardTitle: {
        color: "#007bff",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        mb: 1,
        mt: 2,
    },
    cardSubtitle: {
        fontWeight: 'bold',
        mb: 1,
    },
    cardText: {
        mt: 1,
    },
};

const cardData = [
    {
        title: 'Boost Productivity',
        subtitle: '“We now create our shipping documents 10x faster”',
        name: 'Penny Jones',
        position: 'Office Administrator, Windowrie Wines',
        image: 'https://blog.t.ly/wp-content/uploads/2024/07/Dynamic-Links-Deprecation.webp',
    },
    // Add more card data objects here
    {
        title: 'Innovative Solutions',
        subtitle: '“Our team has seen a 30% increase in efficiency”',
        name: 'John Doe',
        position: 'Project Manager, Tech Innovations',
        image: 'https://blog.t.ly/wp-content/uploads/2024/07/Understanding-HTTP-Status-Codes.jpg',
    },
    {
        title: 'Seamless Integration',
        subtitle: '“Integration was completed without any hiccups”',
        name: 'Jane Smith',
        position: 'IT Specialist, Global Solutions',
        image: 'https://blog.t.ly/wp-content/uploads/2024/05/How-to-Track-QR-Code-Scans.webp',
    },
    {
        title: 'Enhanced Collaboration',
        subtitle: '“Collaboration tools improved team dynamics”',
        name: 'Alice Johnson',
        position: 'Team Lead, Creative Agency',
        image: 'https://blog.t.ly/wp-content/uploads/2024/07/Dynamic-Links-Deprecation.webp',
    },
];

export default function FAQ() {
    return (
        <div>
            <Container >
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: "35px" }}>
                    Helping businesses of all sizes & industries
                    <br />
                    win back time & money on every shipment.

                </Typography>
                <Grid container spacing={2} sx={{ mt: 4 }}>
                    {cardData.map((card, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                            <Card sx={styles.card}>
                                <CardMedia
                                    sx={styles.cardMedia}
                                    image={card.image}
                                    title={card.title}
                                />
                                <CardContent>
                                    <Typography
                                        variant="h6"
                                        component="h5"
                                        sx={styles.cardTitle}
                                    >
                                        {card.title}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        component="h6"
                                        sx={styles.cardSubtitle}
                                    >
                                        {card.subtitle}
                                    </Typography>
                                    <Typography
                                        component="h6"
                                        variant="body2"
                                        sx={styles.cardText}
                                    >
                                        <span style={{ fontWeight: 'bold', fontSize: "14px" }}>
                                            {card.name}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: "12px" }}>

                                            {card.position}
                                        </span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

            <br />

            <Box sx={{ backgroundColor: "rgba(51, 112, 235, .04)", pt: 2, pb: 2 }}>
                <Container>
                    <HomepageBanners />
                </Container>
            </Box>



            <br />
            <Container sx={{ pt: 4, pb: 4 }}>
                <Grid container spacing={1} >
                    <Grid item xs={12} sm={4} md={5}>
                        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1.5, fontSize: "35px" }}>
                            Frequently Asked <br />
                            Questions
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ fontSize: "15px", pt: 1 }}>
                            Got questions? We've already got answers. It's like
                            <br />
                            we can hear you thinking.
                            <br />
                            <Typography sx={{ fontWeight: "bold", pt: 5, color: "#007BFF" }}>
                                Did we miss something?
                            </Typography>
                            <Typography sx={{ pt: 0 }}>
                                Get on a one on one call with our experts.
                            </Typography>
                            <Typography sx={{ pt: 2 }}>
                                <CustomButton
                                    variant="contained"
                                    color="secondary"
                                    title="Request a Call"
                                />
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={7}>
                        {faqs.map((faq, index) => (
                            <Accordion key={index} sx={{
                                boxShadow: 'none',
                                border: 'none',
                            }} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index + 1}-content`}
                                    id={`panel${index + 1}-header`}
                                >
                                    <Typography sx={styles.accordionSummary}>{faq.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={styles.accordionDetails}>
                                    <Typography>
                                        {faq.answer}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}

                        <Link to="/faqpage" style={{ textDecoration: 'none', color: '#007BFF' }}>
                            <CustomButton
                                variant="contained"
                                color="secondary"
                                title="Read More"
                                endIcon={<ArrowForwardOutlinedIcon />}


                            />
                        </Link>

                    </Grid>
                </Grid>





                {/* Add more Accordion components as needed */}



            </Container>
        </div >
    );
}



const faqs = [
    {
        question: 'When can I purchase the All new S1 Pro - 2nd Generation?',
        answer: 'The purchase window of All new S1 Pro - 2nd Generation is open from 15th August, 2023 onwards.',
    },
    {
        question: 'At what price can I buy the All new S1 Pro - 2nd Generation?',
        answer: 'You can purchase the All new S1 Pro - 2nd Generation at a price of ₹147,499 (ex-showroom) from 15th August, 2023 onwards.',
    },
    {
        question: 'When are the test rides starting for S1 Pro - 2nd Generation?',
        answer: 'Test rides will scale up in Experience Centres near you across India from last week of August 2023.',
    },
    {
        question: 'When will the deliveries start?',
        answer: 'The S1 Pro - 2nd Generation deliveries will start from Mid September onwards. You can find your estimated delivery date at the time of purchase on the Ola App.',
    },
    {
        question: 'When will the deliveries start?',
        answer: 'The S1 Pro - 2nd Generation deliveries will start from Mid September onwards. You can find your estimated delivery date at the time of purchase on the Ola App.',
    },
    {
        question: 'When are the test rides starting for S1 Pro - 2nd Generation?',
        answer: 'Test rides will scale up in Experience Centres near you across India from last week of August 2023.',
    },
];