import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Grid, Divider, Typography } from "@mui/material";
import CustomButton from "../common/CustomButton";
import { ClearRounded, SaveRounded, Replay10Rounded } from "@mui/icons-material";
import CustomTextField from "../common/CustomTextField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { genericPost, genericGet } from "../../services/apiServices";
import { CreateMyCompany, GET_COMPANY_id } from "../../services/apiRoutes";
import MessageBox from "../common/CustomMessageBox";

const UpdateCompany = ({ handleClose }) => {
    const { id } = useParams();
    const [message, setMessage] = useState({ open: false, text: "", type: "" });
    const [formData, setFormData] = useState({
        companyName: "",
        mobile: "",
        email: "",
        website: "",
        companyUrl: "",
        defaultCompany: false,
        telephone: "",
        fax: "",
        address: "",
        addressLineOne: "",
        addressLineTwo: "",
        stateId: null,
        cityId: null,
        talukaId: "",
        pincode: "",
        gstin: "",
        fiscalYearBeginMonth: "",
        prefix: "",
        companyLogo: "",
        companyFooter: "",
        companySignStamp: "",
    });

    useEffect(() => {
        if (id) {
            const fetchCompanyDetails = async () => {
                try {
                    const response = await genericGet({ url: GET_COMPANY_id(id) });
                    const companyData = response.data.company;
                    if (companyData) {
                        setFormData({
                            companyName: companyData.companyName,
                            mobile: companyData.mobile,
                            email: companyData.email,
                            website: companyData.website,
                            companyUrl: companyData.companyUrl,
                            defaultCompany: companyData.defaultCompany,
                            telephone: companyData.telephone,
                            fax: companyData.fax,
                            address: companyData.address,
                            addressLineOne: companyData.addressLineOne,
                            addressLineTwo: companyData.addressLineTwo,
                            stateId: companyData.stateId,
                            cityId: companyData.cityId,
                            talukaId: companyData.talukaId,
                            pincode: companyData.pincode,
                            gstin: companyData.gstin,
                            fiscalYearBeginMonth: companyData.fiscalYearBeginMonth,
                            prefix: companyData.prefix,
                            companyLogo: companyData.companyLogo,
                            companyFooter: companyData.companyFooter,
                            companySignStamp: companyData.companySignStamp,
                        });
                    }
                } catch (error) {
                    console.log("Error fetching company details:", error);
                }
            };

            fetchCompanyDetails();
        }
    }, [id]);

    const companyValidationSchema = Yup.object({
        companyName: Yup.string().required("Company Name is required"),
        mobile: Yup.string().required("Mobile is required"),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        website: Yup.string().url("Invalid URL format").required("Website is required"),
        companyUrl: Yup.string().url("Invalid URL format"),
        defaultCompany: Yup.boolean(),
        telephone: Yup.string(),
        fax: Yup.string(),
        address: Yup.string().required("Address is required"),
        addressLineOne: Yup.string().required("Address Line One is required"),
        addressLineTwo: Yup.string(),
        stateId: Yup.number().required("State is required"),
        cityId: Yup.number().required("City is required"),
        talukaId: Yup.string(),
        pincode: Yup.string().required("Pincode is required"),
        gstin: Yup.string(),
        fiscalYearBeginMonth: Yup.string(),
        prefix: Yup.string(),
        companyLogo: Yup.string(),
        companyFooter: Yup.string(),
        companySignStamp: Yup.string(),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const response = await genericPost(CreateMyCompany, values);
            setMessage({
                open: true,
                text: response.message || "Company created successfully",
                type: "success",
            });
        } catch (error) {
            setMessage({
                open: true,
                text: "Something went wrong",
                type: "error",
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <Formik
                initialValues={formData}
                enableReinitialize
                validationSchema={companyValidationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    isSubmitting,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Grid container spacing={1} style={{ position: "sticky" }}>
                            <Grid item xs={4} md={2}>
                                <Typography sx={{ fontWeight: 600 }} style={{ color: "#212529", fontSize: "18px" }}>
                                    {id ? "Update Company" : "Create Company"}
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Box style={{ display: "flex" }}>
                                    <CustomButton
                                        startIcon={<SaveRounded />}
                                        title={isSubmitting ? <CircularProgress size={20} color="inherit" /> : "Save"}
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    />
                                    <CustomButton startIcon={<SaveRounded />} title="Save and New" size="small" variant="contained" />
                                    <CustomButton startIcon={<Replay10Rounded />} title="Convert To Quotation" size="small" variant="contained" />
                                    <CustomButton startIcon={<ClearRounded />} title="Cancel" size="small" variant="contained" onClick={handleClose} />
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={1} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="companyName"
                                    fieldLabel="Company Name"
                                    value={values.companyName}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Company Name"
                                />
                                {touched.companyName && errors.companyName && (
                                    <p style={{ color: "red", fontSize: "12px" }}>{errors.companyName}</p>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="mobile"
                                    fieldLabel="Mobile"
                                    value={values.mobile}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Mobile"
                                />
                                {touched.mobile && errors.mobile && (
                                    <p style={{ color: "red", fontSize: "12px" }}>{errors.mobile}</p>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="email"
                                    type="email"
                                    fieldLabel="Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Email"
                                />
                                {touched.email && errors.email && (
                                    <p style={{ color: "red", fontSize: "12px" }}>{errors.email}</p>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="website"
                                    type="url"
                                    fieldLabel="Website"
                                    value={values.website}
                                    onChange={handleChange}
                                    required={true}
                                    placeholder="Enter Website"
                                />
                                {touched.website && errors.website && (
                                    <p style={{ color: "red", fontSize: "12px" }}>{errors.website}</p>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="companyUrl"
                                    type="url"
                                    fieldLabel="Company URL"
                                    value={values.companyUrl}
                                    onChange={handleChange}
                                    placeholder="Enter Company URL"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <CustomTextField
                                    name="telephone"
                                    fieldLabel="Telephone"
                                    value={values.telephone}
                                    onChange={handleChange}
                                    placeholder="Enter Telephone"
                                />
                            </Grid>
                            {/* Add other fields similarly */}
                        </Grid>
                    </Form>
                )}
            </Formik>
            <MessageBox message={message} setMessage={setMessage} />
        </>
    );
};

export default UpdateCompany;
